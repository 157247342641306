import treatmentsApi from './api/treatments'
import {handleError} from './api/errorHandler'

const GET_MASTER_TREATMENTS = "GET_MASTER_TREATMENTS"
const GET_MASTER_TREATMENT = "GET_MASTER_TREATMENT"

const initialState = {
    data: {},
    master: {},
    treatment: {},
    mastertreatment: {},
};
export const actionCreators = {
    getMasterTreatments: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await treatmentsApi.adminMasterTreatments(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_TREATMENTS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterTreatment: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await treatmentsApi.adminMasterTreatment(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_TREATMENT,
                payload: data.treatment
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterTreatment: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await treatmentsApi.adminCreateMasterTreatment(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterTreatment: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await treatmentsApi.adminUpdateMasterTreatment(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_MASTER_TREATMENTS) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_MASTER_TREATMENT) {
        return {...state, mastertreatment: action.payload};
    }

    return state;
};
