const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>

        <p>
            <strong>
                <mark>Note to clinician: This first section should take 3 minutes of conversation</mark><mark style="color:#db2828">Pick some of the following to discuss.</mark>
            </strong>
        </p>

<p>
  <strong><u>2. Counsel patient: </u></strong>
  <strong>
    Describe benefits of diet, exercise, weight loss/gain and healthy
    eating.
  </strong>
</p>

<p><mark style="background-color: lightblue">2C. Gain weight</mark></p>

<p>
  <strong>To gain weight its important to think about how much your eating, the
  quality of the food you are eating, how often and at what times you are
  eating. Adding one to three pounds a week of weight is a realistic goal.</strong>
  Some strategies to gain weight include consuming energy- and nutrient dense
  food. <strong>Foods, like healthy fats, are high in nutrients and calories.</strong>
  Examples of healthy fats are avocado, nuts and nut butters, seeds and seed
  butters, olive oil, avocado oil, flaxseed oil and fatty fish (like salmon,
  sardines, and tuna). You can add flaxseeds or chia seeds into smoothies,
  salads or yogurt for extra calories.
</p>
<p>
  <strong>Don’t skip meals,</strong> skipping breakfast for example, extends the fasting
  period in our bodies and causes our body to rely more heavily on energy
  from stored fat and muscle tissue. If you don’t like a big breakfast, opt
  for a smoothie or fruit. <strong>Eat more frequently. Try to eat every two to three
  hours, this will help you reach your overall daily caloric intake,</strong> it may
  be easier to eat 5-6 smaller meals during the day than 2-3 large meals. Be
  sure to include whole grains, lean protein, fruits, vegetables, and healthy
  fats in each meal.
</p>
<p>
  Another easy way to consume more calories and gain weight is to have drinks
  that contain calories like fruit juice, milk, protein shakes, smoothies, or
  yogurt drinks. Add toppings to your food like cheese to a sandwich, a
  salad, or vegetables.
</p>
<p>
  <strong>Exercising can also help you reach your goals. Doing weight or resistance
  training can help you gain muscle, keeping your body strong. Exercise may
  also stimulate your appetite.</strong> You want to have a fully balanced meal three
  to four hours before a workout. An example of a meal you make is a turkey
  sandwich with vegetables and a side of yogurt and fruit. Then, about two
  hours before you work out, try to have a mini-meal, like a smoothie or
  oatmeal. During your workout, sip on a sports drink to replenish your
  energy. The one to two hours after a workout is an important time to fuel
  properly. Try to have protein and carbohydrates after a workout. Some post
  workout snack ideas include whole grain toast with peanut butter, Greek
  yogurt with fruit and granola, or pita and hummus.
</p>
<p>
  <strong>Finally have a nighttime snack after dinner and before bed. Low fat or
  nonfat dairy foods are good options for night time snacks.</strong> Banana with
  almond butter, a high-protein smoothie, or an occasional treat, but always
  be mindful of excessive sugar and fat.
</p>




</body>
</html>`

export default text
