import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as userActionCreators} from '../../store/Users'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Button,
    Table,
    Pagination,
    TransitionablePortal,
    Label,
} from 'semantic-ui-react'
import FilterUser from '../common/ui/FilterUser'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import {actionCreators as sitesActionCreators} from "../../store/Sites";
import {actionCreators as treatmentsActionCreators} from "../../store/Treatments";

class MasterUsers extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newUser: {},
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterUsers()
        this.props.getMasterSites()
        this.props.getMasterTreatments()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let isAdmin = nextProps.user.roles ? nextProps.user.roles.find(role => role.name === 'admin') : true
        if(!isAdmin){
            this.props.history.push("")
        }
    }

    getTableData = props => {
        return props.users.master.users.filter(item => {
            return item.firstName.toLowerCase().includes(props.searchStore.text)
        }).sort((a, b) => a.order - b.order).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/user/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{item.firstName}</Table.Cell>
                <Table.Cell>{item.lastName}</Table.Cell>
                <Table.Cell>{item.status === 'Active' ?
                    <Label color='teal' horizontal>ACTIVE</Label> :
                    item.status === 'Locked'?
                    <Label horizontal>{item.status.toUpperCase()}</Label>:
                    <Label color='red' horizontal>{item.status.toUpperCase()}</Label>}
                </Table.Cell>
                <Table.Cell>{item.roles[0].name}</Table.Cell>

            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getMasterUsers(this.state.filter, data.activePage)
    }

    getFilterUser(filter) {
        this.setState({filter})
        this.props.getMasterUsers(filter, this.state.page)
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.createMasterUser(data)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterUsers(this.state.filter, this.state.page)
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Admins",
            }]}/>,
            <AccordionSegment key={1} header={'Admins'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment style={{
                            left: '38%',
                            width: '35%',
                            position: 'fixed',
                            top: '30px',
                            zIndex: 1000,
                            padding: 0
                        }}>
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    <FilterUser filter={this.getFilterUser.bind(this)}/>
                    {this.props.users.master.users ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>First Name</Table.HeaderCell>
                                    <Table.HeaderCell>Last Name</Table.HeaderCell>
                                    <Table.HeaderCell>Active</Table.HeaderCell>
                                    <Table.HeaderCell>Role</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={6}>
                                        <Pagination defaultActivePage={this.props.users.master.paging.page}
                                                    totalPages={this.props.users.master.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                        <Loading/>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, users: state.users, user: state.login.user}),
    dispatch => bindActionCreators({...userActionCreators, ...sitesActionCreators, ...treatmentsActionCreators}, dispatch)
)(MasterUsers)
