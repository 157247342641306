import icsmedicationsApi from './api/icsmedications'
import {handleError} from './api/errorHandler'

const GET_MASTER_ICSMEDICATIONS = "GET_MASTER_ICSMEDICATIONS"
const GET_MASTER_ICSMEDICATION = "GET_MASTER_ICSMEDICATION"

const initialState = {
    data: {},
    master: {},
    icsmedication: {},
    mastericsmedication: {},
};
export const actionCreators = {
    getMasterICSMedications: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await icsmedicationsApi.adminMasterICSMedications(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_ICSMEDICATIONS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterICSMedication: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await icsmedicationsApi.adminMasterICSMedication(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_ICSMEDICATION,
                payload: data.ics_medication
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterICSMedication: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await icsmedicationsApi.adminCreateMasterICSMedication(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterICSMedication: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await icsmedicationsApi.adminUpdateMasterICSMedication(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_MASTER_ICSMEDICATIONS) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_MASTER_ICSMEDICATION) {
        return {...state, mastericsmedication: action.payload};
    }

    return state;
};
