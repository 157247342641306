import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as siteActionCreators} from '../../store/Sites'
import {actionCreators as languageActionCreators} from '../../store/Languages'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    TransitionablePortal,
} from 'semantic-ui-react'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'
import 'jsoneditor-react/es/editor.min.css';
import 'brace/mode/json';
import 'brace/theme/github';
import AppBreadCrumb from "../common/ui/AppBreadCrumb";
import AppMessage from "../common/ui/AppMessage";

class MasterSite extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTopic: {},
        site: {},
        edit: false,
        loading: true,
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterSite(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({site: nextProps.sites.mastersite, loading: !this.state.site})
    }

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})

    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSave() {
        this.setState({saving: 1})
        let site = {
            name: this.state.site.name,
            data: this.state.site.data
        }
        let errors = await this.props.updateMasterSite(this.props.match.params.id, site)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterSite(this.props.match.params.id)
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    handleCancel = () => {
        this.props.getMasterSite(this.props.match.params.id)
        this.setState({edit: false})
    }

    handleChange = (e, target) => {
        if (this.state.edit) {
            let site = this.state.site
            site[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked ? 1 : 0 : e.target.value
            this.setState({site})
        }
    }

    handleJSONChange = (e, target) => {
        if (this.state.edit) {
            let site = this.state.site
            site['data'] = e
            this.setState({site})
        }
    }


    render() {
        let site = this.state.site
        let readOnly = !this.state.edit
        let createdAt = site.created_at ? site.created_at.substr(0, 16) : ""
        let updatedAt = site.updated_at ? site.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Sites",
                link: "/app/sites"
            }, {
                text: "Site",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Site'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}>
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    {this.state.loading ? <Loading/> :
                        <Form className={readOnly ? "readOnly" : "editing"}>
                            <GridFormInput
                                label="Type"
                                component={readOnly ? site.name : <Form.Input
                                    fluid
                                    placeholder='Type'
                                    readOnly={readOnly}
                                    value={site.name}
                                    name="name"
                                    onChange={this.handleChange.bind(this)}/>}
                            />
                            <GridFormInput
                                label="ID"
                                component={site.id}
                            />

                            <GridFormInput
                                label="Created"
                                component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Created By"
                                component={site.created_by
                                }
                            />
                            <GridFormInput
                                label="Updated By"
                                component={site.updated_by}
                            />
                            <br/>
                            {readOnly ? <Button color='teal' style={{float: "right"}}
                                                onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                [<Button key={0} color='teal' style={{float: "right"}}
                                         onClick={this.handleSave.bind(this)}>Save</Button>,
                                    <Button key={1} style={{float: "right"}}
                                            onClick={this.handleCancel.bind(this)}>Cancel</Button>]
                            }
                            <br/>
                            <br/>
                        </Form>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, sites: state.sites, languages: state.languages}),
    dispatch => bindActionCreators({...siteActionCreators, ...languageActionCreators}, dispatch)
)(MasterSite)
