import React, {Component} from "react";
import {
    Accordion,
    Form,
    Select,
    Grid,
    Input,
    Button
} from "semantic-ui-react";
import GridFormInput from './TableGridFormInput'

class FilterForm extends Component {
    state = {filter: {}}

    handleChange(e, value) {
        let filter = this.state.filter
        filter[value.name] = value.value
        this.setState({filter})
    }

    handleClick() {
        this.props.filter(this.state.filter)
    }

    handleClear() {
        this.setState({filter: {}})
        this.props.filter({})
    }

    render() {
        return (<Form>
            <Grid columns='equal'>
                <Grid.Row >
                    <Grid.Column >
                        <GridFormInput
                            label="Nickname"
                            component={<Input style={{width: 200}} name='nickname' placeholder='Nickname'
                                              onChange={this.handleChange.bind(this)}/>}/>
                        <GridFormInput
                            label="Patient Number"
                            component={<Input style={{width: 200}} name='patientNumber' placeholder='Patient Number'
                                              onChange={this.handleChange.bind(this)}/>}/>
                        <GridFormInput
                            label="Created At"
                            component={<Input style={{width: 200}} name='created_at' type='date'
                                              placeholder='Created At'
                                              onChange={this.handleChange.bind(this)}/>}/>
                    </Grid.Column>
                    <Grid.Column>
                        <GridFormInput
                            label="Provider"
                            component={<Select style={{width: 200}} name='providerId' placeholder='Provider'
                                               options={this.props.providers ? [{key:0}, ...this.props.providers.map(item => {
                                                   return {
                                                   key: item.id, value: item.id, text: item.firstName + " " + item.lastName
                                               }
                                               })] : []}
                                               onChange={this.handleChange.bind(this)}/>}/>
                        <GridFormInput
                            label="Site"
                            component={<Select style={{width: 200}} name='siteId' placeholder='Site'
                                               options={this.props.sites ? [{key:0}, ...this.props.sites.map(item => {
                                                   return {
                                                   key: item.id, value: item.id, text: item.name
                                               }
                                               }) ]: []}
                                               onChange={this.handleChange.bind(this)}/>}/>
                        <GridFormInput
                            label="ICS Medication"
                            component={<Select style={{width: 200}} name='icsMedicationId' placeholder='ICS Medication'
                                               options={this.props.icsMedications ? [{key:0}, ...this.props.icsMedications.map(item => {
                                                   return {
                                                       key: item.id, value: item.id, text: item.name
                                                   }
                                               })] : []}
                                               onChange={this.handleChange.bind(this)}/>}/>
                    </Grid.Column>
                    <Grid.Column>
                        <GridFormInput
                            label="Status"
                            component={<Select
                                fluid
                                label='Status' placeholder='Status'
                                options={[{key: 0, text: "Active", value: "Active"},
                                    {key: 1, text: "Inactive", value: "Inactive"}]}
                                name="status"
                                onChange={this.handleChange.bind(this)}
                            />
                            }/>
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div style={{
                            position: "absolute",
                            right: 10,
                            bottom: 0,
                        }}>
                            <Button onClick={this.handleClear.bind(this)}>Clear</Button>
                            &nbsp;
                            <Button color='teal'
                                    onClick={this.handleClick.bind(this)}>Filter</Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>)
    }
}

export class Filter extends Component {
    state = {activeIndex: -1}

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state

        return (
            <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    content='Filter'
                    index={0}
                    onClick={this.handleClick}
                />
                <Accordion.Content active={activeIndex === 0}>
                    <FilterForm filter={this.props.filter} sites={this.props.sites} providers={this.props.providers}
                                icsMedications={this.props.icsMedications}/>
                </Accordion.Content>
            </Accordion>
        )
    }
}

export default Filter
