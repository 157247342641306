import React, {Component} from 'react'
import {Button, Form, Grid, Header, Segment, Message} from 'semantic-ui-react'
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Login";
import {connect} from "react-redux";

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            errorMessage: null,
            successMessage: null
        }
    }

    async handleSubmit() {
        let response = await this.props.sendPasswordReset(this.state)
        this.handleResponse(response);
    }

    handleResponse(response) {
        let errors = response.data.errors
        if (!errors) {
            let successMessage = <p>{response.data.message}</p>
            this.setState({successMessage, errorMessage: null, loading: false})
        } else {
            let errorMessage = Array.isArray(errors) ? errors.map((error, index) => {
                return <p key={index}>{error.message}</p>
            }) : <p>{response.data.message}</p>
            this.setState({successMessage: null, errorMessage, loading: false})

        }
    }

    handleChange(field, e) {
        let state = this.state
        state[field] = e.target.value
        state.errorMessage = null
        this.setState(state)
    }

    render() {
        return (
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Form size='large'>
                        <Segment padded='very' stacked>
                            {this.state.errorMessage ? <Message negative>
                                <Message.Header>There was some errors with your submission</Message.Header>
                                {this.state.errorMessage}
                            </Message> : this.state.successMessage ? <Message positive key={1}>
                                <Message.Header>Success!</Message.Header>
                                {this.state.successMessage}
                                <a href='/'>Click here </a> to login
                            </Message> : null}
                            <Header as='h2' color='teal' textAlign='center'> Forgot Password?
                            </Header>
                            {this.state.successMessage ? null : [
                                <Form.Input key={1} fluid icon='user' iconPosition='left'
                                            placeholder='E-mail address'
                                            onChange={this.handleChange.bind(this, 'email')}/>,
                                <Button key={2} color='teal' fluid size='large' onClick={this.handleSubmit.bind(this)}>
                                    Send
                                </Button>]}
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

export default connect(
    state => {
        const {login} = state;
        return {
            state: {login}
        };
    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);
