import {client} from './apiClient'
import axios from 'axios'

const adminReports = async (filter) => {
    return await client.request({
        url: '/admin/reports',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminReport = async (id) => {
    return await client.request({
        url: '/admin/report/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const reportData = async (url) => {
    return await axios.get(url);
}
export default {
    adminReports,
    adminReport,
    reportData
}