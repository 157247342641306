import React, {Component} from 'react'
import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Header,
    Button,
    Icon,
    Modal,
} from 'semantic-ui-react'
import ReactCrop from 'react-image-crop';

let timeout = {}
export default class AddCurriculumModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
            newItem: {
                active: false,
                order: props.order
            },
            file: null,
            rawFile: null,
            imageUrl: null,
            image: null,
            crop: {
                width: 50,
            }
        }
    }

    fileInputRef = React.createRef();

    componentWillReceiveProps(nextProps) {
        let newItem = this.state.newItem
        newItem.order = nextProps.order
        this.setState({newItem})
    }

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})

    handleChange = (e) => {
        let newItem = this.state.newItem
        newItem[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked : e.target.value
        this.setState({newItem})
    }

    handleSubmit = () => {
        this.props.submit(this.state, () => this.setState({modalOpen: false}))
    }


    async onChangeFile(e) {
        let rawFile = e.target.files[0]
        var image = new Image();
        const canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        image.onload = async () => {

            let imageWidth = image.width
            let imageHeight = image.height
            let ratio = 1
            if (imageWidth > 1100) {
                ratio = 1100 / imageWidth
            }
            canvas.width = imageWidth * ratio
            canvas.height = imageHeight * ratio

            var oc = document.createElement('canvas'),
                octx = oc.getContext('2d');

            oc.width = image.width * ratio;
            oc.height = image.height * ratio;
            octx.drawImage(image, 0, 0, oc.width, oc.height);

            octx.drawImage(oc, 0, 0, oc.width, oc.height);

            ctx.drawImage(oc, 0, 0, oc.width, oc.height,
                0, 0, canvas.width, canvas.height);

            oc.toBlob(file => {
                if (file) {
                    file.lastModifiedDate = new Date();
                    file.name = encodeURI(rawFile.name);
                    this.setState({file})
                }
            }, 'image/png');
        }
        let imageUrl = URL.createObjectURL(rawFile);
        image.src = imageUrl

        this.setState({rawFile, imageUrl, image})
    }

    onChangeCrop = (crop, pixelCrop) => {
        let rawFile = this.state.rawFile
        let image = this.state.image
        this.setState({crop, pixelCrop});
        clearTimeout(timeout)
        timeout = setTimeout(async () => {
            let file = await this.getCroppedImg(image, pixelCrop, rawFile.name)
            this.setState({file});
        }, 1000)
    }

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                if (file) {
                    file.lastModifiedDate = new Date();
                    file.name = fileName;
                    resolve(file);
                } else {
                    reject("not found")
                }
            }, 'image/png');
        });
    }

    render() {
        let fileName = this.state.rawFile ? this.state.rawFile.name : ""

        return (
            <Modal
                trigger={<Button
                    icon
                    labelPosition='left'
                    color='teal'
                    size='small'
                    onClick={this.handleOpen.bind(this)}
                >
                    <Icon name='plus'/> Add Image
                </Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose.bind(this)}
                style={{height: "fit-content", margin: "auto"}}
            >
                <Header content={"Add Image"}/>
                <Modal.Content>
                    <Form className="editing">
                        <Form.Group>
                            <Button
                                style={{width: 200}}
                                content="Choose File"
                                labelPosition="left"
                                icon="file"
                                onClick={() => this.fileInputRef.current.click()}
                            /> <input type="text" placeholder="Browse Files" value={fileName} readOnly onClick={() => this.fileInputRef.current.click()}/>
                            <input
                                style={{display: 'none'}}
                                ref={this.fileInputRef}
                                type="file"
                                hidden
                                onChange={this.onChangeFile.bind(this)}
                            />
                        </Form.Group>
                    </Form>
                    <div style={{
                        border: "1px solid lightgrey",
                        textAlign: "center",
                        maxHeight: "70vh"

                    }}>
                        <div style={{
                            display: "inline-block",
                            border: "1px solid lightgrey",
                            margin: "5px"
                        }}>
                            {this.state.imageUrl ?
                                <ReactCrop disabled={true} id="image" src={this.state.imageUrl}
                                           onChange={this.onChangeCrop.bind(this)}
                                           crop={this.state.crop}/> : null}
                        </div>

                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>
                        Cancel
                    </Button>
                    <Button color='teal' onClick={this.handleSubmit.bind(this)}>
                        <Icon name='plus'/> Save
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}