import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as curriculumActionCreators} from '../../store/Curriculums'
import 'semantic-ui-css/semantic.min.css'
import {
    Button,
    Table,
    Pagination,
    Label,
} from 'semantic-ui-react'
import FilterCurriculum from '../common/ui/FilterCurriculum'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import AddCurriculumModal from '../common/modal/AddCurriculumModal'

class Device extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newCurriculum: {},
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterCurriculums()
    }

    getTableData = props => {
        return props.curriculums.master.curriculums.filter(item => {
            return item.name.toLowerCase().includes(props.searchStore.text)
        }).sort((a, b) => a.order - b.order).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/curriculum/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.active ?
                    <Label color='teal' horizontal>TRUE</Label> :
                    <Label color='red' horizontal>FALSE</Label>}
                </Table.Cell>
                <Table.Cell>{item.order}</Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getMasterCurriculums(this.state.filter, data.activePage)
    }

    getFilterCurriculum(filter) {
        this.setState({filter})
        this.props.getMasterCurriculums(filter, this.state.page)
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.createMasterCurriculum(data)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterCurriculums(this.state.filter, this.state.page)
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Curriculums",
            }]}/>,
            <AccordionSegment key={1} header={'Curriculums'} component={
                <div>
                    <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                    <FilterCurriculum filter={this.getFilterCurriculum.bind(this)}/>
                    {this.props.curriculums.master.curriculums ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Active</Table.HeaderCell>
                                    <Table.HeaderCell>Order</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={6}>
                                        <Pagination defaultActivePage={this.props.curriculums.master.paging.page}
                                                    totalPages={this.props.curriculums.master.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                        <AddCurriculumModal
                                            order={Math.max.apply(Math,
                                                this.props.curriculums.master.curriculums.map(function (o) {
                                                    return o.order;
                                                })) + 1}
                                            submit={this.handleSubmit.bind(this)}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                        <Loading/>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, curriculums: state.curriculums}),
    dispatch => bindActionCreators({...curriculumActionCreators}, dispatch)
)(Device)
