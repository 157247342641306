import {client} from './apiClient'

const adminDeviceUsers = async (filter, deviceId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/users",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminDeviceUser = async (deviceId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/user/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceUserActivities = async (deviceId, id, page, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/user/" + id + "/activities",
        method: "GET",
        params: {sort_field: 'created_at', sort_order: 'asc', page, ...filter},
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceFilterUserActivities = async (deviceId, id, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/user/" + id + "/activities",
        method: "GET",
        params: filter,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminMasterUsers = async (filter) => {
    return await client.request({
        url: '/admin/users',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterUser = async (id) => {
    return await client.request({
        url: '/admin/user/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterUser = async (data) => {
    return await client.request({
        url: '/admin/user',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterUser = async (id, data) => {
    return await client.request({
        url: '/admin/user/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const sendPasswordReset = async (id) => {
    return await client.request({
        url: '/user/' + id + '/sendpasswordreset',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminDeviceUsers,
    adminDeviceUser,
    adminDeviceUserActivities,
    adminDeviceFilterUserActivities,
    adminMasterUsers,
    adminMasterUser,
    adminCreateMasterUser,
    adminUpdateMasterUser,
    sendPasswordReset
}
