import React from 'react';
import SideMenu from "../layout/SideMenu";
import TopMenu from "../layout/TopMenu";
import {connect} from 'react-redux';
import {
    Icon,
    Message,
    Segment,
    TransitionablePortal
} from "semantic-ui-react";

class Layout extends React.Component {
    state = {
        open: false,
        count: 10
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.serviceWorker.serviceWorkerUpdated) {
            this.setState({open: nextProps.serviceWorker.serviceWorkerUpdated})
            this.refresh()
        }
    }

    toggleMessage() {
        setTimeout(() => {
            this.setState({open: false})
            this.refresh()
        }, this.state.count * 1000)

        setInterval(() => {
            let count = this.state.count
            count--;
            this.setState({count})
        }, 1000)
    }

    refresh() {
        if('caches' in window){
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
    }

    render() {
        return (
            <div className="layout-grid">
                <TransitionablePortal open={this.state.open}>
                    <Segment
                        style={{
                            left: '38%',
                            width: '35%',
                            position: 'fixed',
                            top: '30px',
                            zIndex: 1000,
                            padding: 0
                        }}><Message icon positive onClick={this.refresh}> <Icon name='info circle'/>
                        <Message.Content>
                            <Message.Header>New Updates Available</Message.Header>
                            Hooray! Refreshing your browser to enjoy the latest and greatest in {this.state.count}
                        </Message.Content>
                    </Message>
                    </Segment>
                </TransitionablePortal>
                <div className="menu">
                    <TopMenu/>
                </div>
                <div className="main-content">
                    <SideMenu history={this.props.history}>
                        {this.props.children}
                    </SideMenu>
                </div>
            </div>
        );
    }

}

export default connect(state => {
    return {
        serviceWorker: state.serviceWorker
    };
})(Layout);
