import sitesApi from './api/sites'
import {handleError} from './api/errorHandler'

const GET_MASTER_SITES = "GET_MASTER_SITES"
const GET_MASTER_SITE = "GET_MASTER_SITE"

const initialState = {
    data: {},
    master: {},
    site: {},
    mastersite: {},
};
export const actionCreators = {
    getMasterSites: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await sitesApi.adminMasterSites(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_SITES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterSite: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await sitesApi.adminMasterSite(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_SITE,
                payload: data.site
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterSite: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await sitesApi.adminCreateMasterSite(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterSite: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await sitesApi.adminUpdateMasterSite(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_MASTER_SITES) {
        return {...state, master: action.payload};
    }
    if (action.type === GET_MASTER_SITE) {
        return {...state, mastersite: action.payload};
    }

    return state;
};
