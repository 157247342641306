import {client} from './apiClient'

const adminImages = async (filter) => {
    return await client.request({
        url: '/admin/images',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminImage = async (id) => {
    return await client.request({
        url: '/admin/image/'+id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const uploadImage = async (data) => {
    return await client.request({
        url: '/admin/image',
        method: "POST",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token'),
            'content-type': 'multipart/form-data',
        },
    })
}

export default {
    adminImages,
    adminImage,
    uploadImage
}