import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as appActionCreators} from '../../store/Apps'
import {actionCreators as languageActionCreators} from '../../store/Languages'
import {Link} from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    Header,
    Divider,
    TransitionablePortal,
    Table
} from 'semantic-ui-react'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'
import AddAppLanguageModal from '../common/modal/AddAppLanguageModal'
import {JsonEditor as Editor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

class MasterApp extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTopic: {},
        app: {},
        edit: false,
        loading: true,
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterApp(this.props.match.params.id)
        this.props.getMasterLanguages({object_id: this.props.match.params.id, language: 'es'})
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({app: nextProps.apps.masterapp, loading: !this.state.app})
    }

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getMasterTopics(this.props.match.params.id, this.state.filter, data.activePage)

    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSave() {
        this.setState({saving: 1})
        let app = {
            type: this.state.app.type,
            data: this.state.app.data
        }
        let errors = await this.props.updateMasterApp(this.props.match.params.id, app)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterApp(this.props.match.params.id)
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    handleCancel = () => {
        this.props.getMasterApp(this.props.match.params.id)
        this.setState({edit: false})
    }

    handleChange = (e, target) => {
        if (this.state.edit) {
            let app = this.state.app
            app[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked ? 1 : 0 : e.target.value
            this.setState({app})
        }
    }

    handleJSONChange = (e, target) => {
        if (this.state.edit) {
            let app = this.state.app
            app['data'] = e
            this.setState({app})
        }
    }

    handleEditLanguage(id) {
        return async (data, callback) => {
            console.log(data, id)
            this.setState({saving: 1})
            let errors = await
                this.props.updateMasterLanguage(id, data)
            if (errors !== undefined) {
                this.setState({saving: 2, errors})
            } else {
                this.props.getMasterLanguages({object_id: this.props.match.params.id, language: 'es'})
                this.setState({saving: 0})
                this.toggleMessage()
                callback()
            }
        }
    }

    async handleCreateLanguage(data, callback) {
        console.log(data)
        this.setState({saving: 1})
        let errors = await this.props.createMasterLanguage(data)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            this.props.getMasterLanguages({object_id: this.props.match.params.id, language: 'es'})
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    render() {
        let app = this.state.app
        let readOnly = !this.state.edit
        let languageTexts = this.props.languages.master.language_texts
        let createdAt = app.created_at ? app.created_at.substr(0, 16) : ""
        let updatedAt = app.updated_at ? app.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Apps",
                link: "/app/apps"
            }, {
                text: "App",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'App'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}>
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    {this.state.loading ? <Loading/> :
                        <Form className={readOnly ? "readOnly" : "editing"}>
                            <Table compact className='legend' style={{
                                position: 'absolute',
                                right: 0,
                                top: readOnly? 0: 50,
                                width: 300,
                                border: ' 1px solid rgba(34,36,38,1)'
                            }}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            style={{background: '#80e4d5cc', width: '30%'}}>Languages</Table.HeaderCell>
                                        <Table.HeaderCell style={{background: '#80e4d5cc'}}/>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {languageTexts && languageTexts.length > 0 ?
                                        languageTexts.map((languageTexts, index) => {
                                                return <Table.Row key={index} style={{background: '#80e4d577'}}>
                                                    <Table.Cell>
                                                        <Link to={"/app/app/" + app.id + "/language/" + languageTexts.id}>
                                                            <Button color='teal' size="small" icon> Edit
                                                            </Button>
                                                        </Link>
                                                    </Table.Cell>
                                                    <Table.Cell>{languageTexts.language}</Table.Cell>
                                                </Table.Row>
                                            }
                                        )
                                        : <Table.Row style={{background: '#80e4d577'}}>
                                            <Table.Cell>
                                                <AddAppLanguageModal
                                                    app={app}
                                                    submit={this.handleCreateLanguage.bind(this)}/>
                                            </Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>}
                                </Table.Body>
                            </Table>
                            <GridFormInput
                                label="Type"
                                component={readOnly ? app.type : <Form.Input
                                    fluid
                                    placeholder='Type'
                                    readOnly={readOnly}
                                    value={app.type}
                                    name="type"
                                    onChange={this.handleChange.bind(this)}/>}
                            />
                            <GridFormInput
                                label="ID"
                                component={app.id}
                            />

                            <GridFormInput
                                label="Created"
                                component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Created By"
                                component={app.created_by
                                }
                            />
                            <GridFormInput
                                label="Updated By"
                                component={app.updated_by}
                            />
                            <Divider horizontal style={{marginBottom: 5}}>
                                <Header as='h4'>
                                    Data
                                </Header>
                            </Divider>

                            {readOnly ? <Editor
                                style={{height: 500}}
                                value={app.data}
                                search={false}
                                mode={'view'}
                                enableSort={false}
                                enableTransform={false}
                                history={false}
                                ace={ace}
                                onChange={this.handleJSONChange}
                            /> : null}
                            {readOnly ?
                                null
                                :
                                <Editor
                                    style={{height: 500}}
                                    value={app.data}
                                    search={false}
                                    mode={'code'}
                                    enableSort={false}
                                    enableTransform={false}
                                    history={false}
                                    ace={ace}
                                    onChange={this.handleJSONChange}
                                />
                            }
                            <br/>
                            {readOnly ? <Button color='teal' style={{float: "right"}}
                                                onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                [<Button key={0} color='teal' style={{float: "right"}}
                                         onClick={this.handleSave.bind(this)}>Save</Button>,
                                    <Button key={1} style={{float: "right"}}
                                            onClick={this.handleCancel.bind(this)}>Cancel</Button>]
                            }
                            <br/>
                            <br/>
                        </Form>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, apps: state.apps, languages: state.languages}),
    dispatch => bindActionCreators({...appActionCreators, ...languageActionCreators}, dispatch)
)(MasterApp)
