import React, {Component} from 'react'
import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Header,
    Button,
    Icon,
    Modal,
} from 'semantic-ui-react'
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

export default class AddTreatmentLanguageModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
            newItem: {
                name: "",
                data: {}
            }
        }

    }

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})

    handleChange = (e, target) => {
        let newItem = this.state.newItem
        newItem[target.name] = target.name === "active" || target.name === "required" ? target.checked : target.value
        this.setState({newItem})
    }

    handleJSONChange = (e, target) => {
        let newItem = this.state.newItem
        newItem['data'] = e
        this.setState({newItem})
    }
    handleSubmit = () => {
        this.props.submit(this.state.newItem, () => this.setState({modalOpen: false}))
    }

    render() {
        return (
            <Modal
                trigger={<Button
                    floated='right'
                    icon
                    labelPosition='left'
                    color='teal'
                    size='small'
                    onClick={this.handleOpen.bind(this)}
                >
                    <Icon name='plus'/> Add Treatment
                </Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose.bind(this)}
                size='small'
                style={{height: "fit-content", margin: "auto"}}
            >
                <Header content={[<p key={1}>Add Treatment</p>, (
                    this.props.name ?
                        <p key={2} style={{fontSize: 15, marginLeft: 20}}><Icon name={"level up alternate"}
                                                                                rotated="clockwise"/>{this.props.name}
                        </p> : null)]}/>
                <Modal.Content>
                    <Form className="editing">
                        <Form.Input fluid label='Name' placeholder='Name'
                                    name="name"
                                    value={this.state.newItem.name}
                                    onChange={this.handleChange.bind(this)}/>
                        <Form.Select fluid label='Protocol Type' placeholder='Protocol Type'
                                    name="protocol_type"
                                    value={this.state.newItem.protocol_type}
                                     options={[{key: 1, text: "Active", value: "active"},
                                         {key: 2, text: "Control", value: "control"}]}
                                    onChange={this.handleChange.bind(this)}/>
                        <label>Data</label>
                        <Editor
                            style={{height: 500}}
                            value={this.state.newItem.data}
                            search={false}
                            mode={'code'}
                            enableSort={false}
                            enableTransform={false}
                            history={false}
                            ace={ace}
                            onChange={this.handleJSONChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>
                        Cancel
                    </Button>
                    <Button color='teal' onClick={this.handleSubmit.bind(this)}>
                        <Icon name='plus'/> Save
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}
