import {client} from './apiClient'


const adminMasterTreatments = async (filter) => {
    if (!filter) filter = {}
    return await client.request({
        url: '/admin/treatments',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterTreatment = async (id) => {
    return await client.request({
        url: '/admin/treatment/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterTreatment = async (data) => {
    return await client.request({
        url: '/admin/treatment',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterTreatment = async (id, data) => {
    return await client.request({
        url: '/admin/treatment/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminMasterTreatments,
    adminMasterTreatment,
    adminCreateMasterTreatment,
    adminUpdateMasterTreatment
}
