import React from 'react';
import {
    Icon,
    Message,
    Segment,
    TransitionablePortal
} from "semantic-ui-react";

const AppMessage = props => (
    <TransitionablePortal open={props.saving !== -1}>
        <Segment
            style={{
                left: '38%',
                width: '35%',
                position: 'fixed',
                top: '30px',
                zIndex: 1000,
                padding: 0
            }}>{
            props.saving === 1 ? <Message icon info> <Icon name='circle notched' loading/>
                <Message.Content>
                    <Message.Header>Just one second</Message.Header>
                    We are doing it.
                </Message.Content>
            </Message> : props.saving === 2 ?
                <Message
                    error
                    header={props.header || 'Validation Failed'}
                    list={props.errors?.errors ? props.errors.errors.map(error => error.message): [props.message]}
                /> : <Message icon positive> <Icon name='info circle'/>
                    <Message.Content>
                        <Message.Header>Done</Message.Header>
                        {props.message || 'Done'}
                    </Message.Content>
                </Message>
        }
        </Segment>
    </TransitionablePortal>
);

export default AppMessage;
