import React, {Component} from "react";
import {
    Button,
    Table,
    Pagination,
} from "semantic-ui-react";
import {Link} from 'react-router-dom'
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Reports";
import {connect} from "react-redux";
import FilterReport from '../common/ui/FilterReport'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'
import moment from 'moment'

class Reports extends Component {
    state = {
        filter: {},
        page: 1
    }

    componentWillMount() {
        this.props.getReports()
    }

    componentWillReceiveProps() {
    }

    getTableData = props => {
        return props.reports.data.reports.filter(item => {
            return item.type.toLowerCase().includes(props.searchStore.text)
        }).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/report/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{moment(item.updated_at ? item.updated_at.substr(0, 16) : "").format('MMMM Do YYYY') + " at " + moment(item.updated_at ? item.updated_at.substr(0, 16) : "").format('h:mm:ss a')} </Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getReports(this.state.filter, data.activePage)
    }

    getFilterReport(filter) {
        this.setState({filter})
        this.props.getReports(filter, this.state.page)
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Reports",
            }]}/>,
            <AccordionSegment key={1} header={'Reports'} component={
                <div>
                    <FilterReport filter={this.getFilterReport.bind(this)}/>
                    {
                        this.props.reports.data.reports ?
                            <Table compact celled definition>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell/>
                                        <Table.HeaderCell>Report Name</Table.HeaderCell>
                                        <Table.HeaderCell>Last Updated</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{this.getTableData(this.props)}</Table.Body>
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={5}>
                                            <Pagination defaultActivePage={this.props.reports.data.paging.page}
                                                        totalPages={this.props.reports.data.paging.pages}
                                                        onPageChange={this.onPageChange.bind(this)}
                                                        size='mini'/>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table> :
                            <Loading/>
                    }
                </div>}/>
        ];
    }
}


export default connect(
    state => {
        const {searchStore, reports} = state;
        return {searchStore, reports}

    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Reports);
