import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as patientsActionCreators} from '../../store/Patients'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    Table,
    Grid,
    Radio,
    TransitionablePortal,
    Divider,
    Icon,
    Header, Pagination, Label, Modal, Dimmer,
    Progress, Input, Popup
} from 'semantic-ui-react'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'
import moment from 'moment'
import GridFormInput from '../common/ui/CompactTableGridFormInput'
import AppMessage from "../common/ui/AppMessage";
import {actionCreators as sitesActionCreators} from "../../store/Sites";
import {actionCreators as icsmedicationActionCreators} from "../../store/ICSMedications";
import {actionCreators as usersActionCreators} from "../../store/Users";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import ViewACQImageModal from '../common/modal/ViewACQImageModal'
import pcss1a from '../../assets/patient_control_script_screen_1a.js'
import pcss1b from '../../assets/patient_control_script_screen_1b.js'
import pcss1c from '../../assets/patient_control_script_screen_1c.js'
import pcss1d from '../../assets/patient_control_script_screen_1d.js'
import pcss1e from '../../assets/patient_control_script_screen_1e.js'
import pcss2a from '../../assets/patient_control_script_screen_2a.js'
import pcss2b from '../../assets/patient_control_script_screen_2b.js'
import pcss2c from '../../assets/patient_control_script_screen_2c.js'
import pcss2d from '../../assets/patient_control_script_screen_2d.js'
import pcss2e from '../../assets/patient_control_script_screen_2e.js'
import pcss3a from '../../assets/patient_control_script_screen_3a.js'
import pcss3b from '../../assets/patient_control_script_screen_3b.js'
import pcss3c from '../../assets/patient_control_script_screen_3c.js'
import pcss3d from '../../assets/patient_control_script_screen_3d.js'
import pcss3e from '../../assets/patient_control_script_screen_3e.js'
import pcss4 from '../../assets/patient_control_script_screen_4.js'
import PatientActiveScriptScreen1 from "../../assets/PatientActiveScriptScreen1";
import PatientActiveScriptScreen2 from "../../assets/PatientActiveScriptScreen2";
import PatientActiveScriptScreen3 from "../../assets/PatientActiveScriptScreen3";
import PatientActiveScriptScreen4 from "../../assets/PatientActiveScriptScreen4";
import PatientControleScriptScreenStart from "../../assets/PatientControleScriptScreenStart";
import ReactAudioPlayer from 'react-audio-player';
import fileDownload from 'js-file-download'
import axios from 'axios'
import RecordRTC from 'recordrtc'

let recorder = {}

let camsQuestion = [
    {text: "Having air movement from a fan, air conditioner or open window helps my asthma."},
    {text: "Praying, or having someone pray for me, helps my asthma."},
    {text: "Drinking coffee helps my asthma."},
    {text: "Using Vicks VapoRub helps my asthma."},
    {text: "Drinking water helps my asthma."},
    {text: "Drinking tea (herbal or regular) helps my asthma."},
    {text: "Steam or warm things on my chest helps my asthma."},
    {text: "My asthma can get worse if I go out with a wet head."},
    {text: "It is important to me that I find a natural way to treat my asthma."},
    {text: "I need my <ICSMedication> every day."},
    {text: "<ICSMedication> controls my asthma."},
    {text: "I am afraid that I will build up a tolerance to <ICSMedication>."},
    {text: "I make decisions about whether I need my <ICSMedication> on a day-by-day dose-by-dose basis."},
    {text: "I am the best judge of whether I need to take my <ICSMedication>."},
    {text: "<ICSMedication> can cause cancer, or damage the heart, liver or kidneys."},
    {text: "Taking my <ICSMedication> has caused side effects like weakness, dizziness, headache or sick-to-my-stomach."},
    {text: "Doctors get money from drug companies for writing prescriptions for my <ICSMedication>."},
]

class Patient extends Component {
    constructor(props) {
        super(props)

        this.state = {
            patient: {},
            loading: true,
            edit: false,
            saving: -1,
            started: false,
            step: 0,
            audioDetails: {
                url: null,
                blob: null,
                chunks: null,
                duration: {
                    h: 0,
                    m: 0,
                    s: 0
                }
            },
            showAudioControl: false,
            endProtocol: false,
            startProtocol: false,
            uploadedAudio: false,
            uploadButtonDisabled: true,
            openConfirm: false,
            timer: 0,
            halfprogress: 0,
            openConfirmPause: false,
            openConfirmResume: false,
            openConfirmStop: false,
            isPaused: false
        }
    }


    async componentWillMount() {
        this.props.getMasterSites()
        this.props.getMasterICSMedications()
        this.props.getProviderUser({roles: 'clinician'})
        this.props.getPatient(this.props.match.params.id)
        this.props.getPatientACQIMage(this.props.match.params.id)
        this.props.getPatientFiles(this.props.match.params.id)
        this.props.getCustomActiveHtml(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {
        let disable_continue = false
        if (this.state.step === 0) {
            const protocolType = nextProps.patients.patient ? nextProps.patients.patient.treatment ? nextProps.patients.patient.treatment.protocol_type : "" : ""
            disable_continue = protocolType === "active"
        }
        this.setState({
            progress: this.props.patients.progress,
            loading: !nextProps.patients.patient,
            patient: nextProps.patients.patient,
            acqimage: nextProps.patients.acqimage,
            files: nextProps.patients.files,
            disable_continue
        })
    }

    handleChange = (e, target) => {
        let patient = this.state.patient
        patient[target.name] = target.name === "active" || target.name === "required" ? target.checked : target.value
        this.setState({patient, saving: -1, message: null, error: null})
    }
    handleSiteChange = (e, target) => {
        let patient = this.state.patient
        patient.siteId = target.value
        this.props.getProviderUser({siteId: target.value})
        patient.providerId = null
        this.setState({patient, saving: -1, message: null, error: null})
    }

    toggleMessage(callback) {
        setTimeout(() => {
            this.setState({saving: -1, message: null, header: null})
            if (callback) callback()
        }, 3000)
    }

    async handleSave() {
        this.setState({saving: 1})

        const date = moment(this.state.patient.enrollDate).format('MM/DD/YYYY')
        let errors = await this.props.updatePatient(this.props.match.params.id,
            {
                ...this.state.patient,
                enrollDate: this.state.patient.enrollDate ? date : null
            })
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            this.props.getPatientACQIMage(this.props.match.params.id)
            this.setState({edit: false, saving: 0, message: "Successfully saved patient"})
            this.toggleMessage()
        }
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    handleCancel = () => {
        this.props.getPatient(this.props.match.params.id)
        this.setState({edit: false, saving: -1, message: null, error: null})
    }

    handleToggle = (field, e, target) => {
        let patient = this.state.patient
        patient[field] = target.checked ? target.value : null
        this.setState({patient, saving: -1, message: null, error: null})
    }

    async handleUpdateReadiness(e, data) {
        console.log(data.activePage)
        this.setState({saving: 1})
        let errors = await this.props.updatePatient(this.props.match.params.id, {readiness: data.activePage})
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            let patient = this.state.patient
            patient.readiness = data.activePage
            await this.props.getPatients()
            this.setState({patient, edit: false, saving: 0, message: "Successfully saved readiness"})
            this.toggleMessage()
        }
    }

    startProtocol() {
        this.setState({startProtocol: true, showAudioControl: true})
    }

    startRecording() {
        navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true
        }).then(async (stream) => {
            recorder = RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/webm',
            });
            recorder.startRecording();
            this.setState({started: true})
        }).catch((e)=>{
            this.setState({saving: 2, message: "Be sure to have microphone device available and enabled", header: e.message})
            this.toggleMessage()
        });
    }

    stopRecording() {
        this.setState({uploadButtonDisabled: false});

        recorder.stopRecording(async () => {
            let blob = recorder.getBlob();
            var file = new File([blob], "name");
            let response = await this.props.uploadAudioMedia(file, this.state.patient.id)
            if (response.errors !== undefined) {
                this.setState({saving: 2, errors: response, message: response.message})
            } else {
                recorder.reset()
                recorder.destroy()
                this.setState({
                    edit: false,
                    saving: 0,
                    uploadedAudio: true,
                    started: false,
                    showAudioControl: false,
                    message: "Successfully uploaded audio file"
                })
                this.toggleMessage()
            }
        });
    }
    handleStop() {
        this.setState({uploadButtonDisabled: false, openConfirmStop: false});
        recorder.stopRecording(async () => {
            let blob = recorder.getBlob();
            var file = new File([blob], "name");
            let response = await this.props.uploadAudioMedia(file, this.state.patient.id)
            if (response.errors !== undefined) {
                this.setState({saving: 2, errors: response, message: response.message})
            } else {
                recorder.reset()
                recorder.destroy()

                this.setState({
                    edit: false,
                    saving: 0,
                    message: "Successfully uploaded audio file"
                })
                this.toggleMessage(() => {
                    this.setState({showAudioControl: false})
                    this.props.history.push("/app/patients")
                })
            }
        });
    }
    handlePause(){
        this.setState({openConfirmPause: false, isPaused: true, saving: 2,  message: ["Recording has paused"], errors:{}, header: "Pause"})
        recorder.pauseRecording()
    }
    handleResume(){
        this.setState({openConfirmResume: false, isPaused: false, saving: 0,  message: "Recording has continued"})
        recorder.resumeRecording()
        this.toggleMessage()
    }
    async handleEndProtocol() {
        this.setState({endProtocol: true, started: false, saving: 1, uploadedAudio: true})
        let errors = await this.props.updatePatient(this.props.match.params.id, {"protocol_finished": true})
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getPatients()
            this.setState({edit: false, saving: 0, message: "Successfully ended protocol on this patient"})
            this.toggleMessage(() => {
                this.setState({showAudioControl: false})
                this.props.history.push("/app/patients")
            })
        }
    }

    handleType(type) {
        this.setState({type})
    }

    handleContinue() {
        let step = this.state.step;
        step += 1
        this.setState({step})
    }

    handleEnableContinue(disable_continue) {
        this.setState({disable_continue})
    }

    handlePreviousControl() {
        this.setState({type: null, step: 0})
    }

    handlePrevious() {
        let step = this.state.step;
        step -= 1
        this.setState({step})
    }

    async handleDelete(item) {
        let response = await this.props.removePatientFile(this.state.patient.id, item.id)
        if (response.errors !== undefined) {
            this.setState({saving: 2, errors: response, message: response.message})
        } else {
            await this.props.getPatientFiles(this.props.match.params.id)
            this.setState({
                openConfirm: false,
                edit: false,
                saving: 0,
                uploadedAudio: true,
                started: false,
                showAudioControl: false,
                message: "Successfully removed audio file"
            })
            this.toggleMessage()
        }
    }

    async handleDownload(item) {
        await axios.get(item.fileSignedUrl, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, item.fileName + ".mp3")
            })
    }

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getPatientFiles(this.props.match.params.id, data.activePage)
    }
    getTableData = props => {
        return props.patients.files.files.map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell>{item.fileName}</Table.Cell>
                <Table.Cell>{item.fileType}</Table.Cell>
                <Table.Cell style={{verticalAlign: "text-top"}}>
                    <ReactAudioPlayer
                        src={item.fileSignedUrl}
                        controls
                        style={{height: "35px"}}
                    />
                    <Button style={{verticalAlign: "top"}}
                            labelPosition="right"
                            color="teal"
                            size="small" icon onClick={this.handleDownload.bind(this, item)}> Download
                        <Icon name='download'/>
                    </Button>
                    <Modal

                        size='small'
                        style={{height: "fit-content", margin: "auto"}}
                        onClose={() => this.setState({openConfirm: false})}
                        onOpen={() => this.setState({openConfirm: true, item})}
                        open={this.state.openConfirm}
                        trigger={<Button style={{verticalAlign: "top"}}
                                         labelPosition="right"
                                         negative
                                         size="small" icon
                                         onClick={() => this.setState({openConfirm: true, item})}> Delete
                            <Icon name='trash'/>
                        </Button>}
                    >
                        <Modal.Header>Confirm Delete</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <p>Are you sure you want to delete this file?</p>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({openConfirm: false})}>
                                No
                            </Button>
                            <Button
                                onClick={this.handleDelete.bind(this, item)}
                                negative
                            >Yes</Button>
                        </Modal.Actions>
                    </Modal>
                </Table.Cell>
            </Table.Row>);
    };

    render() {


        let readOnly = !this.state.edit
        let patient = this.state.patient
        let acqimage = this.state.acqimage

        const site = this.props.sites.master.sites ? this.props.sites.master.sites.find(find => find.id === patient.siteId) : null
        const provider = this.props.users.master.users ? this.props.users.master.users.find(find => find.id === patient.providerId) : null
        const icsMedication = this.props.icsmedications.master.ics_medications ? this.props.icsmedications.master.ics_medications.find(find => find.id === patient.icsMedicationId) : null

        let controlScreen = {
            a: [{title: "Patient Protocol Screen 1a", text: pcss1a},
                {title: "Patient Protocol Screen 2a", text: pcss2a},
                {title: "Patient Protocol Screen 3a", text: pcss3a},
                {title: "Patient Protocol Save Audio", text: pcss4}
            ],
            b: [{title: "Patient Protocol Screen 1b", text: pcss1b},
                {title: "Patient Protocol Screen 2b", text: pcss2b},
                {title: "Patient Protocol Screen 3b", text: pcss3b},
                {title: "Patient Protocol Save Audio", text: pcss4}
            ],
            c: [{title: "Patient Protocol Screen 1c", text: pcss1c},
                {title: "Patient Protocol Screen 2c", text: pcss2c},
                {title: "Patient Protocol Screen 3c", text: pcss3c},
                {title: "Patient Protocol Save Audio", text: pcss4}
            ],
            d: [{title: "Patient Protocol Screen 1d", text: pcss1d},
                {title: "Patient Protocol Screen 2d", text: pcss2d},
                {title: "Patient Protocol Screen 3d", text: pcss3d},
                {title: "Patient Protocol Save Audio", text: pcss4}
            ],
            e: [{title: "Patient Protocol Screen 1e", text: pcss1e},
                {title: "Patient Protocol Screen 2e", text: pcss2e},
                {title: "Patient Protocol Screen 3e", text: pcss3e},
                {title: "Patient Protocol Save Audio", text: pcss4}
            ]
        }
        let defaultControlScreen = {
            title: "Patient Protocol Screen",
            component: <PatientControleScriptScreenStart handleType={this.handleType.bind(this)}/>
        }
        let activeScreen = [
            {
                title: "STEP 1: Raise The Subject",
                component: <PatientActiveScriptScreen1 patient={patient} user={this.props.login.user}
                                                       acqimage={acqimage}
                                                       handleEnd={this.handleEndProtocol.bind(this)}
                                                       handleContinue={this.handleEnableContinue.bind(this)}
                                                       clearAnswer={() => this.setState({disable_continue: true})}/>
            },
            {
                title: "STEP 2: Provide Feedback",
                component: <PatientActiveScriptScreen2 patient={patient} user={this.props.login.user}
                                                       script={this.props.patients.script}
                                                       acqimage={acqimage}/>
            },
            {
                title: "STEP 3: Enhance Motivation",
                component: <PatientActiveScriptScreen3 patient={patient} user={this.props.login.user}
                                                       acqimage={acqimage}
                                                       handleUpdateReadiness={this.handleUpdateReadiness.bind(this)}/>
            },
            {
                title: "STEP 4: Negotiate and Advise ",
                component: <PatientActiveScriptScreen4 patient={patient} user={this.props.login.user}
                                                       acqimage={acqimage}/>
            },
            {title: "Patient Protocol Save Audio", text: pcss4}]

        const protocolType = patient ? patient.treatment ? patient.treatment.protocol_type : "" : ""
        const step = this.state.step
        const type = this.state.type

        const screen = protocolType === 'control' ? type ? controlScreen[type][step] : defaultControlScreen : activeScreen[step]
        const previousScreen = protocolType === 'control' ? type ? controlScreen[type][step - 1] : null : activeScreen[step - 1]
        const nextScreen = protocolType === 'control' ? type ? controlScreen[type][step + 1] : (previousScreen ? null : {}) : activeScreen[step + 1]

        const treatment = patient ? patient.treatment ? patient.treatment.name : "" : ""


        let isClinician = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'clinician') : false
        let isAdmin = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'admin') : false
        let isResearchAssistant = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'research_assistant') : false

        let createdAt = patient.created_at ? patient.created_at.substr(0, 16) : ""
        let updatedAt = patient.updated_at ? patient.updated_at.substr(0, 16) : ""

        let round = (value, precision) => {
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        }
        let pauseResumeButtons = <Grid.Column style={{textAlign: 'center'}}>
            {this.state.isPaused ?
                <Button icon labelPosition={'left'} color='teal'
                        onClick={this.handleResume.bind(this)}><Icon
                    name='play'/>Resume </Button> :
                <Modal
                    size='small'
                    style={{height: "fit-content", margin: "auto"}}
                    onClose={() => this.setState({openConfirmPause: false})}
                    onOpen={() => this.setState({openConfirmPause: true})}
                    open={this.state.openConfirmPause}
                    trigger={<Button icon labelPosition={'left'}
                                     onClick={() => this.setState({openConfirmPause: false})}><Icon
                        name='pause'/>Pause </Button>}
                >
                    <Modal.Header>Confirm Pause</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Are you sure you want to pause the audio recording? Pause audio recording when you expect to be
                                called away from the patient visit for more than 20 minutes.</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({openConfirmPause: false})}>
                            No
                        </Button>
                        <Button
                            onClick={this.handlePause.bind(this)}
                            negative
                        >Yes</Button>
                    </Modal.Actions>
                </Modal>
            }
            <Modal
                size='small'
                style={{height: "fit-content", margin: "auto"}}
                onClose={() => this.setState({openConfirmStop: false})}
                onOpen={() => this.setState({openConfirmStop: true})}
                open={this.state.openConfirmStop}
                trigger={<Button icon labelPosition={'left'}
                                 onClick={() => this.setState({openConfirmStop: false})}><Icon
                    name='stop'/>Stop </Button>}
            >
                <Modal.Header>Confirm Stop</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Are you sure you want to stop the audio recording? Stop audio recording when you expect to be called
                            away from the patient visit for more than 1 hour or if the patient needs to be rescheduled.</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({openConfirmStop: false})}>
                        No
                    </Button>
                    <Button
                        onClick={this.handleStop.bind(this)}
                        negative
                    >Yes</Button>
                </Modal.Actions>
            </Modal>
        </Grid.Column>;
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Patients",
                link: "/app/patients"
            }, {
                text: "Patient"
            }]}/>,
            <AccordionSegment key={1} header={'Patient Information'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}>
                            <AppMessage message={this.state.message} saving={this.state.saving}
                                        errors={this.state.errors} header={this.state.header || "Warning"}/>
                        </Segment>
                    </TransitionablePortal>

                    {this.state.loading ? <Loading/> :
                        <Form>
                            <Grid style={{marginTop: 0}}>
                                {this.state.started ? <Grid.Row columns={3}>
                                    <Grid.Column style={{textAlign: 'left'}}>
                                        {step < 1 ? (protocolType === 'control' && type ?
                                                <Button icon labelPosition={'left'} color='teal' floated="left"
                                                        onClick={this.handlePreviousControl.bind(this)}><Icon
                                                    name='backward'/>Previous </Button> : null) :
                                            <Button icon labelPosition={'left'} color='teal' floated="left"
                                                    onClick={this.handlePrevious.bind(this)}><Icon
                                                name='backward'/>Previous </Button>}
                                    </Grid.Column>
                                    {nextScreen ? pauseResumeButtons : null}
                                    <Grid.Column style={{textAlign: 'right'}}>
                                        {!nextScreen || (nextScreen && !(nextScreen.component || nextScreen.text)) ? null :
                                            <Button disabled={this.state.disable_continue} icon labelPosition={'right'}
                                                    color='teal' floated="right"
                                                    onClick={this.handleContinue.bind(this)}><Icon></Icon>Continue<Icon
                                                name='forward'/></Button>}
                                    </Grid.Column>
                                </Grid.Row> : null}
                                <Grid.Row>
                                    <Grid.Column width={16}
                                                 style={{textAlign: this.state.started && nextScreen ? 'left' : 'center'}}>
                                        {this.state.started ? <Header as='h3'>{screen.title}</Header> : null}
                                        {this.state.showAudioControl ?
                                            <div style={this.state.started && nextScreen ? {
                                                position: "absolute",
                                                top: -1000,
                                                textAlign: 'center',
                                            } : {maxHeight: 200}}>
                                                {!nextScreen ?
                                                    <span>
                                                        <strong> Note to clinician: </strong>Please save/upload the audio before ending the protocol.
                                                        Click on the End Recording button to save the audio file to our server.
                                                        <br/>
                                                        <br/>
                                                            <Dimmer
                                                                active={!this.state.uploadButtonDisabled}
                                                                page blurring>
                                                                <Progress size='large'
                                                                          progress
                                                                          percent={Math.round(this.props.patients.progress)}
                                                                          inverted
                                                                          color='green'/>
                                                                <span><strong> Note to clinician: </strong>
                                                                    Please wait while we upload the audio file to the server. Don’t close out of this window. <br/>
                                                                    Once your file has been uploaded, you will be returned to the End protocol page, please click End Protocol, <br/>
                                                                    and you will be redirected to your patient panel. This may take a few minutes
                                                                </span>
                                                            </Dimmer>
                                                                <Button icon labelPosition={'right'}
                                                                        color='teal'
                                                                        disabled={!this.state.uploadButtonDisabled}
                                                                        onClick={this.stopRecording.bind(this)}>

                                                        <Icon name='stop'/>End Recording</Button>
                                                    </span> : this.state.endProtocol ? null :
                                                        <span style={{fontSize: "15px"}}><strong> Note to clinician: </strong>Please begin the recording once you start your patient visit NOT when you start the intervention. The entire visit from start to end should be recorded.
                                                    <br/>
                                                    <br/>
                                                        <Button icon labelPosition={'right'}
                                                                color='teal'
                                                                onClick={this.startRecording.bind(this)}><Icon name='record'/>Start Recording</Button>
                                                    <br/>
                                                    <br/>
                                                    Please click Start Recording. Do not use Bluetooth devices.
                                                        </span>
                                                }
                                                <br/>
                                            </div> : null}

                                        {this.state.started ? (screen.text ? <div
                                            dangerouslySetInnerHTML={{__html: screen.text}}></div> : screen.component) : null}
                                    </Grid.Column>
                                </Grid.Row>
                                {this.state.started ? <Grid.Row columns={3}>
                                    <Grid.Column style={{textAlign: 'left'}}>
                                        {step < 1 ? (protocolType === 'control' && type ?
                                                <Button icon labelPosition={'left'} color='teal' floated="left"
                                                        onClick={this.handlePreviousControl.bind(this)}><Icon
                                                    name='backward'/>Previous </Button> : null) :
                                            <Button icon labelPosition={'left'} color='teal' floated="left"
                                                    onClick={this.handlePrevious.bind(this)}><Icon
                                                name='backward'/>Previous </Button>}
                                    </Grid.Column>
                                    {nextScreen ? pauseResumeButtons : null}
                                    <Grid.Column style={{textAlign: 'right'}}>
                                        {!nextScreen || (nextScreen && !(nextScreen.component || nextScreen.text)) ? null :
                                            <Button disabled={this.state.disable_continue} icon labelPosition={'right'}
                                                    color='teal'
                                                    onClick={this.handleContinue.bind(this)}>Continue<Icon
                                                name='forward'/></Button>}
                                    </Grid.Column>
                                </Grid.Row> : null}
                            </Grid>
                            {isClinician ?
                                (this.state.uploadedAudio ?
                                    <Grid style={{marginTop: 0}}>
                                        <Grid.Row centered>
                                            <Grid.Column style={{textAlign: 'center'}}>
                                                <Header as='h3'>Patient Protocol Screen End</Header>
                                                <strong>Note to clinician</strong>: Please click the end protocol
                                                button
                                                to be brought back to the updated patient list.
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row centered>
                                            <Grid.Column style={{textAlign: 'center'}}>
                                                <Button disabled={!this.state.uploadedAudio} icon
                                                        labelPosition={'right'} color='teal'
                                                        onClick={this.handleEndProtocol.bind(this)}>End
                                                    Protocol <Icon
                                                        name='stop'/></Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> :
                                    (this.state.showAudioControl ? null :
                                        <Grid style={{marginTop: 0}}>
                                            <Grid.Row centered>
                                                <Grid.Column style={{textAlign: 'center'}}>
                                                    <strong>ICS Medication</strong>: {patient.ics}
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row centered>
                                                <Grid.Column style={{textAlign: 'center'}}>
                                                    <ViewACQImageModal acqimage={acqimage}/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row centered>
                                                <Grid.Column style={{textAlign: 'center'}}>
                                                    <Button icon labelPosition={'right'} color='teal'
                                                            onClick={this.startProtocol.bind(this)}>
                                                        <Icon name='play'/>Start
                                                        Protocol</Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>)) :
                                <Grid columns='equal' style={{marginTop: 0}}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            {isResearchAssistant && patient.protocol_finished ? null :
                                                (readOnly ? <Button color='teal' style={{float: "right"}}
                                                                    onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                                    [<Button key={0} color='teal' style={{float: "right"}}
                                                             onClick={this.handleSave.bind(this)}>Save</Button>,
                                                        <Button key={1} style={{float: "right"}}
                                                                onClick={this.handleCancel.bind(this)}>Cancel</Button>])
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    {
                                        readOnly ?
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <GridFormInput
                                                        label="Nickname"
                                                        component={patient.nickname}
                                                    />
                                                    <GridFormInput
                                                        label="Site ID"
                                                        component={site ? site.name : patient.siteId}
                                                    />
                                                    <GridFormInput
                                                        label="Provider ID"
                                                        component={provider ? provider.firstName + " " + provider.lastName : patient.providerId}
                                                    />
                                                    <GridFormInput
                                                        label="ICS Medication"
                                                        component={icsMedication ? icsMedication.name : patient.icsMedicationId}
                                                    />
                                                    <GridFormInput
                                                        label="Enroll Date"
                                                        fieldclassName={'date-time-picket'}
                                                        component={patient.enrollDate ? moment(patient.enrollDate).format('MM/DD/YYYY') : "Not Set"}
                                                    />
                                                    <GridFormInput
                                                        label="Status"
                                                        component={patient.status ? (patient.status === 'Active' ?
                                                            <Label
                                                                color={"green"}>{patient.status.toUpperCase()}</Label> :
                                                            <Label
                                                                color={"red"}>{patient.status.toUpperCase()}</Label>) : null}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <GridFormInput
                                                        label="ACQ"
                                                        component={<div
                                                            style={{display: 'flex', alignItems: "baseline"}}>
                                                            <Label size={"large"}>{(patient.acq * 1).toFixed(1)}</Label>
                                                            &nbsp;
                                                            &nbsp;
                                                            <div style={{width: "100%"}}><ViewACQImageModal
                                                                acqimage={acqimage}/></div>
                                                        </div>}
                                                    />
                                                    <GridFormInput
                                                        label="Protocol Completed"
                                                        component={patient.protocol_finished ? 'TRUE' : "FALSE"}
                                                    />

                                                    <GridFormInput
                                                        label="Created"
                                                        component={moment(createdAt).format('MMM DD YYYY h:mm a')}
                                                    />
                                                    <GridFormInput
                                                        label="Updated"
                                                        component={moment(updatedAt).format('MMM DD YYYY h:mm a')}
                                                    />
                                                    {isAdmin ?
                                                        <GridFormInput
                                                            label="Treatment"
                                                            component={treatment}
                                                        /> : null}
                                                </Grid.Column>

                                            </Grid.Row> :
                                            null}
                                    {readOnly && protocolType !== 'control' && isAdmin ? <Grid.Row>
                                        <Grid.Column>
                                            <GridFormInput
                                                label="Readiness"
                                                component={<div>
                                                    {patient.readiness ? <Input
                                                        readOnly
                                                        min={1}
                                                        max={10}
                                                        type='range'
                                                        value={patient.readiness}
                                                        style={{width: 458}}
                                                    /> : null
                                                    }
                                                    <br/>
                                                    <Pagination
                                                        readOnly
                                                        activePage={patient.readiness}
                                                        boundaryRange={10}
                                                        size='large'
                                                        totalPages={10}
                                                        firstItem={null}
                                                        lastItem={null}
                                                        prevItem={null}
                                                        nextItem={null}
                                                        pageItem={(Item, {value, ...props}) => {
                                                            let content = value
                                                            let style = props.active ? {
                                                                background: "#00b5ad",
                                                                color: "white",
                                                                textDecoration: 'none'
                                                            } : {textDecoration: 'none'}
                                                            let component = value === 1 ? <Popup
                                                                content='Not Ready'
                                                                position='bottom left'
                                                                trigger={<Item value={value} {...props}
                                                                               content={content} style={style}/>}
                                                            /> : value === 10 ? <Popup
                                                                content='Very Ready'
                                                                position='bottom right'
                                                                trigger={<Item value={value} {...props}
                                                                               content={content} style={style}/>}
                                                            /> : value === 5 ? <Popup
                                                                content='Somewhat Ready'
                                                                position='bottom center'
                                                                trigger={<Item value={value} {...props}
                                                                               content={content} style={style}/>}
                                                            /> : <Item value={value} {...props} content={content}
                                                                       style={style}/>
                                                            return component
                                                        }}
                                                    />
                                                    <br/>
                                                    <br/>
                                                </div>}
                                            />
                                        </Grid.Column>
                                    </Grid.Row> : null}
                                    {readOnly ? null : <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <label>Nickname</label>
                                                <Form.Input
                                                    fluid
                                                    placeholder='Nickname'
                                                    readOnly={readOnly}
                                                    value={patient.nickname}
                                                    name="nickname"
                                                    onChange={this.handleChange.bind(this)}/>
                                            </Form.Field>
                                            <Form.Select
                                                fluid
                                                label='Site' placeholder='Site'
                                                value={patient.siteId}
                                                options={this.props.sites.master.sites ? this.props.sites.master.sites.map((site, index) => {
                                                    return {key: index, text: site.name, value: site.id}
                                                }) : []}
                                                name="siteId"
                                                onChange={this.handleSiteChange.bind(this)}
                                            />
                                            <Form.Select
                                                fluid
                                                label='Provider' placeholder='Provider'
                                                value={patient.providerId}
                                                options={this.props.users.master.users ? this.props.users.master.users.map((site, index) => {
                                                    return {
                                                        key: index,
                                                        text: site.firstName + " " + site.lastName,
                                                        value: site.id
                                                    }
                                                }) : []}
                                                name="providerId"
                                                onChange={this.handleChange.bind(this)}
                                            />

                                            <Form.Select
                                                fluid
                                                label='Status' placeholder='Status'
                                                value={patient.status}
                                                options={[{key: 0, text: "Active", value: "Active"},
                                                    {key: 1, text: "Inactive", value: "Inactive"}]}
                                                name="status"
                                                onChange={this.handleChange.bind(this)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Form.Select
                                                fluid
                                                label='ICS Medication' placeholder='ICS Medication'
                                                value={patient.icsMedicationId}
                                                options={this.props.icsmedications.master.ics_medications ? this.props.icsmedications.master.ics_medications.map((site, index) => {
                                                    return {key: index, text: site.name, value: site.id}
                                                }) : []}
                                                name="icsMedicationId"
                                                onChange={this.handleChange.bind(this)}
                                            />
                                            <SemanticDatepicker
                                                className={"date-time-picker"}
                                                style={{width: '100%'}}
                                                fluid
                                                datePickerOnly
                                                label='Enroll Date'
                                                placeholder='Enroll Date'
                                                name="enrollDate"
                                                value={patient.enrollDate ? new Date(patient.enrollDate) : null}
                                                format={"MM/DD/YYYY"}
                                                onChange={this.handleChange.bind(this)}
                                            />

                                            <Form.Select
                                                fluid
                                                label='Protocol Completed' placeholder='Protocol Completed'
                                                value={patient.protocol_finished}
                                                options={[{key: 0, text: "TRUE", value: 1},
                                                    {key: 1, text: "FALSE", value: 0}]
                                                }
                                                name="protocol_finished"
                                                onChange={this.handleChange.bind(this)}
                                            />
                                            <Form.Field>
                                                <label>ACQ</label>
                                                <div style={{display: 'flex', alignItems: "baseline"}}>
                                                    <div style={{width: "100%"}}><Form.Select
                                                        clearable
                                                        search
                                                        selection
                                                        fluid
                                                        placeholder='ACQ'
                                                        name="acq"
                                                        value={patient.acq}
                                                        onChange={this.handleChange.bind(this)}
                                                        options={Array.from(Array(61).keys()).map(key => {
                                                            return {
                                                                key: key,
                                                                text: (key * 0.1).toFixed(1),
                                                                value: round(key * 0.1, 1)
                                                            }
                                                        })}
                                                    /></div>
                                                    &nbsp;
                                                    &nbsp;
                                                    <div style={{width: "100%"}}><ViewACQImageModal
                                                        acqimage={acqimage}/></div>
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    }
                                    <Grid.Row>
                                        <Grid.Column>

                                            <Divider horizontal>CAMA</Divider>
                                            <div>
                                                <Header as={"h5"} style={{textAlign: "center"}}>
                                                    The Conventional and Alternative Management (CAM) Instrument for
                                                    Asthma - PATIENT
                                                </Header>
                                                <span>
                                                    <strong>DIRECTIONS</strong>: This survey asks about your beliefs, opinions and favorite ways to manage your asthma. As you read
                                                    each statement check the box that most correctly describes how you feel. You can choose any number between 1(strongly disagree)
                                                    to 7(strongly agree).
                                                </span>
                                            </div>
                                            <Table celled definition compact striped className={"cama"}>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell/>
                                                        <Table.HeaderCell width={1}>Strongly
                                                            disagree<br/>1</Table.HeaderCell>
                                                        <Table.HeaderCell width={1}><br/>2</Table.HeaderCell>
                                                        <Table.HeaderCell width={1}><br/>3</Table.HeaderCell>
                                                        <Table.HeaderCell width={1}><br/>4</Table.HeaderCell>
                                                        <Table.HeaderCell width={1}><br/>5</Table.HeaderCell>
                                                        <Table.HeaderCell width={1}><br/>6</Table.HeaderCell>
                                                        <Table.HeaderCell width={1}>Strongly
                                                            agree<br/>7</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {camsQuestion.map((question, index) => {
                                                        const camaField = "cama" + (index + 1)
                                                        const camaValue = patient[camaField]
                                                        return <Table.Row key={index}>
                                                            <Table.Cell>{index + 1}. {question.text}</Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={1}
                                                                               checked={camaValue === 1}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={2}
                                                                               checked={camaValue === 2}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={3}
                                                                               checked={camaValue === 3}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={4}
                                                                               checked={camaValue === 4}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={5}
                                                                               checked={camaValue === 5}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={6}
                                                                               checked={camaValue === 6}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                            <Table.Cell><Radio readOnly={readOnly} value={7}
                                                                               checked={camaValue === 7}
                                                                               onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        </Table.Row>
                                                    })}

                                                </Table.Body>
                                            </Table>
                                            <br/>
                                            {isResearchAssistant && patient.protocol_finished ? null :
                                                (readOnly ? <Button color='teal' style={{float: "right"}}
                                                                    onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                                    [<Button key={0} color='teal' style={{float: "right"}}
                                                             onClick={this.handleSave.bind(this)}>Save</Button>,
                                                        <Button key={1} style={{float: "right"}}
                                                                onClick={this.handleCancel.bind(this)}>Cancel</Button>])
                                            }
                                            <br/>
                                            <br/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            }
                        </Form>}
                </div>}/>,
            isClinician ? null :
                <AccordionSegment key={2} header={'Audio Files'} component={
                    <div>
                        {this.props.patients.files.files ?
                            <Table compact celled definition>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>File Name</Table.HeaderCell>
                                        <Table.HeaderCell>File Type</Table.HeaderCell>
                                        <Table.HeaderCell/>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>{this.getTableData(this.props)}</Table.Body>
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={7}>
                                            <Pagination
                                                defaultActivePage={this.props.patients.files.paging.page}
                                                totalPages={this.props.patients.files.paging.pages}
                                                onPageChange={this.onPageChange.bind(this)}
                                                size='mini'/>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table> : <Loading/>

                        }
                    </div>}/>
        ]
    }
}

export default connect(
    state => {
        const {searchStore, patients, sites, treatments, icsmedications, users, login} = state;
        return {searchStore, patients, sites, treatments, icsmedications, users, login}
    },
    dispatch => bindActionCreators({...patientsActionCreators, ...sitesActionCreators, ...icsmedicationActionCreators, ...usersActionCreators}, dispatch)
)(Patient)
