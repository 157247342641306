import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as curriculumActionCreators} from '../../store/Curriculums'
import {actionCreators as topicActionCreators} from '../../store/Topics'
import {actionCreators as deviceActionCreators} from '../../store/Devices'
import 'semantic-ui-css/semantic.min.css'
import {
    Icon,
    Button
} from 'semantic-ui-react'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'
import {Timeline, TimelineEvent} from 'react-event-timeline'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller';
import FilterTimeline from '../common/ui/FilterTimeline'

class CurriculumTimeline extends Component {
    state = {
        filter: {},
        page: 1,
        loading: true,
        currentIndex: {
            activeCurriculum: 0
        }
    }

    componentWillMount() {
        this.props.getDeviceCurriculumActivities(this.props.match.params.deviceid, this.props.match.params.id)
        this.props.getDevice(this.props.match.params.deviceid)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: !this.props.curriculums.curriculum, loadingActivities: false})
    }

    handleClick = (option, isSuper, e, titleProps) => {
        const {index} = titleProps
        const {currentIndex} = this.state
        if (option === 'activeCurriculum' || isSuper) {
            currentIndex['activeTopic'] = -1
        }
        const newIndex = currentIndex[option] === index ? -1 : index
        currentIndex[option] = newIndex
        this.setState({currentIndex})
    }

    getTimeText(time) {
        let timeMinutes = Math.floor(time / 60000)
        let remainderMinutes = time % 60000
        let timeSeconds = Math.floor(remainderMinutes / 1000)
        return (timeMinutes < 1 ? "" : timeMinutes + " minutes and ") + (timeSeconds < 1 ? " less than a second" : timeSeconds + " seconds")
    }

    loadMore(page) {
        let activities = this.props.curriculums.activities
        if (activities && !this.state.loadingActivities) {
            this.setState({loadingActivities: true})
            let paging = activities.paging
            let newPage = paging.page + 1
            if (newPage <= paging.pages)
                this.props.getDeviceCurriculumActivities(this.props.match.params.deviceid, this.props.match.params.id, newPage, this.state.filter)
        }

    }

    getFilterTimeline(filter) {
        this.setState({filter})
        this.props.getDeviceFilterCurriculumActivities(this.props.match.params.deviceid, this.props.match.params.id, filter)
    }

    downloadCSV() {
        console.log(this.props.devices.device)

        let a = document.createElement('a');
        a.href = this.props.devices.device.summary.raw_device_csv_url;
        a.target = "_blank"
        a.click();
    }

    downloadJSON() {
        let a = document.createElement('a');
        a.href = this.props.devices.device.summary.raw_device_json_url;
        a.target = "_blank"
        a.click();
    }

    render() {
        let device = this.props.devices.device
        let activities = this.props.curriculums.activities
        let dates = []
        let hasMore = false
        if (activities) {
            dates = [...new Set(activities.activities.map(activity => activity.date))]
            hasMore = activities.paging.pages !== activities.paging.page
        }

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Devices",
                link: "/app/devices"
            }, {
                text: "Device",
                link: "/app/device/" + this.props.match.params.deviceid
            }, {
                text: "Curriculum",
                link: "/app/device/" + this.props.match.params.deviceid + "/curriculum/" + this.props.match.params.id
            }, {
                text: "Timeline",
            }]}/>,

            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Curriculum Timeline'} component={
                <div className={'timeline'}>
                    {device.summary && device.summary.raw_device_csv_url ?
                        <Button icon labelPosition='right' color='teal' size="small"
                                onClick={this.downloadCSV.bind(this)}>Download CSV<Icon
                            name='download'/></Button> : null}
                    {device.summary && device.summary.raw_device_json_url ?
                        <Button icon labelPosition='right' color='teal' size="small"
                                onClick={this.downloadJSON.bind(this)}>Download
                            JSON<Icon name='download'/></Button> : null}
                    <FilterTimeline filter={this.getFilterTimeline.bind(this)}/>
                    <br/>
                    {this.state.loading ? <Loading/> :
                        activities && activities.paging.count ? <InfiniteScroll
                            key={1}
                            pageStart={1}
                            loadMore={this.loadMore.bind(this)}
                            hasMore={hasMore}
                            threshold={700}
                            loader={<div className="loader" key={1}><Icon loading
                                                                          name='asterisk'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Loading More</div>}>
                            <Timeline style={{fontSize: 15, marginLeft: 170, width: "calc(95% - 170)"}}>
                                {
                                    dates.map(date => {
                                        let activitiesDates = activities.activities.filter(activity => {
                                            return activity.date === date
                                        })
                                        return activitiesDates.map((activity, index) => {
                                            return <TimelineEvent
                                                container="card"
                                                key={index}
                                                title={activity.action}
                                                createdAt={index === 0 ?
                                                    <h4>{moment(date).format('MMMM Do YYYY')}</h4> : ""}
                                                collapsible
                                                showContent
                                            > <React.Fragment>
                                                {activity.topic ? 'Topic: ' + activity.topic : null}
                                                {activity.topic ? <br/> : null}
                                                {activity.content ? 'Content: ' + activity.content : null}
                                                {activity.content ? <br/> : null}
                                                {activity.duration ? <span
                                                    style={{color: 'red'}}>Duration: {this.getTimeText(activity.duration)} </span> : null}
                                            </React.Fragment>
                                            </TimelineEvent>

                                        })
                                    })

                                }
                            </Timeline>

                        </InfiniteScroll> : "No Activities"
                    }
                </div>
            }
            />,

        ]
    }
}

export default connect(
    state => ({
        searchStore: state.searchStore,
        curriculums: state.curriculums,
        topics: state.topics,
        devices: state.devices
    }),
    dispatch => bindActionCreators({...curriculumActionCreators, ...topicActionCreators, ...deviceActionCreators}, dispatch)
)(CurriculumTimeline)
