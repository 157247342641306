import curriculumsApi from './api/curriculums'
import {handleError} from './api/errorHandler'

const GET_DEVICE_CURRICULUMS = "GET_DEVICE_CURRICULUMS";
const GET_CURRICULUM = "GET_CURRICULUM";
const GET_CURRICULUM_ACTIVITIES = "GET_CURRICULUM_ACTIVITIES";
const GET_MASTER_CURRICULUMS = "GET_MASTER_CURRICULUMS"
const GET_MASTER_CURRICULUM = "GET_MASTER_CURRICULUM"

const initialState = {
    data: {},
    master: {},
    curriculum: {},
    mastercurriculum: {},
};
export const actionCreators = {
    getDeviceCurriculums: (deviceId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await curriculumsApi.adminDeviceCurriculums(filter, deviceId)
            let data = response.data
            dispatch({
                type: GET_DEVICE_CURRICULUMS,
                payload: data
            });
        };

        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceCurriculum: (deviceId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await curriculumsApi.adminDeviceCurriculum(deviceId, id)
            let data = response.data
            dispatch({
                type: GET_CURRICULUM,
                payload: data.curriculum
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceFilterCurriculumActivities: (deviceId, id, filter) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await curriculumsApi.adminDeviceFilterCurriculumActivities(deviceId, id, filter)
            let data = response.data
            await dispatch({
                type: GET_CURRICULUM_ACTIVITIES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceCurriculumActivities: (deviceId, id, limit, filter) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await curriculumsApi.adminDeviceCurriculumActivities(deviceId, id, limit, filter)
            let data = response.data
            let activities = getState().curriculums.activities
            if(activities){
                activities.activities.slice(0).reverse().map(activity=> {
                    return data.activities.unshift(activity)
                })
            }
            await dispatch({
                type: GET_CURRICULUM_ACTIVITIES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterCurriculums: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await curriculumsApi.adminMasterCurriculums(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_CURRICULUMS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterCurriculum: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await curriculumsApi.adminMasterCurriculum(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_CURRICULUM,
                payload: data.curriculum
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterCurriculum: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await curriculumsApi.adminCreateMasterCurriculum(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterCurriculum: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await curriculumsApi.adminUpdateMasterCurriculum(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_DEVICE_CURRICULUMS) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_MASTER_CURRICULUMS) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_CURRICULUM) {
        return {...state, curriculum: action.payload, activities:null};
    }
    if (action.type === GET_CURRICULUM_ACTIVITIES) {
        return {...state, activities:action.payload};
    }
    if (action.type === GET_MASTER_CURRICULUM) {
        return {...state, mastercurriculum: action.payload};
    }


    return state;
};
