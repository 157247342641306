import React, {Component} from 'react'
import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Header,
    Button,
    Icon,
    Modal,
} from 'semantic-ui-react'

export default class AddCurriculumModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
            newItem: {
                active: false,
                order: props.order
            }
        }

    }

    componentWillReceiveProps(nextProps) {
        let newItem = this.state.newItem
        newItem.order = nextProps.order
        this.setState({newItem})
    }

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})

    handleChange = (e) => {
        let newItem = this.state.newItem
        newItem[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked : e.target.value
        this.setState({newItem})
    }

    handleSubmit = () => {
        this.props.submit(this.state.newItem, () => this.setState({modalOpen: false}))
    }

    render() {
        return (
            <Modal
                trigger={<Button
                    floated='right'
                    icon
                    labelPosition='left'
                    color='teal'
                    size='small'
                    onClick={this.handleOpen.bind(this)}
                >
                    <Icon name='plus'/> Add Curriculum
                </Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose.bind(this)}
                size='small'
                style={{height: "fit-content", margin: "auto"}}
            >
                <Header content={[<p key={1}>Add Curriculum</p>, (
                    this.props.name ?
                        <p key={2} style={{fontSize: 15, marginLeft: 20}}><Icon name={"level up alternate"}
                                                                                rotated="clockwise"/>{this.props.name}
                        </p> : null)]}/>
                <Modal.Content>
                    <Form className="editing">
                        <Form.Input fluid label='Name' placeholder='Name'
                                    name="name"
                                    onChange={this.handleChange.bind(this)}/>
                        <Form.Input fluid type='number'
                                    label='Order'
                                    placeholder='Order'
                                    name="order"
                                    defaultValue={this.state.newItem.order}
                                    onChange={this.handleChange.bind(this)}/>
                        <div className="ui toggle checkbox">
                            <input type="checkbox"
                                   name="active"
                                   checked={this.state.newItem.active}
                                   onChange={this.handleChange.bind(this)}/>
                            <label>Active</label>
                        </div>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>
                        Cancel
                    </Button>
                    <Button color='teal' onClick={this.handleSubmit.bind(this)}>
                        <Icon name='plus'/> Save
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}