import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as topicActionCreators} from '../../store/Topics'
import {actionCreators as contentActionCreators} from '../../store/Contents'
import {actionCreators as deviceActionCreators} from '../../store/Devices'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    Table,
    Pagination,
    Label,
    Grid,
    Image,
} from 'semantic-ui-react'
import FilterContent from '../common/ui/FilterContent'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import moment from 'moment'
import GridFormInput from '../common/ui/CompactTableGridFormInput'

class Topic extends Component {
    state = {
        filter: {},
        page: 1,
        loading: true,
        preview: false,
        previewui: true
    }

    componentWillMount() {
        this.props.getCurriculumTopic(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.id)
        this.props.getTopicContents(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.id)
        this.props.getDevice(this.props.match.params.deviceid)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: !this.props.topics.topic})
    }

    getTableData = props => {
        return props.contents.data.contents.filter(item => {
            return item.name.toLowerCase().includes(props.searchStore.text)
        }).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link
                        to={"/app/device/" + props.match.params.deviceid + "/curriculum/" + props.match.params.curriculumid + "/topic/" + props.match.params.id + "/content/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.complete}</Table.Cell>
                <Table.Cell>{item.current}</Table.Cell>
                <Table.Cell>{item.order}</Table.Cell>
                <Table.Cell>{item.required ?
                    <Label color='teal' horizontal>TRUE</Label> :
                    <Label color='red' horizontal>FALSE</Label>}
                </Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getTopicContents(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.id, this.state.filter, data.activePage)

    }

    getFilterContent(filter) {
        this.setState({filter})
        this.props.getTopicContents(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.id, filter, this.state.page)
    }

    getPreviewUI(topic) {
        return topic.congrats ? <Grid centered columns={2}>
            <Grid.Column key={topic.congrats.order}>{topic.congrats.title}
                <Segment style={{minWidth: 250}}> {
                    topic.congrats.responseArray ? this.renderResponseArray(topic.congrats.responseArray) : null
                }</Segment>
            </Grid.Column>
        </Grid> : null
    }

    renderResponseArray(responseArray) {
        let data = []
        for (let i = 0; i < responseArray.length; i++) {
            let response = responseArray[i]

            if (response.format === "h1")
                data.push(<h1 key={i}>{response.text}</h1>)
            if (response.format === "h2")
                data.push(<h2 key={i}>{response.text}</h2>)

            if (response.format === "p") {
                let paragraph = response.text.split("\\n\\n")
                let newData = []
                if (paragraph.length > 1) {
                    for (let j = 0; j < paragraph.length; j++) {
                        newData.push(' ' + paragraph[j])
                        newData.push(<br key={"inner" + i + '-' + j}/>)
                    }
                } else {
                    newData.push(' ' + response.text)
                }

                data.push(<span key={i}>{newData}
                </span>)
            }

            if (response.format === "b")
                data.push(<b key={i}>{response.text}</b>)

            if (response.format === "link") {
                data.push(<br key={"a_" + i}/>)
                data.push(<a href={response.src} key={i} src={response.src}>{response.text}</a>)
            }

            if (response.format === "icon")
                data.push(<Image key={i} src={response.text} size='tiny' avatar/>)

            if (response.format === "image")
                data.push(<Image key={i} src={response.src} size="small" centered/>)

            if (response.format === "gallery") {
                data.push(<br key={"gallery_" + i}/>)
                data.push(<Grid centered key={i}>
                    <Grid.Row centered columns={response.src.length}>
                        {response.src.map((src, index) => {
                            return <Grid.Column key={index}>
                                <Image centered
                                       src={src}/>
                            </Grid.Column>

                        })}</Grid.Row></Grid>)
            }
        }
        return data
    }

    getTimeText(time) {
        let timeMinutes = Math.floor(time / 60000)
        let remainderMinutes = time % 60000
        let timeSeconds = Math.floor(remainderMinutes / 1000)
        return timeMinutes + " minutes and " + timeSeconds + " seconds"
    }

    render() {
        let device = this.props.devices.device
        let topic = this.props.topics.topic
        let previewui = this.state.previewui
        let createdAt = topic.created_at ? topic.created_at.substr(0, 16) : ""
        let updatedAt = topic.updated_at ? topic.updated_at.substr(0, 16) : ""
        let profileTopic = null

        if (device.summary && device.summary.profile_curriculums) {
            let profileCurriculum = device.summary.profile_curriculums.find(profile_curriculum => profile_curriculum.id === this.props.match.params.curriculumid)
            profileTopic = profileCurriculum.topics.find(profileTopic => profileTopic.id === topic.id)
        }
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Devices",
                link: "/app/devices"
            }, {
                text: "Device",
                link: "/app/device/" + this.props.match.params.deviceid
            }, {
                text: "Curriculum",
                link: "/app/device/" + this.props.match.params.deviceid + "/curriculum/" + this.props.match.params.curriculumid
            }, {
                text: "Topic"
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Topic Information'} component={
                <div>
                    {this.state.loading || !topic ? <Loading/> :
                        <Form>
                            <Grid columns='equal' style={{marginTop: 0}}>
                                <Grid.Column>
                                    <GridFormInput
                                        label="ID"
                                        component={topic.id}
                                    />
                                    <GridFormInput
                                        label="Name"
                                        component={topic.name}
                                    />
                                    <GridFormInput
                                        label="Current"
                                        component={topic.current}
                                    />
                                    <GridFormInput
                                        label="Complete"
                                        component={topic.complete}
                                    />
                                    <GridFormInput
                                        label="Order"
                                        component={topic.order}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <GridFormInput
                                        label="Device ID"
                                        component={topic.deviceId}
                                    />
                                    <GridFormInput
                                        label="Curriculum ID"
                                        component={topic.profileCurriculumId}
                                    />
                                    <GridFormInput
                                        label="Created"
                                        component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                                    />
                                    <GridFormInput
                                        label="Updated"
                                        component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                                    />
                                </Grid.Column>
                            </Grid>

                        </Form>}
                </div>}/>
            , <AccordionSegment backgroundColor={'#f7f6a130'} key={2} header={'Results'} component={
                device.summary && device.summary.profile_curriculums ? this.state.loading || !profileTopic ? <Loading/> : [
                    <Grid columns='equal' style={{marginTop: 0}}>
                        <Grid.Column>
                            <GridFormInput
                                label="Average Time"
                                component={this.getTimeText(profileTopic.average_time)}
                            />
                            <GridFormInput
                                label="Total Time"
                                component={this.getTimeText(profileTopic.total_time)}
                            />
                            <GridFormInput
                                label="Count"
                                component={profileTopic.count}
                            />
                        </Grid.Column>
                    </Grid>,
                ]: <b>No Data Available</b>
            }/>,
            <AccordionSegment backgroundColor={'#f3937e30'} key={3} header={'Congrats'} component={
                this.state.loading || !topic ? <Loading/> :
                    <GridFormInput
                        label=""
                        component={previewui ? this.getPreviewUI(topic) : null}
                    />
            }/>,
            <AccordionSegment key={4} header={'Contents'} component={
                <div>
                    <FilterContent filter={this.getFilterContent.bind(this)}/>
                    {this.props.contents.data.contents ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Complete</Table.HeaderCell>
                                    <Table.HeaderCell>Current</Table.HeaderCell>
                                    <Table.HeaderCell>Order</Table.HeaderCell>
                                    <Table.HeaderCell>Required</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={7}>
                                        <Pagination defaultActivePage={this.props.contents.data.paging.page}
                                                    totalPages={this.props.contents.data.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> : <Loading/>
                    }
                </div>}/>

        ]
    }
}

export default connect(
    state => ({
        searchStore: state.searchStore,
        topics: state.topics,
        contents: state.contents,
        devices: state.devices
    }),
    dispatch => bindActionCreators({...topicActionCreators, ...contentActionCreators, ...deviceActionCreators}, dispatch)
)(Topic)
