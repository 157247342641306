import React from 'react'
import {Switch, Route} from 'react-router'
import UserManagement from './components/pages/UserManagement'
import Profile from './components/pages/Profile'
import InviteAdministrator from './components/pages/InviteAdministrator'
import Reports from './components/pages/Reports'
import Report from './components/pages/Report'
import Devices from './components/pages/Devices'
import Device from './components/pages/Device'
import Patients from './components/pages/Patients'
import FastPatient from './components/pages/FastPatient'
import AddPatient from './components/pages/AddPatient'
import Curriculum from './components/pages/Curriculum'
import CurriculumTimeline from './components/pages/CurriculumTimeline'
import Topic from './components/pages/Topic'
import Content from './components/pages/Content'
import Quiz from './components/pages/Quiz'
import MasterSites from './components/pages/MasterSites'
import MasterSite from './components/pages/MasterSite'
import MasterTreatments from './components/pages/MasterTreatments'
import MasterTreatment from './components/pages/MasterTreatment'
import MasterICSMedications from './components/pages/MasterICSMedications'
import MasterICSMedication from './components/pages/MasterICSMedication'
import MasterApps from './components/pages/MasterApps'
import MasterApp from './components/pages/MasterApp'
import MasterLanguage from './components/pages/MasterLanguage'
import MasterCurriculums from './components/pages/MasterCurriculums'
import MasterCurriculum from './components/pages/MasterCurriculum'
import MasterUsers from './components/pages/MasterUsers'
import MasterUser from './components/pages/MasterUser'

import CardForm from './components/pages/Card'
import Images from './components/pages/Images'

const AppRouter = () => (<Switch>
        <Route exact path="/app/reports" component={Reports}/>
        <Route exact path="/app/report/:id" component={Report}/>
        <Route exact path="/app/devices" component={Devices}/>
        <Route exact path="/app/device/:id" component={Device}/>
        <Route exact path="/app/patients" component={Patients}/>
        <Route exact path="/app/patient/:id" component={FastPatient}/>
        <Route exact path="/app/patient" component={AddPatient}/>
        <Route exact path="/app/device/:deviceid/curriculum/:id" component={Curriculum}/>
        <Route exact path="/app/device/:deviceid/curriculum/:id/timeline" component={CurriculumTimeline}/>
        <Route exact path="/app/device/:deviceid/curriculum/:curriculumid/topic/:id" component={Topic}/>
        <Route exact path="/app/device/:deviceid/curriculum/:curriculumid/topic/:topicid/content/:id"
               component={Content}/>
        <Route exact path="/app/device/:deviceid/curriculum/:curriculumid/topic/:topicid/content/:contentid/quiz/:id"
               component={Quiz}/>
        <Route exact path="/app/apps" component={MasterApps}/>
        <Route exact path="/app/app/:id" component={MasterApp}/>
        <Route exact path="/app/sites" component={MasterSites}/>
        <Route exact path="/app/site/:id" component={MasterSite}/>
        <Route exact path="/app/treatments" component={MasterTreatments}/>
        <Route exact path="/app/treatment/:id" component={MasterTreatment}/>
        <Route exact path="/app/icsmedications" component={MasterICSMedications}/>
        <Route exact path="/app/icsmedication/:id" component={MasterICSMedication}/>
        <Route exact path="/app/app/:appid/language/:id" component={MasterLanguage}/>
        <Route exact path="/app/curriculums" component={MasterCurriculums}/>
        <Route exact path="/app/curriculum/:id" component={MasterCurriculum}/>
        <Route exact path="/app/userManagement" component={UserManagement}/>
        <Route exact path="/app/card" component={CardForm}/>
        <Route exact path="/app/profile" component={Profile}/>
        <Route exact path="/app/invite" component={InviteAdministrator}/>
        <Route exact path="/app/images" component={Images}/>
        <Route exact path="/app/users" component={MasterUsers}/>
        <Route exact path="/app/user/:id" component={MasterUser}/>

    </Switch>)

export default AppRouter
