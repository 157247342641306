import {client} from './apiClient'

const adminDevices = async (filter) => {
    return await client.request({
        url: '/admin/devices',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminDevice = async (id) => {
    return await client.request({
        url: '/admin/device/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminDeviceLocation = async (id) => {
    return await client.request({
        url: '/admin/device/' + id + '/location',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminDevices,
    adminDevice,
    adminDeviceLocation
}