import reportsApi from './api/reports'
import {handleError} from './api/errorHandler'

const GET_ALL_REPORTS = "GET_ALL_REPORTS";
const GET_REPORT = "GET_REPORT";
const GET_REPORT_LOCATION = "GET_REPORT_LOCATION";

const initialState = {
    data: [],
    report: {},
    location: {}
};

export const actionCreators = {
    getReports: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await reportsApi.adminReports(filter)
            let data = response.data

            dispatch({
                type: GET_ALL_REPORTS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getReport: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await reportsApi.adminReport(id)
            let data = response.data
            if(data.report.data.fulldata_json_url ) {
                let reportData = await reportsApi.reportData(data.report.data.fulldata_json_url)
                data.report.data = reportData.data
            }
            dispatch({
                type: GET_REPORT,
                payload: data.report
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getReportLocation: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await reportsApi.adminReportLocation(id)
            let data = response.data
            dispatch({
                type: GET_REPORT_LOCATION,
                payload: data.location
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_ALL_REPORTS) {
        console.log(action.payload)
        return {...state, data: action.payload};
    }

    if (action.type === GET_REPORT) {
        return {...state, report: action.payload};
    }

    return state;
};
