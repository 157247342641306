import React, {Component} from 'react'
import {Menu} from 'semantic-ui-react'
import {Link} from "react-router-dom";
import TextIcon from "../extension/TextIcon";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actionCreators} from "../../store/SideMenu";

class SideMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: props.history.location.pathname,
        };
    }

    handleItemClick = (e, {name}) => this.setState({activeItem: name});

    getMenu() {
        let isClinician = this.props.user.roles ? this.props.user.roles.find(role => role.name === 'clinician') : false
        let isAdmin = this.props.user.roles ? this.props.user.roles.find(role => role.name === 'admin') : false

        const {activeItem} = this.state;
        return (
            <Menu fixed='left' borderless className={(this.props.sideMenu.smallMenu ? 'small-side' : '') + ' side'} vertical>
                <Menu.Item as={Link} to={'/app/patients'} name='/app/patients' active={activeItem === '/app/patients'}
                           onClick={this.handleItemClick}>
                    <TextIcon hideText={this.props.sideMenu.smallMenu} name='user'>
                        Patients
                    </TextIcon>
                </Menu.Item>
                {!isClinician ? <Menu.Item>
                    <Menu.Header>
                        <TextIcon hideText={this.props.smallMenu||false} name='settings'>
                            Setup
                        </TextIcon></Menu.Header>
                    <Menu.Menu style={{paddingLeft: 5, paddingTop: 10}}>
                        <Menu.Item
                            as={Link} to={'/app/sites'} name='/app/sites'
                            active={activeItem === '/app/sites'}
                            onClick={this.handleItemClick}>
                            <TextIcon hideText={this.props.sideMenu.smallMenu||false} name='cog'>
                                Sites
                            </TextIcon>
                        </Menu.Item>
                        <Menu.Item
                            as={Link} to={'/app/treatments'} name='/app/treatments'
                            active={activeItem === '/app/treatments'}
                            onClick={this.handleItemClick}>
                            <TextIcon hideText={this.props.sideMenu.smallMenu||false} name='cog'>
                                Treatments
                            </TextIcon>
                        </Menu.Item>
                        <Menu.Item
                            as={Link} to={'/app/icsmedications'} name='/app/icsmedications'
                            active={activeItem === '/app/icsmedications'}
                            onClick={this.handleItemClick}>
                            <TextIcon hideText={this.props.sideMenu.smallMenu||false} name='cog'>
                                ICS Medications
                            </TextIcon>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item> : null}
                {isAdmin ? <Menu.Item as={Link} to={'/app/users'} name='/app/users' active={activeItem === '/app/users'}
                           onClick={this.handleItemClick}>
                    <TextIcon hideText={this.props.sideMenu.smallMenu||false} name='users'>
                        Admins
                    </TextIcon>
                </Menu.Item> : null}
            </Menu>
        )
    }

    render() {
        return (
            <div className='parent'>
                <div className={(this.props.sideMenu.smallMenu ? 'small-side ' : '') + 'side'}>
                    {this.getMenu()}
                </div>
                <div className={(this.props.sideMenu.smallMenu ? 'small-content ' : '') + 'normal-content'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({user: state.login.user, sideMenu: state.sideMenu}),

    dispatch => bindActionCreators(actionCreators, dispatch)
)(SideMenu);
