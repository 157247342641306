import quizzesApi from './api/quizzes'
import {handleError} from './api/errorHandler'

const GET_CONTENT_QUIZZES = "GET_CONTENT_QUIZZES";
const GET_QUIZ = "GET_QUIZ";
const GET_MASTER_QUIZZES = "GET_MASTER_QUIZZES";
const GET_MASTER_QUIZ = "GET_MASTER_QUIZ";

const initialState = {
    data: {},
    quiz: {},
    master: {},
    masterquiz:{}
};

export const actionCreators = {
    getContentQuizzes: (deviceId, curriculumId, topicId, contentId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await quizzesApi.adminContentQuizzes(filter, deviceId, curriculumId, topicId, contentId)
            let data = response.data
            dispatch({
                type: GET_CONTENT_QUIZZES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getContentQuiz: (deviceId, curriculumId, topicId, contentId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await quizzesApi.adminContentQuiz(deviceId, curriculumId, topicId, contentId, id)
            let data = response.data
            dispatch({
                type: GET_QUIZ,
                payload: data.quiz
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },

    getMasterQuizzes: (curriculumId, topicId, contentId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await quizzesApi.adminMasterQuizzes(filter, curriculumId, topicId, contentId)
            let data = response.data
            dispatch({
                type: GET_MASTER_QUIZZES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterQuiz: (curriculumId, topicId, contentId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await quizzesApi.adminMasterQuiz(curriculumId, topicId, contentId, id)
            let data = response.data
            dispatch({
                type: GET_MASTER_QUIZ,
                payload: data.quiz
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterQuiz:(curriculumId, topicId, contentId, newItem) => async (dispatch, getState) => {
        let callback = async function () {
            await quizzesApi.adminCreateMasterQuiz(curriculumId, topicId, contentId, newItem)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterQuiz: (curriculumId, topicId, contentId, id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await quizzesApi.adminUpdateMasterQuiz(curriculumId, topicId, contentId, id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_CONTENT_QUIZZES) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_QUIZ) {
        return {...state, quiz: action.payload};
    }

    if (action.type === GET_MASTER_QUIZZES) {
        return {...state, master: action.payload};
    }
    if (action.type === GET_MASTER_QUIZ) {
        return {...state, masterquiz: action.payload};
    }


    return state;
};
