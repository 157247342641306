import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as userActionCreators} from '../../store/Users'

import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Button,
    Label,
} from 'semantic-ui-react'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'
import {actionCreators as sitesActionCreators} from "../../store/Sites";
import {actionCreators as treatmentsActionCreators} from "../../store/Treatments";
import {actionCreators as loginActionCreators} from "../../store/Login";

class MasterUser extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTopic: {},
        user: {},
        edit: false,
        loading: true,
        saving: -1
    }

    async componentWillMount() {
        await this.props.getMasterTreatments()
        await this.props.getMasterSites()
        await this.props.getMasterUser(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({user: nextProps.users.masteruser, loading: !this.state.user})
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    async handleSave() {
        this.setState({saving: 1})
        let user = {
            firstName: this.state.user.firstName,
            lastName: this.state.user.lastName,
            treatmentId: this.state.user.treatmentId,
            siteId: this.state.user.siteId,
            status: this.state.user.status,
        }
        let errors = await this.props.updateMasterUser(this.props.match.params.id, user)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterUser(this.props.match.params.id)
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    async handleResetPassword() {
        this.setState({saving: 1})
        let response = await this.props.sendPasswordReset(this.state.user)
        if (response.data.errors !== undefined) {
            this.setState({saving: 2, errors: response.data, message: null})
        } else {
            await this.props.getMasterUser(this.props.match.params.id)
            this.setState({saving: 0, errors: null, message: response.data.message})
            this.toggleMessage()
        }
    }

    handleCancel = () => {
        this.props.getMasterUser(this.props.match.params.id)
        this.setState({edit: false})
    }

    handleChange = (e, {name, value, checked}) => {
        if (this.state.edit) {
            let user = this.state.user
            user[name] = name === "active" || name === "required" ? checked ? 1 : 0 : value
            this.setState({user})
        }
    }

    render() {
        let user = this.state.user
        let readOnly = !this.state.edit
        let createdAt = user.created_at ? user.created_at.substr(0, 16) : ""
        let updatedAt = user.updated_at ? user.updated_at.substr(0, 16) : ""

        let siteSelected = this.props.sites.master.sites ? this.props.sites.master.sites.find(item => user.siteId === item.id) : {}
        let treatmentSelected = this.props.treatments.master.treatments ? this.props.treatments.master.treatments.find(item => user.treatmentId === item.id) : {}

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Users",
                link: "/app/users"
            }, {
                text: "User",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'User'} component={
                <div>
                    <AppMessage saving={this.state.saving} errors={this.state.errors}
                                message={this.state.message}/>
                    {this.state.loading ? <Loading/> :
                        <Form className={readOnly ? "readOnly" : "editing"}>
                            <GridFormInput
                                width={160}
                                label="Email"
                                component={user.email}
                            />
                            <GridFormInput
                                width={160}
                                label="First Name"
                                component={readOnly ? user.firstName :
                                    <Form.Input
                                        fluid
                                        placeholder='First Name'
                                        readOnly={readOnly}
                                        value={user.firstName}
                                        name="firstName"
                                        onChange={this.handleChange.bind(this)}/>
                                }
                            />
                            <GridFormInput
                                width={160}
                                label="Last Name"
                                component={readOnly ? user.lastName :
                                    <Form.Input
                                        fluid
                                        placeholder='Last Name'
                                        readOnly={readOnly}
                                        value={user.lastName}
                                        name="lastName"
                                        onChange={this.handleChange.bind(this)}/>
                                }
                            />

                            <GridFormInput
                                width={160}
                                label="Site ID"
                                component={readOnly ? (siteSelected ? siteSelected.name : "") :
                                    <Form.Select
                                        fluid
                                        placeholder='Site ID'
                                        readOnly={readOnly}
                                        value={user.siteId}
                                        options={this.props.sites.master.sites.map((site, index) => {
                                            return {key: index, text: site.name, value: site.id}
                                        })}
                                        name="siteId"
                                        onChange={this.handleChange.bind(this)}/>
                                }
                            />
                            <GridFormInput
                                width={160}
                                label="Treatment ID"
                                component={readOnly ? (treatmentSelected ? treatmentSelected.name : "") :
                                    <Form.Select
                                        fluid
                                        placeholder='Treatment ID'
                                        readOnly={readOnly}
                                        value={user.treatmentId}
                                        options={this.props.treatments.master.treatments.map((site, index) => {
                                            return {key: index, text: site.name, value: site.id}
                                        })}
                                        name="treatmentId"
                                        onChange={this.handleChange.bind(this)}/>
                                }
                            />
                            <GridFormInput
                                width={160}
                                label="Status"
                                component={readOnly ? (user.status === 'Active' ?
                                    <Label color='teal' horizontal>ACTIVE</Label> :
                                    <Label color='red'
                                           horizontal>{user.status ? user.status.toUpperCase() : ""}</Label>):
                                    <Form.Select
                                        fluid
                                        placeholder='Status'
                                        readOnly={readOnly}
                                        value={user.status}
                                        options={[
                                            {key: 0, text: "Active", value: "Active"},
                                            {key: 1, text: "Suspend", value: 'Suspend'},
                                            {key: 2, text: "Locked", value: 'Locked'}
                                        ]}
                                        name="status"
                                        onChange={this.handleChange.bind(this)}/>}
                            />

                            <GridFormInput
                                width={160}
                                label="Failed Logon Attempts"
                                component={user.logonAttempts}
                            />
                            <GridFormInput
                                width={160}
                                label="Role"
                                component={user.roles ? user.roles[0].name : ""}
                            />
                            <GridFormInput
                                width={160}
                                label="Created"
                                component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                width={160}
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />
                            {readOnly ? [<Button key={1} color='teal' style={{float: "right"}}
                                                 onClick={this.handleResetPassword.bind(this)}>Reset Password</Button>,
                                    <Button key={2} color='teal' style={{float: "right"}}
                                            onClick={this.handleEdit.bind(this)}>Edit</Button>] :
                                [<Button key={0} color='teal' style={{float: "right"}}
                                         onClick={this.handleSave.bind(this)}>Save</Button>,
                                    <Button key={1} style={{float: "right"}}
                                            onClick={this.handleCancel.bind(this)}>Cancel</Button>]
                            }
                            <br/>
                            <br/>
                        </Form>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({
        searchStore: state.searchStore,
        users: state.users,
        sites: state.sites,
        treatments: state.treatments
    }),
    dispatch => bindActionCreators({...loginActionCreators, ...userActionCreators, ...sitesActionCreators, ...treatmentsActionCreators}, dispatch)
)(MasterUser)
