import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as quizzesActionCreators} from '../../store/Quizzes'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    Table,
    Label,
    Grid,
    Image,
} from 'semantic-ui-react'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import moment from 'moment'
import GridFormInput from '../common/ui/CompactTableGridFormInput'

class Content extends Component {
    state = {
        filter: {},
        page: 1,
        loading: true,
        preview: {review: false, question: false},
        previewui: {review: true, question: true}
    }

    componentWillMount() {
        this.props.getContentQuiz(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.topicid, this.props.match.params.contentid, this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: !this.props.quizzes.quiz})
    }

    getTableData = props => {
        return props.quizzes.data.quizs.filter(item => {
            return item.name.toLowerCase().includes(props.searchStore.text)
        }).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link
                        to={"/app/device/" + props.match.params.deviceid + "/curriculum/" + props.match.params.curriculumid + "/topic/" + props.match.params.topicid + "/content/" + props.match.params.contentid + "/quiz" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.complete}</Table.Cell>
                <Table.Cell>{item.current}</Table.Cell>
                <Table.Cell>{item.order}</Table.Cell>
                <Table.Cell>{item.required ?
                    <Label color='teal' horizontal>TRUE</Label> :
                    <Label color='red' horizontal>FALSE</Label>}
                </Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getContentQuizzes(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.topicid, this.props.match.params.id, this.state.filter, data.activePage)
    }

    getFilterQuiz(filter) {
        this.setState({filter})
        this.props.getContentQuizzes(this.props.match.params.deviceid, this.props.match.params.curriculumid, this.props.match.params.topicid, this.props.match.params.id, filter, this.state.page)
    }


    handlePreview = (option) => {
        let preview = this.state.preview
        preview[option] = !preview[option]

        this.setState({preview})
    }

    handlePreviewUI = (option) => {
        let previewui = this.state.previewui
        previewui[option] = !previewui[option]

        let preview = this.state.preview
        preview[option] = false

        this.setState({previewui, preview})
    }

    getPreviewUI(quiz, option) {
        let screen = quiz.data[option]
        return <Grid centered columns={2}> <Grid.Column key={screen.order}>{screen.title}
            <Segment style={{minWidth: 250}}>
                {
                    screen.responseArray ? this.renderResponseArray(screen.responseArray) : null
                }
                {
                    screen.answers ? this.renderAnswers(screen, screen.answers) : null
                }
            </Segment>
        </Grid.Column>
        </Grid>


    }

    renderAnswers(screen, answers) {
        if (screen.question_type === 'radio') {
            if (screen.answer_type === 'single') {
                return <Form.Group grouped>
                    <label>Choices</label>
                    {
                        answers.map((answer, index) => {
                            return <Form.Radio
                                key={index}
                                label={answer.answer_icon_text + ". " + answer.answer}
                                value={answer.answer_icon_text}
                                checked={answer.selected}
                            />
                        })
                    }
                </Form.Group>
            } else {
                return <Form.Group grouped>
                    <label>Choices</label>
                    {
                        answers.map((answer, index) => {
                            return <Form.Checkbox
                                key={index}
                                label={answer.answer}
                                value={answer.answer_icon_text}
                                checked={answer.selected}
                            />

                        })
                    }
                </Form.Group>
            }
        } else {
            return <Form.Input
                fluid
                placeholder='Name'
                name="name"
                onChange={this.handleChange.bind(this)}/>
        }
    }

    renderResponseArray(responseArray) {
        let data = []
        for (let i = 0; i < responseArray.length; i++) {
            let response = responseArray[i]

            if (response.format === "h1")
                data.push(<h1 key={i}>{response.text}</h1>)
            if (response.format === "h2")
                data.push(<h2 key={i}>{response.text}</h2>)

            if (response.format === "p") {
                let paragraph = response.text.split("\\n\\n")
                let newData = []
                if (paragraph.length > 1) {
                    for (let j = 0; j < paragraph.length; j++) {
                        newData.push(' ' + paragraph[j])
                        newData.push(<br key={"inner" + i + '-' + j}/>)
                    }
                } else {
                    newData.push(' ' + response.text)
                }

                data.push(<span key={i}>{newData}
                </span>)
            }

            if (response.format === "b")
                data.push(<b key={i}>{response.text}</b>)

            if (response.format === "link") {
                data.push(<br key={"a_" + i}/>)
                data.push(<a href={response.src} key={i} src={response.src}>{response.text}</a>)
            }

            if (response.format === "icon")
                data.push(<Image key={i} src={response.text} size='tiny' avatar/>)

            if (response.format === "image")
                data.push(<Image key={i} src={response.src} size="small" centered/>)

            if (response.format === "gallery") {
                data.push(<br key={"gallery_" + i}/>)
                data.push(<Grid centered key={i}>
                    <Grid.Row centered columns={response.src.length}>
                        {response.src.map((src, index) => {
                            return <Grid.Column key={index}>
                                <Image centered
                                       src={src}/>
                            </Grid.Column>

                        })}</Grid.Row></Grid>)
            }
        }
        return data
    }

    getTimeText(time) {
        let timeMinutes = Math.floor(time / 60000)
        let remainderMinutes = time % 60000
        let timeSeconds = Math.floor(remainderMinutes / 1000)
        return timeMinutes + " minutes and " + timeSeconds + " seconds"
    }

    render() {
        let quiz = this.props.quizzes.quiz
        let previewui = this.state.previewui
        let createdAt = quiz.created_at ? quiz.created_at.substr(0, 16) : ""
        let updatedAt = quiz.updated_at ? quiz.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Devices",
                link: "/app/devices"
            }, {
                text: "Device",
                link: "/app/device/" + this.props.match.params.deviceid
            }, {
                text: "Curriculum",
                link: "/app/device/" + this.props.match.params.deviceid + "/curriculum/" + this.props.match.params.curriculumid
            }, {
                text: "Topic",
                link: "/app/device/" + this.props.match.params.deviceid + "/curriculum/" + this.props.match.params.curriculumid + "/topic/" + this.props.match.params.topicid
            }, {
                text: "Content",
                link: "/app/device/" + this.props.match.params.deviceid + "/curriculum/" + this.props.match.params.curriculumid + "/topic/" + this.props.match.params.topicid + "/content/" + this.props.match.params.contentid
            }, {
                text: "Quiz",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Quiz Information'} component={
                <div>
                    {this.state.loading ? <Loading/> :
                        <Form>
                            <Grid columns='equal' style={{marginTop: 0}}>
                                <Grid.Column>
                                    <GridFormInput
                                        label="Name"
                                        component={quiz.name}
                                    />
                                    <GridFormInput
                                        label="Current"
                                        component={quiz.current}
                                    />
                                    <GridFormInput
                                        label="Complete"
                                        component={quiz.complete}
                                    />
                                    <GridFormInput
                                        label="Order"
                                        component={quiz.order}
                                    />
                                    <GridFormInput
                                        label="Created"
                                        component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                                    />
                                    <GridFormInput
                                        label="Updated"
                                        component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <GridFormInput
                                        label="ID"
                                        component={quiz.id}
                                    />
                                    <GridFormInput
                                        label="Device ID"
                                        component={quiz.deviceId}
                                    />
                                    <GridFormInput
                                        label="Curriculum ID"
                                        component={quiz.profileCurriculumId}
                                    />
                                    <GridFormInput
                                        label="Topic ID"
                                        component={quiz.profileTopicId}
                                    />
                                    <GridFormInput
                                        label="Content ID"
                                        component={quiz.profileContentId}
                                    />
                                </Grid.Column>
                            </Grid>

                        </Form>}
                </div>
            }/>,

            <AccordionSegment backgroundColor={'#f7f6a130'} key={2} header={'Results'} component={
                this.state.loading ? <Loading/> :
                    <Grid columns='equal' style={{marginTop: 0}}>
                        <Grid.Column>
                            <GridFormInput
                                label="Count"
                                component={quiz.data.results.count}
                            />
                            <GridFormInput
                                label="First Answer"
                                component={quiz.data.results.first_answer ? 'true' : 'false'}
                            />
                            <GridFormInput
                                label="Right Answers"
                                component={quiz.data.results.overall_answers.right}
                            />
                            <GridFormInput
                                label="Wrong Answers"
                                component={quiz.data.results.overall_answers.wrong}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <GridFormInput
                                label="Average Time"
                                component={this.getTimeText(quiz.data.results.average_time)}
                            />
                            <GridFormInput
                                label="Total Time"
                                component={this.getTimeText(quiz.data.results.total_time)}
                            />
                            <GridFormInput
                                label="First Time"
                                component={this.getTimeText(quiz.data.results.first_time)}
                            />
                        </Grid.Column>
                    </Grid>
            }/>,
            <AccordionSegment backgroundColor={'#f3937e30'} key={3} header={'Question'} component={
                this.state.loading ? <Loading/> :
                    <GridFormInput
                        label=""
                        component={quiz.data ?
                            previewui['question'] ? this.getPreviewUI(quiz, 'question') : null
                            : null}
                    />
            }/>,
            <AccordionSegment backgroundColor={'#fd9fbe30'} key={4} header={'Review'} component={
                this.state.loading ? <Loading/> :
                    <GridFormInput
                        label=""
                        component={quiz.data ?
                            previewui['review'] ? this.getPreviewUI(quiz, 'review') : null
                            : null}
                    />
            }/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, quizzes: state.quizzes}),
    dispatch => bindActionCreators({...quizzesActionCreators}, dispatch)
)(Content)
