import usersApi from './api/users'
import {handleError} from './api/errorHandler'

const GET_DEVICE_USERS = "GET_DEVICE_USERS";
const GET_USER = "GET_USER";
const GET_USER_ACTIVITIES = "GET_USER_ACTIVITIES";
const GET_MASTER_USERS = "GET_MASTER_USERS"
const GET_MASTER_USER = "GET_MASTER_USER"

const initialState = {
    data: {},
    master: {},
    user: {},
    masteruser: {},
};
export const actionCreators = {
    getMasterUsers: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await usersApi.adminMasterUsers(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_USERS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getProviderUser: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            console.log(filter)
            let response = await usersApi.adminMasterUsers(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_USERS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterUser: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await usersApi.adminMasterUser(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_USER,
                payload: data.user
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterUser: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await usersApi.adminCreateMasterUser(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterUser: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await usersApi.adminUpdateMasterUser(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_DEVICE_USERS) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_MASTER_USERS) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_USER) {
        return {...state, user: action.payload, activities:null};
    }
    if (action.type === GET_USER_ACTIVITIES) {
        return {...state, activities:action.payload};
    }
    if (action.type === GET_MASTER_USER) {
        return {...state, masteruser: action.payload};
    }


    return state;
};
