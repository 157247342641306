import React, {Component} from "react";
import {
    Accordion,
    Form,
    Header,
    Segment,
    Grid,
    Input,
    Button
} from "semantic-ui-react";
import GridFormInput from './TableGridFormInput'

class FilterForm extends Component {
    state = {filter: {}}

    handleChange(e, value) {
        let filter = this.state.filter
        filter[value.name] = value.value
        this.setState({filter})
    }

    handleClick() {
        this.props.filter(this.state.filter)
    }

    handleClear() {
        this.props.filter({})
        this.setState({filter: {}})
    }

    render() {
        let filter = this.state.filter
        return (<Form>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as='h5' attached='top'>
                            Active
                        </Header>
                        <Segment attached>
                            <Form.Group grouped>
                                <Form.Radio label='True' name='active' type='radio' value='1'
                                            checked={filter.active === '1'}
                                            onChange={this.handleChange.bind(this)}/>
                                <Form.Radio label='False' name='active' type='radio' value='0'
                                            checked={filter.active === '0'}
                                            onChange={this.handleChange.bind(this)}/>
                            </Form.Group></Segment>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Header as='h5' attached='top'>
                            Required
                        </Header>
                        <Segment attached>
                            <Form.Group grouped>
                                <Form.Radio label='True' name='required' type='radio' value='1'
                                            checked={filter.required === '1'}
                                            onChange={this.handleChange.bind(this)}/>
                                <Form.Radio label='False' name='required' type='radio' value='0'
                                            checked={filter.required === '0'}
                                            onChange={this.handleChange.bind(this)}/>
                            </Form.Group></Segment>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <GridFormInput
                            label="Name"
                            component={<Input style={{width: 200}}
                                              value={filter.name}
                                              name='name'
                                              placeholder='Name'
                                              onChange={this.handleChange.bind(this)}/>}/>
                        <GridFormInput
                            label="Order"
                            component={<Input style={{width: 200}}
                                              value={filter.order}
                                              name='order'
                                              placeholder='Order'
                                              onChange={this.handleChange.bind(this)}/>}/>
                        <GridFormInput
                            label="Created At"
                            component={<Input style={{width: 200}}
                                              value={filter.created_at}
                                              name='created_at'
                                              type='date'
                                              placeholder='Created At'
                                              onChange={this.handleChange.bind(this)}/>}/>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <div style={{
                            position: "absolute",
                            right: 10,
                            bottom: 0,
                        }}>
                            <Button onClick={this.handleClear.bind(this)}>Clear</Button>
                            &nbsp;
                            <Button color='teal'
                                    onClick={this.handleClick.bind(this)}>Filter</Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid>

            </Grid>

        </Form>)
    }
}

export class Filter extends Component {
    state = {activeIndex: -1}

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state

        return (
            <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    content='Filter'
                    index={0}
                    onClick={this.handleClick}
                />
                <Accordion.Content active={activeIndex === 0}>
                    <FilterForm filter={this.props.filter}/>
                </Accordion.Content>
            </Accordion>
        )
    }
}

export default Filter