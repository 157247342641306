import {client} from './apiClient'

const adminTopicContents = async (filter, deviceId, curriculumId, topicId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + curriculumId + "/topic/" + topicId + "/contents",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminTopicContent = async (deviceId, curriculumId, topicId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + curriculumId + "/topic/" + topicId + "/content/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminMasterContents = async (filter, curriculumId, topicId) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/contents",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterContent = async (curriculumId, topicId, id) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterContent = async (curriculumId, topicId, data) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content",
        method: "POST",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterContent = async (curriculumId, topicId, id, data) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content/" + id,
        method: "PUT",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminTopicContents,
    adminTopicContent,
    adminMasterContents,
    adminMasterContent,
    adminCreateMasterContent,
    adminUpdateMasterContent
}