import React from 'react';

const GridFormInput = props => (
    <table style={{marginTop: 0, height: 38, width: '100%'}}>
        <tbody>
        <tr>
            {props.label || props.label === null ? <td style={{
                verticalAlign: "middle",
                paddingRight: 0, paddingTop: 5, paddingBottom: 5, width: props.width || 140
            }}>
                <label style={{
                    float: 'right',
                    paddingRight: 10,
                    marginBottom: 0
                }}>{props.label ? props.label + ":" : ""}</label>
            </td> : null}
            <td style={{paddingRight: 0, paddingTop: 1, paddingBottom: 1}} className={props.fieldclassName}>
                {props.component}
            </td>
        </tr>
        </tbody>
    </table>
);
export default GridFormInput;
