import React from 'react';

const PatientActiveScriptScreen4 = props => (
    <>
        (shared decision-making)

        <h3>CRITICAL COMPONENTS </h3>

        <ol>
            <li>Negotiate a plan on how to increase ICS use or improve asthma self-management</li>
            <li>Direct advice</li>
            <li>Agreement handout</li>
        </ol>

        <h3 >OBJECTIVES</h3>
        <ul>
            <li>Negotiate goal</li>
            <li>Give advice</li>
            <li>Summarize</li>

        </ul>

        <h3 >ACTIONS</h3>
        <ul>
            <li>Assist patient to identify a goal from a menu of options
                <li>Avoid being argumentative</li>
                <li>Deliver sound medical advice/education</li>
                <li>Encourage harm reduction</li>
            </li>
        </ul>
        <table style={{fontWeight: "bold", backgroundColor: "white", border: "black solid", margin: 0}}>
            <tr>
                <td style={{border: "black solid"}}>
                    <h3 style={{margin: 0, textAlign: "center"}}>SCRIPT</h3>
                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}>
                    <font  style={{fontSize: "1.28571429rem"}}>"What might be your next step, if any?"</font>

                    <br/><br/>

                    (If a positive next step, reflect it; If not, ask permission to suggest one and then make the
                    suggestion.)
                    <br/><br/>
                </td>
            </tr>

            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}>
                    Here is an example of a positive suggestion: <font  style={{fontSize: "1.28571429rem"}}>"If you can take one-half to three-quarters of your {props.patient.ics} dose you will be less likely to have a
                        serious asthma attack."</font>
                    <br/><br/>
                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}>
                    <font  style={{fontSize: "1.28571429rem"}}>"Overall, this is what I've heard you say, you have agreed to ...</font> (state actual amounts of ICS use)
                    <font  style={{fontSize: "1.28571429rem"}}> because you want it will lead to ... </font>(summarize their most motivational reasons for change) ...
                    <font  style={{fontSize: "1.28571429rem"}}> We also
                    suggest that you remind yourself everyday of these reasons and even add new ones to the list. This is an
                    agreement between you and yourself because we know that only you can decide it is important enough to you to make
                        these changes."</font>
                    <br/><br/>
                    Suggest f/u to discuss asthma control, asthma self-management, and ICS use
                    <br/><br/>
                    Thank patient for his/her time
                </td>
            </tr>
        </table>

        <h3 >SUMMARY</h3>
        <p>The PCP should assist the patient in exploring a menu of options. However, the patient is the decision-maker
            and
            should ultimately be responsible for choosing a plan</p>
    </>
);

export default PatientActiveScriptScreen4;
