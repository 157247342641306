import React from 'react';
import ViewReadinessModal from "../components/common/modal/ViewReadinessModal";

const PatientActiveScriptScreen3 = props => (
    <>
        <h3>CRITICAL COMPONENTS </h3>

        <ol>
            <li>Assess readiness to change</li>
            <li>Develop discrepancy</li>
            <li>Reflective listening</li>
            <li>Open-ended questions</li>

        </ol>

        <h3>PREPARATION</h3>
        <ul>
            <li>"Readiness to Change Ruler"</li>
        </ul>

        <table style={{fontWeight: "bold", backgroundColor: "white", border: "black solid", margin: 0}}>
            <tbody>
            <tr>
                <td style={{border: "black solid"}}>
                    <h3 style={{margin: 0, textAlign: "center"}}>SCRIPT</h3>
                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}>
                    <font  style={{fontSize: "1.28571429rem"}}>"So, based on what we just discussed, how ready might you be to change any aspect of your {props.patient.ics} use, on
                        a scale from 1-10, where 1 means not at all ready and 10 means totally ready?"</font>
                    <br/><br/>
                    <ViewReadinessModal handleUpdateReadiness={props.handleUpdateReadiness} patient={props.patient}/>

                    <br/><br/>
                    Ask pt to tell you what change they had in mind.

                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}>
                    If patient says:
                    <br/><br/>
                    &gt;2, ask <font  style={{fontSize: "1.28571429rem"}}>"Why did you choose that number and not a lower one?"</font> (Be ready to explain why we're asking this)
                    <br/><br/>
                    &lt;1 or unwilling, ask- <font  style={{fontSize: "1.28571429rem"}}>What would it take for you to become a "2"? What would make this a problem for you? How important would it be for you to prevent that from happening?... have you ever not been able to do something you wanted to because of your asthma?</font>
                    <br/><br/>
                    Note. Ask for at least several reasons that are as personal and motivating as possible. To
                    strengthen their reasons, ask them why those reasons are important to them.
                    <br/><br/>

                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}>
                    Reflect/reiterate the patient's reasons for making a change, one at a time and in a summary reflection,
                    highlighting what seem like the most motivating reasons.
                </td>
            </tr>
            </tbody>
        </table>

        <h3>OBJECTIVES</h3>
        <ul>
            <li>Assess readiness to change</li>
            <li>Develop discrepancy</li>
            <li>Use reflective listening</li>
        </ul>

        <h3>ACTIONS</h3>
        <ul>
            <li>Have patient self-identity readiness to change, on a scale of 1-10</li>
            <li>Identify area to discuss</li>
        </ul>

        <h3>SUMMARY</h3>
        <p>Patients are often ambivalent about change. Developing discrepancies between the patient's present behavior and their own expressed concerns may tip the scales towards readiness to change. Reflective listening is a way to check what the patient meant by a statement. Intonation should turn down at the end of the remark to encourage patient response.</p>
    </>
);

export default PatientActiveScriptScreen3;
