const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
<p>
  <strong>
    <mark>Note to clinician: This second section should take 3 minutes of conversation. </mark><mark style="color:#db2828">Pick some of the following to discuss.</mark>
  </strong>
</p>

<p>
  <strong><u>2. Counsel patient: </u></strong>
  <strong>
    Describe benefits of diet, exercise, weight loss/gain and healthy
    eating.
  </strong>
</p>
<p><mark style="background-color: lightblue">2E. Exercising to stay healthy</mark></p>

<p>
  <strong>Work backwards from the patient’s goal using the minimum information necessary to cover the most essential learning topics that will enable and motivate them to exercise in a healthier way.</strong>
</p>
<p>
  <strong>There are many different types of exercises to achieve your goal of staying healthy. Exercise is enjoyable when you find the activities you like to do and who you like to do them with. Try different types of exercise to figure out what you like best. Exercise can include walking, biking, dancing, yoga, following a fitness instruction video on YouTube or a group exercise class.</strong>
</p>
<p>
  The more you exercise, the more health benefits you gain. Being active can help you live longer, maintain muscle, prevent joint pain as you age, strengthen your heart and lungs, improve brain function and help you sleep better at night. Exercising can also improve your mood, lower blood pressure and lower blood sugar.
</p>
<p>
  To stay healthy, you should be exercising 150 minutes a week or at least 30 minutes at a time. You can spread your activity out during the week and break it up based on your schedule.
</p>
<p>
  There are three components to physical activity- intensity, frequency and duration. Intensity is how hard you have to work to do the activity. Frequency is how often you are doing the activity. Duration is how long you do the activity for. Research has shown that the total amount of exercise in a week is more beneficial to your health than any one component (frequency, intensity, or duration).Muscle-strengthening is an important part of exercise. At least two days a week should be dedicated to muscle-strengthening activities instead of aerobic activities (like walking). These activities should work all the major muscle groups of your body – legs, hips, back, chest, abdomen, shoulders and arms. Activities that strengthen these muscle groups include lifting weights, squats, push-ups, sit-ups and some forms of yoga. Research shows you must work these muscles to fatigue - meaning you cannot lift another repetition - in order to make the muscle stronger. Try to start with one set (12-15 repetitions) per activity and work up to three sets. To give your muscles time to recover, rest one full day between exercising each specific muscle group.
</p>
<p>
  It is important to stretch before and after exercising. Stretching increases and maintains your flexibility. Stretching regularly can help you exercise better, use your full range of motion and avoid injuries.
</p>
<p>
  When you have asthma, it is also important to warm-up. Your doctor may also tell you to use your asthma rescue medicine before you exercise and to avoid exercising where you might come into contact with your asthma triggers like outdoor pollen or air pollution.
</p>
<p>
  If you have asthma symptoms during exercise, stop. If needed, take your asthma rescue medicine. You may return to exercising if you feel well. However, if you need to stop a second time, or if you asthma symptoms worsen when you stop exercising, use another dose of rescue medicine and do not resume exercise. Report this to your provider.
</p>
<p>
  When adding exercise to your weekly routine, it is also important to stay hydrated. This means drinking enough water so you do not feel tired or weak, It is recommended to drink eight glasses of water a day, but you lose fluids when you sweat during exercising. Try to drink extra water during and after a workout.
</p>
<p>
  <strong>Remember that any amount of physical activity has health benefits. Even just moving more and sitting less yields better health outcomes. When starting a new exercise regimen start off slow and work your way up in time and intensity. It is important that you do not exercise so much that you hurt yourself, feel dizzy, or get very short of breath. This may be doing more harm than good for your body. Not every day has to be as intense as the previous day, change the intensity of workouts throughout the week and take rest days.</strong>
</p>





</body>
</html>`

export default text
