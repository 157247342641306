import {client} from './apiClient'

const adminDeviceLanguages = async (filter, deviceId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/languages",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminDeviceLanguage = async (deviceId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/language/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceLanguageActivities = async (deviceId, id, page, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/language/" + id + "/activities",
        method: "GET",
        params: {sort_field:'created_at', sort_order: 'asc', page, ...filter},
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceFilterLanguageActivities = async (deviceId, id, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/language/" + id + "/activities",
        method: "GET",
        params: filter,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminMasterLanguages = async (filter) => {
    return await client.request({
        url: '/admin/languages',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterLanguage = async (id) => {
    return await client.request({
        url: '/admin/language/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterLanguage = async (data) => {
    return await client.request({
        url: '/admin/language',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterLanguage = async (id, data) => {
    return await client.request({
        url: '/admin/language/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminDeviceLanguages,
    adminDeviceLanguage,
    adminDeviceLanguageActivities,
    adminDeviceFilterLanguageActivities,
    adminMasterLanguages,
    adminMasterLanguage,
    adminCreateMasterLanguage,
    adminUpdateMasterLanguage
}