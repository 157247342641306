const text = `<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
        <br/>
<p><mark style="background-color: lightblue">1C. You would like to gain weight</mark></p>
<div style="padding-left: 25px;">
  <p>
    If goal is to gain weight – ask, how much?
  </p>
  <div style="padding-left: 25px;">
    <p>
      <strong>You can ask some of the following:</strong>
    </p>
    <p>
      How would you describe your diet?
    </p>
    <p>
      How many meals are you eating a day?
    </p>
    <p>
      What did you have for breakfast?
    </p>
    <p>
      What would you like to change about your diet?
    </p>
  </div>
</div>

</body>
</html>`

export default text
