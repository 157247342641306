import React, {Component} from "react";
import {
    Button,
    Header,
    Segment,
    Form,
    Grid, Icon, Radio, Table,
    Divider, TransitionablePortal
} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import {actionCreators as sitesActionCreators} from "../../store/Sites";
import {actionCreators as usersActionCreators} from "../../store/Users";
import {actionCreators as icsmedicationActionCreators} from "../../store/ICSMedications";
import {actionCreators as patientsActionCreators} from "../../store/Patients";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import AppMessage from "../common/ui/AppMessage";
import Loading from "../common/ui/Loading";
import AccordionSegment from '../common/ui/AccordionSegment'
import moment from "moment";

class AddPatient extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newItem: {},
            patient: {},
            loading: false,
            edit: false,
            saving: -1
        }
    }


    async componentWillMount() {
        await this.props.getMasterSites()
        await this.props.getMasterICSMedications()
    }

    handleToggle = (field, e, target) => {
        let newItem = this.state.newItem
        newItem[field] = target.checked ? target.value : null
        this.setState({newItem})
    }
    handleChange = (e, target) => {
        let newItem = this.state.newItem
        newItem[target.name] = target.name === "active" || target.name === "required" ? target.checked : target.value
        this.setState({newItem})
    }
    handleSiteChange = (e, target) => {
        let newItem = this.state.newItem
        newItem[target.name] = target.name === "active" || target.name === "required" ? target.checked : target.value
        this.props.getProviderUser({siteId: target.value})
        this.setState({newItem})
    }
    handleSubmit = async () => {
        this.setState({saving: 1})
        const date = moment(this.state.patient.enrollDate).format('MM/DD/YYYY')
        let errors = await this.props.createPatient({
            ...this.state.newItem,
            enrollDate: date
        })
        if (errors.errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            this.props.history.push("/app/patient/" + errors.data.patient.id)
            await this.props.getPatients()
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    render() {

        let camsQuestion = [
            {text: "Having air movement from a fan, air conditioner or open window helps my asthma."},
            {text: "Praying, or having someone pray for me, helps my asthma."},
            {text: "Drinking coffee helps my asthma."},
            {text: "Using Vicks VapoRub helps my asthma."},
            {text: "Drinking water helps my asthma."},
            {text: "Drinking tea (herbal or regular) helps my asthma."},
            {text: "Steam or warm things on my chest helps my asthma."},
            {text: "My asthma can get worse if I go out with a wet head."},
            {text: "It is important to me that I find a natural way to treat my asthma."},
            {text: "I need my <ICSMedication> every day."},
            {text: "<ICSMedication> controls my asthma."},
            {text: "I am afraid that I will build up a tolerance to <ICSMedication>."},
            {text: "I make decisions about whether I need my <ICSMedication> on a day-by-day dose-by-dose basis."},
            {text: "I am the best judge of whether I need to take my <ICSMedication>."},
            {text: "<ICSMedication> can cause cancer, or damage the heart, liver or kidneys."},
            {text: "Taking my <ICSMedication> has caused side effects like weakness, dizziness, headache or sick-to-my-stomach."},
            {text: "Doctors get money from drug companies for writing prescriptions for my <ICSMedication>."},
        ]
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Patients",
                link: "/app/patients"
            }, {
                text: "Add Patient",
            }]}/>,
            <AccordionSegment key={1} header={'New Patient Information'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}>
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    {this.state.loading ? <Loading/> :
                        <Form className="editing">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <Form.Field>
                                            <label>Nickname</label>
                                            <Form.Input
                                                fluid
                                                placeholder='Nickname'
                                                value={this.state.newItem.nickname}
                                                name="nickname"
                                                onChange={this.handleChange.bind(this)}/>
                                        </Form.Field>
                                        <Form.Select
                                            fluid
                                            label='Site' placeholder='Site'
                                            value={this.state.newItem.siteId}
                                            options={this.props.sites.master.sites ? this.props.sites.master.sites.map((site, index) => {
                                                return {key: index, text: site.name, value: site.id}
                                            }) : []}
                                            name="siteId"
                                            onChange={this.handleSiteChange.bind(this)}
                                        />
                                        <Form.Select
                                            fluid
                                            label='Provider' placeholder='Provider'
                                            value={this.state.newItem.providerId}
                                            options={this.props.users.master.users ? this.props.users.master.users.map((site, index) => {
                                                return {
                                                    key: index,
                                                    text: site.firstName + " " + site.lastName,
                                                    value: site.id
                                                }
                                            }) : []}
                                            name="providerId"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <Form.Select
                                            fluid
                                            label='Status' placeholder='Status'
                                            value={this.state.newItem.status}
                                            options={[{key: 0, text: "Active", value: "Active"},
                                                {key: 1, text: "Inactive", value: "Inactive"}]}
                                            name="status"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>

                                        <Form.Select
                                            fluid
                                            label='ICS Medication' placeholder='ICS Medication'
                                            value={this.state.newItem.icsMedicationId}
                                            options={this.props.icsmedications.master.ics_medications ? this.props.icsmedications.master.ics_medications.map((site, index) => {
                                                return {key: index, text: site.name, value: site.id}
                                            }) : []}
                                            name="icsMedicationId"
                                            onChange={this.handleChange.bind(this)}
                                        />

                                        <SemanticDatepicker
                                            className={"date-time-picker"}
                                            style={{width: '100%'}}
                                            fluid
                                            label='Enroll Date'
                                            placeholder='Enroll Date'
                                            name="enrollDate"
                                            value={this.state.newItem.enrollDate ? new Date(this.state.newItem.enrollDate) : null}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <Form.Select
                                            label='ACQ'
                                            clearable
                                            search
                                            selection
                                            fluid
                                            placeholder='ACQ'
                                            name="acq"
                                            value={this.state.newItem.acq}
                                            onChange={this.handleChange.bind(this)}
                                            options={Array.from(Array(61).keys()).map(key => {
                                                return {
                                                    key: key,
                                                    text: (key * 0.1).toFixed(1),
                                                    value: (key * 0.1).toFixed(1)
                                                }
                                            })}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>

                                        <Divider horizontal>CAMA</Divider>
                                        <div>
                                            <Header as={"h5"} style={{textAlign: "center"}}>
                                                The Conventional and Alternative Management (CAM) Instrument for
                                                Asthma - PATIENT
                                            </Header>
                                            <span>
                                                    <strong>DIRECTIONS</strong>: This survey asks about your beliefs, opinions and favorite ways to manage your asthma. As you read
                                                    each statement check the box that most correctly describes how you feel. You can choose any number between 1(strongly disagree)
                                                    to 7(strongly agree).
                                                </span>
                                        </div>
                                        <Table celled definition compact striped className={"cama"}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell/>
                                                    <Table.HeaderCell width={1}>Strongly
                                                        disagree<br/>1</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}><br/>2</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}><br/>3</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}><br/>4</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}><br/>5</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}><br/>6</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>Strongly agree<br/>7</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {camsQuestion.map((question, index) => {
                                                    const camaField = "cama" + (index + 1)
                                                    const camaValue = this.state.newItem[camaField]
                                                    return <Table.Row key={index}>
                                                        <Table.Cell>{index + 1}. {question.text}</Table.Cell>
                                                        <Table.Cell><Radio value={1} checked={camaValue === 1}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        <Table.Cell><Radio value={2} checked={camaValue === 2}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        <Table.Cell><Radio value={3} checked={camaValue === 3}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        <Table.Cell><Radio value={4} checked={camaValue === 4}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        <Table.Cell><Radio value={5} checked={camaValue === 5}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        <Table.Cell><Radio value={6} checked={camaValue === 6}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                        <Table.Cell><Radio value={7} checked={camaValue === 7}
                                                                           onChange={this.handleToggle.bind(this, camaField)}/></Table.Cell>
                                                    </Table.Row>
                                                })}

                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button color='teal' floated='right'
                                                onClick={this.handleSubmit.bind(this)}>
                                            <Icon name='plus'/> Add
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Form>
                    }
                </div>}/>
        ];
    }
}

export default connect(
    state => {
        const {searchStore, patients, sites, treatments, icsmedications, users} = state;
        return {searchStore, patients, sites, treatments, icsmedications, users}
    },
    dispatch => bindActionCreators({...patientsActionCreators, ...sitesActionCreators, ...icsmedicationActionCreators, ...usersActionCreators}, dispatch)
)(AddPatient);

