import React from 'react'
import Layout from '.././layout/Layout'
import Router from '../../appRouter'

export default (props) => {
    return (
        <Layout history={props.history}>
            <Router />
        </Layout>
    );
}
