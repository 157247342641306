import {client} from './apiClient'

const adminCurriculumTopics = async (filter, deviceId, curriculumId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + curriculumId + "/topics",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminCurriculumTopic = async (deviceId, curriculumId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + curriculumId + "/topic/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminMasterTopics = async (filter, curriculumId) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topics",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminMasterTopic = async (curriculumId, id) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterTopic = async (curriculumId, data) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic",
        method: "POST",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterTopic = async (curriculumId, id, data) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + id,
        method: "PUT",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminCurriculumTopics,
    adminCurriculumTopic,
    adminMasterTopics,
    adminMasterTopic,
    adminCreateMasterTopic,
    adminUpdateMasterTopic
}