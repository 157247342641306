import React, {Component} from 'react';
import {Button, Modal, Checkbox} from "semantic-ui-react";

export default class PatientActiveScriptScreen1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openConfirm: false,
            checkedYes: false,
            checkedNo: false
        }
    }

    handleEnd(){
        this.props.handleEnd()
    }

    render(){
        let props = this.props
        return (
            <>
                <h3>CRITICAL COMPONENTS </h3>
                <ol>
                    <li style={{textIndent: "0%"}}>Be respectful.</li>
                    <li>Remember the patient is giving you permission to discuss his/her uncontrolled asthma/asthma self-management which is an
                        important aspect of the intervention
                    </li>
                    <li>Avoid Arguing or being confrontational</li>
                </ol>

                <h3>PREPARATION</h3>
                <ul>
                    <li>Review ACQ graphic, and CAM-A summary score</li>
                </ul>
                <br/>
                <table style={{fontWeight: "bold", backgroundColor: "white", border: "black solid"}}>
                    <tbody>
                    <tr>
                        <td style={{border: "black solid"}}>
                            <h3 style={{margin: 0, textAlign: "center"}}>SCRIPT</h3>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "black solid", margin: 0, padding: "10px"}}><font  style={{fontSize: "1.28571429rem"}}>"Hello, Good to see you again/meet
                            you. {props.patient.nickname}."
                            <br/><br/>
                            "My name is Dr./Nurse {props.user.firstName + " " + props.user.lastName}."</font>
                        </td>
                    </tr>
                    <tr style={{fontWeight: "bold", backgroundColor: "white", border: "black solid"}}>
                        <td style={{border: "black solid", margin: 0, padding: "10px"}}>
                            <font  style={{fontSize: "1.28571429rem"}}>
                                "Would you mind taking a few minutes to talk with me about your asthma control and how you are a managing your asthma?"
                            </font>
                            <br/>
                        </td>
                    </tr>


                    <tr style={{fontWeight: "bold", backgroundColor: "white", border: "black solid"}}>
                        <td style={{border: "black solid", margin: 0, padding: "20px 10px 20px 10px", textAlign:'center'}}>
                            <font  style={{fontSize: "1.28571429rem"}}>
                                "Does the patient want to continue with this portion of the visit?"
                            </font>
                            <br/>
                            <Checkbox
                                label='Yes'
                                onChange={(e, data) => {
                                    this.setState({checkedYes: data.checked})
                                        this.props.handleContinue(!data.checked)
                                }}
                                checked={this.state.checkedYes}
                            />
                            <br/>
                            <Modal
                                size='small'
                                style={{height: "fit-content", margin: "auto"}}
                                onClose={() => this.setState({openConfirm: false})}
                                onOpen={() => this.setState({openConfirm: true})}
                                open={this.state.openConfirm}
                                trigger={
                                    <Checkbox
                                        label='No'
                                        onChange={(e, data) => {
                                            this.setState({checkedNo: data.checked})
                                            this.setState({openConfirm: true})
                                        }}
                                        checked={this.state.checkedNo}
                                    />}
                            >
                                <Modal.Header>Confirm End Protocol</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <p>Are you sure you want to end this session. You cannot begin this session again without contacting an administrator?</p>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => {
                                        this.setState({openConfirm: false, checkedNo: false, checkedYes: false})
                                        this.props.clearAnswer()
                                    }}>
                                        No
                                    </Button>
                                    <Button
                                        onClick={this.handleEnd.bind(this)}
                                        negative
                                    >Yes</Button>
                                </Modal.Actions>
                            </Modal>
                        </td>
                    </tr>


                    </tbody>
                </table>

                <h3>OBJECTIVES</h3>
                <ul>
                    <li>Establish rapport</li>
                    <li>Raise the subject</li>
                </ul>

                <h3>ACTIONS</h3>
                <ul>
                    <li>Explain your provider role</li>
                    <li>Avoid a judgmental stance</li>
                    <li>Set the climate</li>
                    <li>Engage the patient</li>
                </ul>

                <h3>SUMMARY</h3>
                <p>This first step sets the climate for a successful BNI. Asking permission to discuss the subject of asthma
                    control and asthma management formally lets the patient know that their wishes and perception
                    are central to the treatment. It may also reduce their defensiveness, thereby allowing a more open and
                    motivated discussion about possible changes or next steps.</p>


            </>
        )
    }
}

;
