import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as reportActionCreators} from '../../store/Reports'
import {actionCreators as curriculumActionCreators} from '../../store/Curriculums'
import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Divider,
    Header,
    Accordion,
    Icon,
    Table,
    Button,
    Grid
} from 'semantic-ui-react'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'
import {Bar, HorizontalBar} from 'react-chartjs-2';
import {Link} from 'react-router-dom'
import _ from 'lodash'
import SimpleDeviceList from '../common/ui/SimpleDeviceList'
import SimpleDeviceUsageList from '../common/ui/SimpleDeviceUsageList'

class Report extends Component {
    state = {
        filter: {},
        page: 1,
        loading: true,
        currentIndex: {
            activeCurriculum: 0,
            activeTopic: -1,
            activeChart: 0,
            activeDevice: -1
        },
        column: 'date',
        columnLocation: 'country',
        dates: [],
        regions: [],
        direction: 'ascending',
    }

    handleSort = (clickedColumn) => () => {
        const {column, dates, direction} = this.state

        if (column !== clickedColumn) {
            var sortedArray = _.sortBy(dates, [clickedColumn])
            if (clickedColumn === 'date') {
                sortedArray = dates.sort(function compare(a, b) {
                    var dateA = new Date(a.date);
                    var dateB = new Date(b.date);
                    return dateA - dateB;
                });
            }

            this.setState({
                column: clickedColumn,
                dates: sortedArray,
                direction: 'ascending',
            })
            return
        }

        this.setState({
            dates: dates.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    handleLocationSort = (clickedColumn) => () => {
        const {columnLocation, regions, direction} = this.state

        console.log(clickedColumn)
        if (columnLocation !== clickedColumn) {
            var sortedArray = _.sortBy(regions, [clickedColumn])
            console.log(sortedArray)
            this.setState({
                columnLocation: clickedColumn,
                regions: sortedArray,
                direction: 'ascending',
            })
            return
        }

        this.setState({
            regions: regions.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    componentWillMount() {
        this.props.getReport(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reports.report && nextProps.reports.report.data && nextProps.reports.report.data.dates) {
            let dates = nextProps.reports.report.data.dates.sort(function compare(a, b) {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);
                return dateA - dateB;
            })
            this.setState({loading: !this.props.reports.report, dates})
        } else if (nextProps.reports.report && nextProps.reports.report.data && nextProps.reports.report.data.regions) {
            var regions = _.sortBy(nextProps.reports.report.data.regions, ['country'])
            this.setState({loading: !this.props.reports.report, regions})
        }
        else {
            this.setState({loading: !this.props.reports.report})
        }
    }

    handleClick = (option, isSuper, e, titleProps) => {
        const {index} = titleProps
        const {currentIndex} = this.state
        if (option === 'activeCurriculum' || isSuper) {
            currentIndex['activeTopic'] = -1
        }
        const newIndex = currentIndex[option] === index ? -1 : index
        currentIndex[option] = newIndex
        this.setState({currentIndex})
    }

    getTimeText(time) {
        let timeMinutes = Math.floor(time / 60000)
        let remainderMinutes = time % 60000
        let timeSeconds = Math.floor(remainderMinutes / 1000)
        return timeMinutes + " min " + timeSeconds + " sec"
    }

    renderChart(entity, option, currentIndex) {
        let timeSpent = entity[option]
        let data = {
            labels: timeSpent.map(item => item.name),
            datasets: [{
                label: 'Device Count',
                data: timeSpent.map(item => item.count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 3
            }]
        }
        return <Grid>
            <Grid.Column mobile={16} tablet={16} computer={9}>
                {option === 'overall_completion_percentages' ?
                    <HorizontalBar
                        key={2}
                        data={data}
                        width={100}
                        height={50}
                        options={{
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            },
                            maintainAspectRatio: true
                        }}/> : <Bar
                        key={2}
                        data={data}
                        width={100}
                        height={50}
                        options={{
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            },
                            maintainAspectRatio: true
                        }}
                    />}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={7}>
                <Accordion styled key={3} style={{marginTop: 0}}>
                    {
                        timeSpent.map((item, i) => {
                            let index = entity.name + "_" + item.name + "_" + option + "_" + i
                            if (item.count > 0) {
                                return [
                                    <Accordion.Title
                                        key={1}
                                        active={currentIndex.activeDevice === index}
                                        index={index}
                                        onClick={this.handleClick.bind(this, 'activeDevice', false)}>
                                        <Icon name='dropdown'/>
                                        Devices in {item.name} Population
                                    </Accordion.Title>,
                                    <Accordion.Content
                                        key={2}
                                        active={currentIndex.activeDevice === index}>
                                        <Table basic='very' compact>
                                            <Table.Body>
                                                {
                                                    item.devices.sort((a, b) => {
                                                        if (option === 'overall_completion_percentages') {
                                                            if (a.percentage > b.percentage) {
                                                                return 1;
                                                            }
                                                            if (b.percentage > a.percentage) {
                                                                return -1;
                                                            }
                                                        } else {
                                                            if (a.total_time > b.total_time) {
                                                                return 1;
                                                            }
                                                            if (b.total_time > a.total_time) {
                                                                return -1;
                                                            }
                                                        }
                                                        return 0;
                                                    }).map((device, j) => {
                                                        return <Table.Row key={j}>
                                                            <Table.Cell collapsing>
                                                                <Link
                                                                    to={"/app/device/" + device.deviceId}>
                                                                    <Button color='teal'
                                                                            size="mini"
                                                                            icon>
                                                                        <Icon name="eye"/>
                                                                    </Button>
                                                                </Link>
                                                            </Table.Cell>
                                                            <Table.Cell><Link
                                                                to={"/app/device/" + device.deviceId}>{device.deviceId}
                                                            </Link></Table.Cell>
                                                            {option === 'overall_completion_percentages' ?
                                                                <Table.Cell>{(device.percentage).toFixed(2)}
                                                                    %</Table.Cell> :
                                                                <Table.Cell>{this.getTimeText(device.total_time)}</Table.Cell>}
                                                        </Table.Row>

                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Accordion.Content>]
                            } else {
                                return null
                            }

                        })
                    }
                </Accordion>
            </Grid.Column>
        </Grid>


    }

    renderAccordionTopic(currentIndex, tindex, topic, option, label) {
        return <Accordion styled key={3}
                          style={{marginTop: 0, boxShadow: 'unset'}}>
            <Accordion.Title
                active={true}
                index={0}>
                Time spent in {label}
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(topic, 'overall_time_buckets', currentIndex)}
            </Accordion.Content>
            <Accordion.Title
                active={true}
                index={1}>
                Time spent in {label} where completed
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(topic, 'completed_time_buckets', currentIndex)}
            </Accordion.Content>
            <Accordion.Title
                active={true}
                index={2}>
                Time spent in {label} where NOT completed
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(topic, 'non_completed_time_buckets', currentIndex)}
            </Accordion.Content>
            <Accordion.Title
                active={true}
                index={3}>
                {label} Completed Percentages
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(topic, 'overall_completion_percentages', currentIndex)}
            </Accordion.Content>
        </Accordion>;
    }

    renderAccordionCurriculum(currentIndex, tindex, curriculum, option, label) {
        return <Accordion styled key={3}
                          style={{marginTop: 0, boxShadow: 'unset'}}>
            <Accordion.Title
                active={true}
                index={0}>
                Time spent in {label}
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(curriculum, 'overall_time_buckets', currentIndex)}
            </Accordion.Content>
            <Accordion.Title
                active={true}
                index={1}>
                Time spent in {label} where completed
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(curriculum, 'completed_time_buckets', currentIndex)}
            </Accordion.Content>
            <Accordion.Title
                active={true}
                index={2}>
                Time spent in {label} where NOT completed
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(curriculum, 'non_completed_time_buckets', currentIndex)}
            </Accordion.Content>
            <Accordion.Title
                active={true}
                index={3}>
                {label} Completed Percentages
            </Accordion.Title>
            <Accordion.Content
                active={true}>
                {this.renderChart(curriculum, 'overall_completion_percentages', currentIndex)}
            </Accordion.Content>
        </Accordion>;
    }

    renderReportCurriculumSummary(report, currentIndex) {
        return report.data.active_curriculums.map((curriculumn, index) => {
            return [
                <Accordion.Title
                    key={0}
                    active={currentIndex['activeCurriculum'] === index}
                    index={0}
                    onClick={this.handleClick.bind(this, 'activeCurriculum', true)}>
                    <Icon name='dropdown'/>
                    Curriculumn: {curriculumn.name}
                </Accordion.Title>,
                <Accordion.Content
                    key={1}
                    active={currentIndex['activeCurriculum'] === index}>
                    <GridFormInput
                        width={240}
                        label="Devices with no time"
                        component={curriculumn.no_time_devices.count}
                    />
                    <GridFormInput
                        width={240}
                        label="Devices with no time in the last 30 days"
                        component={curriculumn.no_time_in_last_30_days_devices.count}
                    />
                    {this.renderAccordionCurriculum(currentIndex, index, curriculumn, 'activeCurriculum', 'Program')}
                    <div>
                        <Divider horizontal style={{marginBottom: 0}}>
                            <Header as='h4'>
                                Topics
                            </Header>
                        </Divider>
                        <Accordion styled key={3}>
                            {curriculumn.topics.map((topic, tindex) => {
                                return [
                                    <Accordion.Title
                                        key={0}
                                        active={currentIndex['activeTopic'] === tindex}
                                        index={tindex}
                                        onClick={this.handleClick.bind(this, 'activeTopic', false)}>
                                        <Icon name='dropdown'/>
                                        Topic: {topic.name}
                                    </Accordion.Title>,
                                    <Accordion.Content
                                        key={1}
                                        active={currentIndex['activeTopic'] === tindex}>
                                        <div key={tindex}>
                                            {this.renderAccordionTopic(currentIndex, tindex, topic, 'activeTopic', 'Topic')}
                                        </div>
                                    </Accordion.Content>
                                ]
                            })
                            }
                        </Accordion>
                    </div>
                </Accordion.Content>
            ]
        });
    }

    renderReportDeviceLocationsSummary(regions) {
        const {columnLocation, direction} = this.state

        return [<Accordion.Title
            key={0}
            active={true}
            index={0}>
            <Icon name='dropdown'/>
            Regions
        </Accordion.Title>,
            <Accordion.Content
                key={1}
                active={true}>
                <Table sortable compact>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell sorted={columnLocation === 'country' ? direction : null}
                                              onClick={this.handleLocationSort('country')}>Country</Table.HeaderCell>
                            <Table.HeaderCell sorted={columnLocation === 'state' ? direction : null}
                                              onClick={this.handleLocationSort('state')}>State</Table.HeaderCell>
                            <Table.HeaderCell sorted={columnLocation === 'city' ? direction : null}
                                              onClick={this.handleLocationSort('city')}>City</Table.HeaderCell>
                            <Table.HeaderCell sorted={columnLocation === 'count' ? direction : null}
                                              onClick={this.handleLocationSort('count')}>Count</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            regions.map((region, j) => {
                                return <Table.Row key={j}>
                                    <Table.Cell>{region.country}</Table.Cell>
                                    <Table.Cell>{region.state}</Table.Cell>
                                    <Table.Cell>{region.city || 'N/A'}</Table.Cell>
                                    <Table.Cell>{region.count}</Table.Cell>
                                </Table.Row>

                            })
                        }
                    </Table.Body>
                </Table>
            </Accordion.Content>];
    }

    renderReportDeviceUsageSummary(dates, report) {
        const {column, direction} = this.state

        let detailedRow = (props) => {
            if (props.className.indexOf('active') > -1) console.log(props.data)
            return props.className.indexOf('active') > -1 ?
                <div className={props.className}
                     style={{margin: "0px 0px 0px", background: '#f3937e77', overflow: 'hidden', paddingBottom: 0}}>
                    {props.data.count ? <SimpleDeviceUsageList date={props.data.date}
                                                               json_url={report.data.raw_device_json_url}/> : null}
                </div>
                : null
        }

        let detailedTitle = (props) => {
            return <Table.Row {...props} style={{background: '#f3937e20'}}>
                {props.className.indexOf('active') ? props.children.handle.active : props.children.handle.inactive}
                {props.children.rest}
            </Table.Row>
        }

        return <Table sortable className="accordion-report" style={{border: '1px solid black'}}>
            <Table.Header>
                <Table.HeaderCell sorted={column === 'date' ? direction : null}
                                  onClick={this.handleSort('date')}
                                  style={{background: '#f3937e60'}}>Date</Table.HeaderCell>
                <Table.HeaderCell sorted={column === 'count' ? direction : null}
                                  onClick={this.handleSort('count')}
                                  style={{background: '#f3937e60', width: '20%'}}>Count</Table.HeaderCell>
            </Table.Header>
            <Accordion.Accordion
                fluid='true'
                as={Table.Body}
                panels={dates.map(result => {
                    return {
                        key: result.date,
                        class: "tr",
                        title: {
                            as: detailedTitle,
                            className: "accordion-report",
                            children: {
                                handle: {
                                    active: <Table.Cell key={`${result.date}_question`}>
                                        <Icon fitted name='chevron circle right'/> &nbsp;{result.date}
                                    </Table.Cell>,
                                    inactive: <Table.Cell key={`${result.date}_question`}>
                                        <Icon fitted name='chevron circle down'/> &nbsp;{result.date}
                                    </Table.Cell>
                                },
                                rest: [
                                    <Table.Cell
                                        key={`${result.date}_right`}>{result.count}</Table.Cell>]
                            }
                        },
                        content: {
                            as: detailedRow,
                            data: result
                        }
                    };
                })}
            />
        </Table>;
    }

    renderReportDeviceInstallationSummary(dates) {
        const {column, direction} = this.state

        let detailedRow = (props) => {
            if (props.className.indexOf('active') > -1) console.log(props.data)
            return props.className.indexOf('active') > -1 ?
                <div className={props.className}
                     style={{margin: "0px 0px 0px", background: '#f3937e77', overflow: 'hidden', paddingBottom: 0}}>
                    <SimpleDeviceList date={props.data.date}/></div>
                : null
        }

        let detailedTitle = (props) => {
            return <Table.Row {...props} style={{background: '#f3937e20'}}>
                {props.className.indexOf('active') ? props.children.handle.active : props.children.handle.inactive}
                {props.children.rest}
            </Table.Row>
        }

        return <Table sortable className="accordion-report" style={{border: '1px solid black'}}>
            <Table.Header>
                <Table.HeaderCell sorted={column === 'date' ? direction : null}
                                  onClick={this.handleSort('date')}
                                  style={{background: '#f3937e60'}}>Date</Table.HeaderCell>
                <Table.HeaderCell sorted={column === 'count' ? direction : null}
                                  onClick={this.handleSort('count')}
                                  style={{background: '#f3937e60', width: '20%'}}>Count</Table.HeaderCell>
            </Table.Header>
            <Accordion.Accordion
                fluid='true'
                as={Table.Body}
                panels={dates.map(result => {
                    return {
                        key: result.date,
                        class: "tr",
                        title: {
                            as: detailedTitle,
                            className: "accordion-report",
                            children: {
                                handle: {
                                    active: <Table.Cell key={`${result.date}_question`}>
                                        <Icon fitted name='chevron circle right'/> &nbsp;{result.date}
                                    </Table.Cell>,
                                    inactive: <Table.Cell key={`${result.date}_question`}>
                                        <Icon fitted name='chevron circle down'/> &nbsp;{result.date}
                                    </Table.Cell>
                                },
                                rest: [
                                    <Table.Cell
                                        key={`${result.date}_right`}>{result.count}</Table.Cell>]
                            }
                        },
                        content: {
                            as: detailedRow,
                            data: result
                        }
                    };
                })}
            />
        </Table>;
    }

    renderQuestionTable(report, result) {
        let detailedRow = (props) => {
            return props.className.indexOf('active') > -1 ?
                <table className={props.className}
                       style={{margin: "0px 0px 0px", background: '#f3937e77', overflow: 'hidden', paddingBottom: 0}}>
                    <tr>
                        <td style={{width: '80%'}}>
                            <div>{props.children.info}</div>
                        </td>
                        <td style={{verticalAlign: 'top', width: '20%'}}>
                            {props.children.legend}
                        </td>
                    </tr>
                </table> : null
        }
        let detailedTitle = (props) => {
            return <Table.Row {...props} style={{background: '#f3937e20'}}>
                {props.className.indexOf('active') ? props.children.handle.active : props.children.handle.inactive}
                {props.children.rest}
            </Table.Row>
        }
        return <Table className="accordion-report" style={{border: '1px solid black'}}>
            <Table.Header>
                <Table.HeaderCell style={{background: '#f3937e60'}}>Question</Table.HeaderCell>
                <Table.HeaderCell style={{background: '#f3937e60'}}>Count</Table.HeaderCell>
                <Table.HeaderCell style={{background: '#f3937e60'}}>Wrong</Table.HeaderCell>
                <Table.HeaderCell style={{background: '#f3937e60'}}>Right</Table.HeaderCell>
            </Table.Header>
            <Accordion.Accordion
                fluid='true'
                as={Table.Body}
                panels={report.data.results.filter(topic => topic.topicId === result.topicId).map(result => {
                    return {
                        key: result.contentId,
                        class: "tr",
                        title: {
                            as: detailedTitle,
                            className: "accordion-report",
                            children: {
                                handle: {
                                    active: <Table.Cell
                                        key={`${result.contentId}_question`}><Icon fitted
                                                                                   name='chevron circle right'/> &nbsp;{result.quizName}
                                    </Table.Cell>,
                                    inactive: <Table.Cell
                                        key={`${result.contentId}_question`}><Icon fitted
                                                                                   name='chevron circle down'/> &nbsp;{result.quizName}
                                    </Table.Cell>
                                },
                                rest: [<Table.Cell
                                    key={`${result.contentId}_count`}>{result.overall_right_count + result.overall_wrong_count}</Table.Cell>,
                                    <Table.Cell
                                        key={`${result.contentId}_wrong`}>{result.overall_wrong_count}</Table.Cell>,
                                    <Table.Cell
                                        key={`${result.contentId}_right`}>{result.overall_right_count}</Table.Cell>]
                            }
                        },
                        content: {
                            as: detailedRow,
                            children: {
                                info: [
                                    <GridFormInput
                                        key={1}
                                        width={150}
                                        label="Content"
                                        component={result.contentName}
                                    />,
                                    <GridFormInput
                                        key={2}
                                        width={150}
                                        label="Question"
                                        component={result.quizName}
                                    />,
                                    <GridFormInput
                                        key={3}
                                        width={150}
                                        label="Correct Answer"
                                        component={result.correct_answer_text}
                                    />,
                                    <GridFormInput
                                        key={4}
                                        width={150}
                                        label="Overall Right"
                                        component={result.overall_right_count}
                                    />,
                                    <GridFormInput
                                        key={5}
                                        width={150}
                                        label="Overall Wrong"
                                        component={result.overall_wrong_count}
                                    />,
                                    <GridFormInput
                                        key={6}
                                        width={150}
                                        label="Overall Attempts"
                                        component={result.overall_attempt_count}
                                    />,
                                    <GridFormInput
                                        key={7}
                                        width={150}
                                        label="First Time Right"
                                        component={result.first_time_right}
                                    />,
                                    <GridFormInput
                                        key={8}
                                        width={150}
                                        label="First Time Wrong"
                                        component={result.first_time_wrong}
                                    />,
                                    <GridFormInput
                                        key={9}
                                        width={150}
                                        label="First Time Attempt"
                                        component={result.first_time_attempt_count}
                                    />,
                                    <GridFormInput
                                        key={10}
                                        width={150}
                                        label="Average Time"
                                        component={this.getTimeText(result.average_time)}
                                    />,
                                    <GridFormInput
                                        key={10}
                                        width={150}
                                        label="Median Time"
                                        component={this.getTimeText(result.median_time)}
                                    />],
                                legend: [
                                    <Table key={1} compact className='legend' style={{
                                        position: 'absolute',
                                        right: '30px',
                                        width: '300px',
                                        border: ' 1px solid rgba(34,36,38,1)'
                                    }}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{background: '#f3937ecc'}}/>
                                                <Table.HeaderCell style={{background: '#f3937ecc'}}>Possible
                                                    Answers</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {result.answers.map((answer, index) => {
                                                return <Table.Row key={index} style={{background: '#f3937e55'}}>
                                                    <Table.Cell>{answer.answer_icon_text}</Table.Cell>
                                                    <Table.Cell>{answer.answer}</Table.Cell>
                                                </Table.Row>
                                            })}
                                        </Table.Body>
                                    </Table>
                                ]
                            }
                        }
                    };
                })}
            />
        </Table>;
    }

    renderReportCurriculumQuizSummary(report, currentIndex) {
        let uniqueTopic = [];
        if (report && report.data) uniqueTopic = this.getUnique(report.data.results, 'topicId');

        return [
            <Accordion.Title
                key={0}
                active={true}
                index={0}>
                <Icon name='dropdown'/>
                Result
            </Accordion.Title>,
            <Accordion.Content
                key={1}
                active={true}>
                {uniqueTopic.map((result, index) => {
                    return <React.Fragment key={index}>
                        <GridFormInput
                            width={60}
                            label="Curriculum"
                            component={result.curriculumName}
                        />
                        <GridFormInput
                            width={40}
                            label="Topic"
                            component={result.topicName}
                        />
                        <GridFormInput
                            width={40}
                            label=" "
                            component={this.renderQuestionTable(report, result)}
                        />
                    </React.Fragment>
                })
                }
            </Accordion.Content>
        ]
    }

    downloadCSV(url) {
        let a = document.createElement('a');
        a.href = url;
        a.target = "_blank"
        a.click();
    }

    downloadJSON(url) {
        let a = document.createElement('a');
        a.href = url;
        a.target = "_blank"
        a.click();
    }

    getUnique(arr, comp) {
        const unique = arr
            .map(e => e[comp])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    render() {
        let dates = this.state.dates
        let regions = this.state.regions
        let report = this.props.reports.report
        let updatedAt = report.updated_at ? report.updated_at.substr(0, 16) : ""
        let currentIndex = this.state.currentIndex

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Reports",
                link: "/app/reports"
            }, {
                text: "Report"
            }]}/>,
            <AccordionSegment key={1} header={'Report Information'} component={
                <div>
                    {this.state.loading ? <Loading/> :
                        <Form>
                            <GridFormInput
                                width={150}
                                label="Report Name"
                                component={report.name}
                            />
                            <GridFormInput
                                width={150}
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />

                            <Divider horizontal style={{marginBottom: 0}}>
                                <Header as='h4'>
                                    {
                                        report.type === 'curriculumSummary' ? "Curriculums" :
                                            report.type === 'devicesLocationsSummary' ? "Device Locations" :
                                                report.type === 'devicesInstallationSummary' ? "Device Installs" :
                                                    report.type === 'devicesUsageSummary' ? "Device Usages" :
                                                        report.type === 'curriculumQuizSummary' ? "Quiz Summary" : ""

                                    }

                                </Header>
                            </Divider>

                            <GridFormInput
                                width={150}
                                label="Number of Devices"
                                component={report.data.device_count}
                            />
                            {report.data.raw_device_csv_url && report.data.raw_device_csv_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadCSV.bind(this, report.data.raw_device_csv_url)}>Download
                                    CSV<Icon
                                        name='download'/></Button> : null}
                            {report.data.raw_device_json_url && report.data.raw_device_json_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadJSON.bind(this, report.data.raw_device_json_url)}>Download
                                    JSON<Icon name='download'/></Button> : null}
                            {report.data.quiz_detail_raw_device_csv_url ?
                                <Divider horizontal style={{marginBottom: 0}}>
                                    <Header as='h4'>
                                        {
                                            report.type === 'curriculumQuizSummary' ? "Quiz Details" : ""
                                        }

                                    </Header>
                                </Divider> : null}

                            <br/>
                            {report.data.quiz_detail_raw_device_csv_url && report.data.quiz_detail_raw_device_csv_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadCSV.bind(this, report.data.quiz_detail_raw_device_csv_url)}>Download
                                    Quiz Details CSV<Icon name='download'/></Button> : null}

                            {report.data.quiz_detail_raw_device_json_url && report.data.quiz_detail_raw_device_json_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadJSON.bind(this, report.data.quiz_detail_raw_device_json_url)}>Download
                                    Quiz Details JSON<Icon name='download'/></Button> : null}

                            <Accordion styled key={3}>
                                {
                                    report.type === 'curriculumSummary' ? this.renderReportCurriculumSummary(report, currentIndex) :
                                        report.type === 'devicesLocationsSummary' ? this.renderReportDeviceLocationsSummary(regions) :
                                            report.type === 'devicesInstallationSummary' ? this.renderReportDeviceInstallationSummary(dates) :
                                                report.type === 'devicesUsageSummary' ? this.renderReportDeviceUsageSummary(dates, report) :
                                                    report.type === 'curriculumQuizSummary' ? this.renderReportCurriculumQuizSummary(report, currentIndex) : ""

                                }
                            </Accordion>
                        </Form>}
                </div>}/>

        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, reports: state.reports, curriculums: state.curriculums}),
    dispatch => bindActionCreators({...reportActionCreators, ...curriculumActionCreators}, dispatch)
)(Report)
