import {history} from '../../index'
import loginApi from './login'

export const handleError = async (response, callback) => {
    if (response.status === 404) {
        return response.data
    } else if (sessionStorage.getItem('token') === undefined) {
        history.push("/")
    } else if (response.data.code === "unauthorized") {
        try {
            let response = await loginApi.refresh()
            let auth = response.data
            sessionStorage.setItem('token', auth.token);
            callback()
        } catch (e) {
            sessionStorage.setItem('previouspath', history.location.pathname)
            history.push("/")
        }
    } else {
        return response.data
    }
}