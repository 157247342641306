import React from 'react';

// const GridFormInput = props => (
//     <Grid style={{marginTop: 0, height:60}}>
//         <Grid.Column style={{paddingRight:0, width:200}}>
//             <label>{props.label}:</label>
//         </Grid.Column>
//         <Grid.Column style={{paddingRight:0, minWidth:'80%'}}>
//             {props.component}
//         </Grid.Column>
//     </Grid>
// );

const GridFormInput = props => (
    <table style={{marginTop: 0, height: 40, width: '100%'}}>
        <tbody>
        <tr>
            {props.label || props.label === null ? <td style={{paddingRight: 0, paddingTop: 5, paddingBottom: 5, width: props.width || 115}}>
                <label style={{
                    float: 'right',
                    paddingRight: 10,
                    marginBottom: 0
                }}>{props.label.trim() ? props.label + ":" : ""}</label>
            </td> : null}
            <td style={{paddingRight: 0, paddingTop: 1, paddingBottom: 1}}>
                {props.component}
            </td>
        </tr>
        </tbody>
    </table>
);
export default GridFormInput;
