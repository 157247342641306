import contentsApi from './api/contents'
import {handleError} from './api/errorHandler'

const GET_TOPIC_CONTENTS = "GET_TOPIC_CONTENTS";
const GET_CONTENT = "GET_CONTENT";
const GET_MASTER_CONTENTS = "GET_MASTER_CONTENTS";
const GET_MASTER_CONTENT = "GET_MASTER_CONTENT";


const initialState = {
    data: {},
    content: {},
    master: {},
    mastercontent: {}
};

export const actionCreators = {
    getTopicContents: (deviceId, curriculumId, topicId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await contentsApi.adminTopicContents(filter, deviceId, curriculumId, topicId)
            let data = response.data
            dispatch({
                type: GET_TOPIC_CONTENTS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getTopicContent: (deviceId, curriculumId, topicId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await contentsApi.adminTopicContent(deviceId, curriculumId, topicId, id)
            let data = response.data
            dispatch({
                type: GET_CONTENT,
                payload: data.content
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },

    getMasterContents: (curriculumId, topicId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await contentsApi.adminMasterContents(filter, curriculumId, topicId)
            let data = response.data
            dispatch({
                type: GET_MASTER_CONTENTS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },

    getMasterContent: (curriculumId, topicId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await contentsApi.adminMasterContent(curriculumId, topicId, id)
            let data = response.data
            dispatch({
                type: GET_MASTER_CONTENT,
                payload: data.content
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterContents: (curriculumId, topicId, newItem) => async (dispatch, getState) => {
        let callback = async function () {
            await contentsApi.adminCreateMasterContent(curriculumId, topicId, newItem)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterContent:(curriculumId, topicId, id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await contentsApi.adminUpdateMasterContent(curriculumId, topicId, id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_TOPIC_CONTENTS) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_CONTENT) {
        return {...state, content: action.payload};
    }

    if (action.type === GET_MASTER_CONTENTS) {
        return {...state, master: action.payload};
    }
    if (action.type === GET_MASTER_CONTENT) {
        return {...state, mastercontent: action.payload};
    }

    return state;
};
