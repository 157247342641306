import React from 'react';
import ViewACQImageModal from "../components/common/modal/ViewACQImageModal";

const PatientActiveScriptScreen2 = props => (
    <>
        <h3>CRITICAL COMPONENTS </h3>
        <ol>
            <li>Review current beliefs about asthma control, how they are currently managing asthma, and asthma controller medicines (ICS)</li>
            <li>Make the connection between asthma management, ICS use, beliefs and uncontrolled asthma</li>
            <li>Compare patient's ICS use to evidence-based guidelines</li>
        </ol>

        <h3>PREPARATION</h3>
        <ul>
            <li>ACQ</li>
        </ul>

        <table style={{fontWeight: "bold", backgroundColor: "white", border: "black solid", margin: 0}}>
            <tbody>
            <tr>
                <td style={{border: "black solid"}}>
                    <h3 style={{margin: 0, textAlign: "center"}}>SCRIPT</h3>
                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", margin: 0, padding: "5px"}}><font  style={{fontSize: "1.28571429rem"}}>"From what I see here your
                    asthma is not in control?" <ViewACQImageModal acqimage={props.acqimage}/></font>

                    <div dangerouslySetInnerHTML={{__html: props.script.body}} ></div>

                    <font  style={{fontSize: "1.28571429rem"}}>"When your asthma is not in control, it is probably because you are not taking enough of the {props.patient.ics} or
                        maybe your are not taking it the right way or are having some other challenges related to controlling your asthma."</font>
                    <br/><br/>
                </td>
            </tr>
            <tr>
                <td style={{border: "black solid", padding: "5px"}}>
                    <font  style={{fontSize: "1.28571429rem"}}>"What connection (if any) do you see between how you use your asthma controller medicine ({props.patient.ics})
                        and
                        having asthma that is not in control?"</font>
                    <br/><br/>
                    If patient sees connection: reflect/reiterate what patient has said.
                    <br/><br/>
                    If patient does not see connection: make one using facts., <font  style={{fontSize: "1.28571429rem"}}>"Using at least one-half to
                    three-quarters of
                    all your {props.patient.ics} doses may decrease your risk for a serious asthma attack. Would you like to know
                    about
                    some of the worst things that could happen from an asthma attack?"</font> If the patient says, "I know I
                    could
                    die," then reiterate their statement and perhaps add that that's possible even with a mild attack.
                    <br/><br/>
                    If they don't say that they could die, but they give us permission to tell them, say, <font  style={{fontSize: "1.28571429rem"}}>"You can die
                    from
                    an asthma attack, even if you have mild asthma and even if, at first, it seems like a mild attack."</font>

                </td>
            </tr>
            </tbody>
        </table>

        <h3>OBJECTIVES</h3>
        <ul>
            <li>Review screen</li>
            <li>Make connection between self-management, low/incorrect use of ICS and uncontrolled asthma</li>
        </ul>

        <h3>ACTIONS</h3>
        <ul>
            <li>Review screening data</li>
            <li>Express concern</li>
            <li>Be non-judgmental</li>
            <li>Discussion of specific patient medical issues</li>
            <li>Review ACQ image with patient</li>
        </ul>

        <h3>SUMMARY</h3>
        <p>Linking uncontrolled asthma to low/incorrect ICS use or some other self-management error and by comparing beliefs to statistics is a great
            motivator
            towards encouraging a change in the patient's ICS use pattern. This is the opportunity to offer education
            related to specific patient issues.</p>
    </>
);

export default PatientActiveScriptScreen2;
