const text = `<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
<br/>
<p><mark style="background-color: lightblue">1B. Lose weight by reducing calories</mark></p>
<div style="padding-left: 25px;">
  <p>
    If goal is to lose weight – ask, how much? If goal is for large weight loss
    (50+ pounds), ask them to set a more modest goal &lt;50 pounds
  </p>
  <div style="padding-left: 25px;">
    <p>
      <strong>You can ask some of the following:</strong>
    </p>
    <p>
      What kinds of foods and beverages do you eat and drink on a typical day?
    </p>
    <p>
      Who does the grocery shopping at home?
    </p>
    <p>
      How often do you eat meals that have been cooked at home?
    </p>
    <p>
      What would you like to change about your diet?
    </p>
  </div>
</div>

</body>
</html>`

export default text
