import React from 'react';
import {Button, Icon} from "semantic-ui-react";

const PatientControlScriptScreenStart = props => (
    <>
        <p>
            <strong>
                <mark>Note to clinician: This first section should take 3 minutes of conversation</mark>
            </strong>
        </p>
        <p>
            <strong>1. </strong>
            <strong>Review of weight, diet, and exercise goals: </strong>
            I’d like to talk with you about healthy lifestyles. Do you have weight,
            diet, or exercise goals? If patient denies having these goals, select
            healthy diet
        </p>
        <p>
            If yes, ask them which goal is most important to them
        </p>
        <ul>
            <li>Weight
                <ul style={{listStyleType:"none"}}>
                    <li><Button icon labelPosition={'right'} color='teal'
                                onClick={()=>props.handleType("a")}><b>1a.</b> Lose weight through exercising<Icon
                        name='forward'/></Button></li>
                    <br/>
                    <li><Button icon labelPosition={'right'} color='teal'
                                onClick={()=>props.handleType("b")}><b>1b.</b> Lose weight by reducing calories<Icon
                        name='forward'/></Button></li>
                    <br/>
                    <li><Button icon labelPosition={'right'} color='teal'
                                onClick={()=>props.handleType("c")}><b>1c.</b> Gain weight<Icon
                        name='forward'/></Button></li>
                </ul>
            </li>
            <br/>
            <li>Diet
                <ul style={{listStyleType:"none"}}>
                    <li><Button icon labelPosition={'right'} color='teal'
                                onClick={()=>props.handleType("d")}><b>1d.</b>  Eating a healthier diet<Icon
                        name='forward'/></Button>
                        </li>
                </ul>
            </li>
            <br/>
            <li>Exercise
                <ul style={{listStyleType:"none"}}>
                    <li><Button icon labelPosition={'right'} color='teal'
                                onClick={()=>props.handleType("e")}><b>1e.</b> Exercising to stay healthy<Icon
                        name='forward'/></Button>
                    </li>
                </ul>
            </li>
        </ul>
        <br/>
    </>
);

export default PatientControlScriptScreenStart;
