import React, {Component} from "react";
import {
    Button,
    Header,
    Segment,
    Message,
    Form,
    Grid
} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Login";
import {connect} from "react-redux";
import AppBreadCrumb from '../common/ui/AppBreadCrumb'

class Devices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            current_password: "",
            new_password: "",
            new_password_confirmation: "",
            errorMessage: null,
            successMessage: null,
        }
    }

    async handleSubmit() {
        let response = await this.props.updatePassword(this.state)
        this.handleResponse(response);

    }

    handleResponse(response) {
        let errors = response.data.errors
        if (!errors) {
            let successMessage = <p>{response.data.message}</p>
            this.setState({successMessage, errorMessage: null, loading: false})
        } else {
            let errorMessage = Array.isArray(errors) ? errors.map((error, index) => {
                return <p key={index}>{error.message}</p>
            }) : <p>{response.data.message}</p>
            this.setState({successMessage: null, errorMessage, loading: false})

        }
    }

    handleChange(field, e) {
        let state = this.state
        state[field] = e.target.value
        state.errorMessage = null
        this.setState(state)
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Profile",
            }]}/>,
            <Segment key={1}>
                <Header>Profile</Header>
                <Grid>
                    <Grid.Column style={{maxWidth: 450}}>
                        <Segment stacked>
                            <Form size='large'>
                                {this.state.errorMessage ? <Message negative>
                                    <Message.Header>There was some errors with your submission</Message.Header>
                                    {this.state.errorMessage}
                                </Message> : this.state.successMessage ? <Message positive key={1}>
                                    <Message.Header>Success!</Message.Header>
                                    {this.state.successMessage}
                                </Message> : null}
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Current Password'
                                    label='Current Password'
                                    type='password'
                                    onChange={this.handleChange.bind(this, 'currentPassword')}
                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='New Password'
                                    label='New Password'
                                    type='password'
                                    onChange={this.handleChange.bind(this, 'new_password')}
                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Confirm New Password'
                                    label='Confirm New Password'
                                    type='password'
                                    onChange={this.handleChange.bind(this, 'new_password_confirmation')}
                                />

                                <Button color='teal'  size='large' onClick={this.handleSubmit.bind(this)}>
                                    Save
                                </Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>

            </Segment>
        ];
    }
}


export default connect(
    state => {
        const {searchStore, devices} = state;
        return {searchStore, devices}

    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Devices);
