import {client} from './apiClient'

const adminDeviceApps = async (filter, deviceId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/apps",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminDeviceApp = async (deviceId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/app/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceAppActivities = async (deviceId, id, page, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/app/" + id + "/activities",
        method: "GET",
        params: {sort_field:'created_at', sort_order: 'asc', page, ...filter},
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceFilterAppActivities = async (deviceId, id, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/app/" + id + "/activities",
        method: "GET",
        params: filter,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminMasterApps = async (filter) => {
    return await client.request({
        url: '/admin/apps',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterApp = async (id) => {
    return await client.request({
        url: '/admin/app/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterApp = async (data) => {
    return await client.request({
        url: '/admin/app',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterApp = async (id, data) => {
    return await client.request({
        url: '/admin/app/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminDeviceApps,
    adminDeviceApp,
    adminDeviceAppActivities,
    adminDeviceFilterAppActivities,
    adminMasterApps,
    adminMasterApp,
    adminCreateMasterApp,
    adminUpdateMasterApp
}