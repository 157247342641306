import imagesApi from './api/images'
import {handleError} from './api/errorHandler'
import {v1} from 'uuid'

const GET_ALL_IMAGES = "GET_ALL_IMAGES";
const GET_IMAGE = "GET_IMAGE";

const initialState = {
    data: [],
    image: {}
};

export const actionCreators = {
    getImages: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await imagesApi.adminImages(filter)
            let data = response.data

            dispatch({
                type: GET_ALL_IMAGES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getImage: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await imagesApi.adminImage(id)
            let data = response.data
            dispatch({
                type: GET_IMAGE,
                payload: data.image
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    uploadImage:(data) => async (dispatch, getState) => {
        let callback = async function () {
            const formData = new FormData();
            let fileName = data.file.name
            let randomName = v1()
            let date = new Date()
            let newFilename = fileName.replace(/^([^.]*)\.(.*)$/, '$1.' + date.getTime() + '.$2');
            formData.append('file', data.file, randomName)
            formData.append('name', decodeURI(newFilename))
            await imagesApi.uploadImage(formData)
        };
        try {
            await callback();
        } catch (e) {
            handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_ALL_IMAGES) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_IMAGE) {
        return {...state, image: action.payload};
    }

    return state;
};
