import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {routerReducer, routerMiddleware} from 'react-router-redux';
import * as Card from './Card';
import * as SideMenu from "./SideMenu";
import * as UserManagement from "./UserManagement";
import * as Login from "./Login";
import * as Apps from "./Apps";
import * as Curriculums from "./Curriculums";
import * as Topics from "./Topics";
import * as Contents from "./Contents";
import * as Quizzes from "./Quizzes";
import * as SearchStore from "./SearchStore";
import * as Reports from "./Reports";
import * as Users from "./Users";
import * as Sites from "./Sites";
import * as Treatments from "./Treatments";
import * as ICSMedications from "./ICSMedications";
import * as Patients from "./Patients";
import * as ServiceWorker from "./service-worker-redux";

export default function configureStore(history, initialState) {
    const reducers = {
        card: Card.reducer,
        sideMenu: SideMenu.reducer,
        userManagement: UserManagement.reducer,
        searchStore: SearchStore.reducer,
        login: Login.reducer,
        apps: Apps.reducer,
        curriculums: Curriculums.reducer,
        topics: Topics.reducer,
        contents: Contents.reducer,
        quizzes: Quizzes.reducer,
        reports: Reports.reducer,
        users: Users.reducer,
        sites: Sites.reducer,
        treatments: Treatments.reducer,
        icsmedications: ICSMedications.reducer,
        patients: Patients.reducer,
        serviceWorker: ServiceWorker.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
