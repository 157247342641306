import {client} from './apiClient'

const auth = async (email, password, remember) => {
    return await client.request({
        url: '/auth/local',
        method: "POST",
        data: {
            email, password
        }
    })
}

const me = async () => {
    return await client.request({
        url: '/user',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        }
    })
}
const refresh = async () => {
    return await client.request({
        url: '/auth/refreshtoken',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        }
    })
}

const sendPasswordReset = async (email) => {
    return await client.request({
        url: '/auth/sendpasswordreset',
        method: "GET",
        params: {
            email, destination: window.CONFIG.adminURL
        }
    })
}

const resetPassword = async (newPassword, newPasswordConfirmation, token) => {
    return await client.request({
        url: '/auth/resetpassword',
        method: "POST",
        data: {
            newPassword, newPasswordConfirmation, token
        }
    })
}

const updatePassword = async ({current_password, new_password, new_password_confirmation}) => {
    return await client.request({
        url: '/auth/updatepassword',
        method: "PUT",
        data:{current_password, new_password, new_password_confirmation},
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        }
    })
}

const inviteAdministrator = async (data) => {
    return await client.request({
        url: '/admin/user/invite',
        method: "POST",
        data: {
            ...data, destination: window.CONFIG.adminURL
        },
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        }
    })
}

const unlockAccount = async (token) => {
    return await client.request({
        url: '/auth/unlock',
        method: "GET",
        params: {
            token
        }
    })
}

const resendUnlock = async (email) => {
    return await client.request({
        url: '/auth/resendunlock',
        method: "GET",
        params: {
            email, destination: window.CONFIG.adminURL
        }
    })
}

export default {
    auth,
    refresh,
    sendPasswordReset,
    resetPassword,
    updatePassword,
    inviteAdministrator,
    unlockAccount,
    resendUnlock,
    me
}
