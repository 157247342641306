import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as curriculumActionCreators} from '../../store/Curriculums'
import {actionCreators as topicActionCreators} from '../../store/Topics'
import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Button,
    Table,
    Pagination,
    Label,
} from 'semantic-ui-react'
import FilterTopic from '../common/ui/FilterTopic'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import AddTopicModal from "../common/modal/AddTopicModal";
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'

class Curriculum extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTopic: {},
        curriculum: {},
        edit: false,
        loading: true,
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterCurriculum(this.props.match.params.id)
        this.props.getMasterTopics(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({curriculum: nextProps.curriculums.mastercurriculum, loading: !this.state.curriculum})
    }

    getTableData = props => {
        return props.topics.master.topics.filter(item => {
            return item.name.toLowerCase().includes(props.searchStore.text)
        }).sort((a, b) => a.order - b.order).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link
                        to={"/app/curriculum/" + props.match.params.id + "/topic/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.order}</Table.Cell>
                <Table.Cell>{item.active ?
                    <Label color='teal' horizontal>TRUE</Label> :
                    <Label color='red' horizontal>FALSE</Label>}
                </Table.Cell>
                <Table.Cell>{item.required ?
                    <Label color='teal' horizontal>TRUE</Label> :
                    <Label color='red' horizontal>FALSE</Label>}
                </Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getMasterTopics(this.props.match.params.id, this.state.filter, data.activePage)

    }

    getFilterTopic(filter) {
        this.setState({filter})
        this.props.getMasterTopics(this.props.match.params.id, filter, this.state.page)
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.createMasterTopic(this.props.match.params.id, data)
        if (errors !== undefined) {
            console.log(errors)
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterTopics(this.props.match.params.id, this.state.filter, this.state.page)
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    async handleSave() {
        this.setState({saving: 1})
        let curriculum = {
            name: this.state.curriculum.name,
            active: this.state.curriculum.active === 1,
            order: this.state.curriculum.order
        }
        let errors = await this.props.updateMasterCurriculum(this.props.match.params.id, curriculum)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterCurriculum(this.props.match.params.id)
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    handleCancel = () => {
        this.props.getMasterCurriculum(this.props.match.params.id)
        this.setState({edit: false})
    }

    handleChange = (e, target) => {
        if (this.state.edit) {
            let curriculum = this.state.curriculum
            curriculum[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked ? 1 : 0 : e.target.value
            this.setState({curriculum})
        }
    }

    render() {
        let curriculum = this.state.curriculum
        let readOnly = !this.state.edit
        let createdAt = curriculum.created_at ? curriculum.created_at.substr(0, 16) : ""
        let updatedAt = curriculum.updated_at ? curriculum.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Curriculums",
                link: "/app/curriculums"
            }, {
                text: "Curriculum",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Curriculum'} component={
                <div>
                    <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                    {this.state.loading ? <Loading/> :
                        <Form className={readOnly ? "readOnly" : "editing"}>
                            <GridFormInput
                                label="Name"
                                component={readOnly ? curriculum.name :
                                    <Form.Input
                                        fluid
                                        placeholder='Name'
                                        readOnly={readOnly}
                                        value={curriculum.name}
                                        name="name"
                                        onChange={this.handleChange.bind(this)}/>
                                }
                            />
                            <GridFormInput
                                label="Order"
                                component={readOnly ? curriculum.order :
                                    <Form.Input
                                        fluid
                                        placeholder='Order'
                                        readOnly={readOnly}
                                        value={curriculum.order}
                                        type={'number'}
                                        name="order"
                                        onChange={this.handleChange.bind(this)}/>
                                }
                            />

                            <GridFormInput
                                label="Active"
                                component={readOnly ? (curriculum.active === 1 ?
                                    <Label color='teal' horizontal>TRUE</Label> :
                                    <Label color='red' horizontal>FALSE</Label>) :
                                    <div className="ui toggle checkbox field">
                                        <input type="checkbox"
                                               name="active"
                                               readOnly={readOnly}
                                               checked={curriculum.active === 1}
                                               onChange={this.handleChange.bind(this)}/>
                                        <label></label>
                                    </div>}
                            />
                            <GridFormInput
                                label="Created"
                                component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />
                            {readOnly ? <Button color='teal' style={{float: "right"}}
                                                onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                [<Button key={0} color='teal' style={{float: "right"}}
                                         onClick={this.handleSave.bind(this)}>Save</Button>,
                                    <Button key={1} style={{float: "right"}}
                                            onClick={this.handleCancel.bind(this)}>Cancel</Button>]
                            }
                            <br/>
                            <br/>
                        </Form>
                    }
                </div>}/>,
            <AccordionSegment key={2} header={'Topics'} component={
                <div>
                    <FilterTopic filter={this.getFilterTopic.bind(this)}/>
                    {this.props.topics.master.topics ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Order</Table.HeaderCell>
                                    <Table.HeaderCell>Active</Table.HeaderCell>
                                    <Table.HeaderCell>Required</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={7}>
                                        <Pagination defaultActivePage={this.props.topics.master.paging.page}
                                                    totalPages={this.props.topics.master.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                        <AddTopicModal name={curriculum.name}
                                                       order={this.props.topics.master.topics.length ? Math.max.apply(Math,
                                                           this.props.topics.master.topics.map(function (o) {
                                                               return o.order;
                                                           })) + 1 : 0}
                                                       submit={this.handleSubmit.bind(this)}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                        <Loading/>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, curriculums: state.curriculums, topics: state.topics}),
    dispatch => bindActionCreators({...curriculumActionCreators, ...topicActionCreators}, dispatch)
)(Curriculum)
