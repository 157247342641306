import {client} from './apiClient'

const adminContentQuizzes = async (filter, deviceId, curriculumId, topicId, contentId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + curriculumId + "/topic/" + topicId + "/content/" + contentId + "/quizzes",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminContentQuiz = async (deviceId, curriculumId, topicId, contentId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + curriculumId + "/topic/" + topicId + "/content/" + contentId + "/quiz/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminMasterQuizzes = async (filter, curriculumId, topicId, contentId) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content/" + contentId + "/quizzes",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterQuiz = async (curriculumId, topicId, contentId, id) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content/" + contentId + "/quiz/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterQuiz = async (curriculumId, topicId, contentId, data) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content/" + contentId + "/quiz",
        method: "POST",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterQuiz= async (curriculumId, topicId, contentId, id, data) => {
    return await client.request({
        url: '/admin/curriculum/' + curriculumId + "/topic/" + topicId + "/content/" + contentId + "/quiz/" + id,
        method: "PUT",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminContentQuizzes,
    adminContentQuiz,
    adminMasterQuizzes,
    adminMasterQuiz,
    adminCreateMasterQuiz,
    adminUpdateMasterQuiz
}