import {client} from './apiClient'

const adminDeviceCurriculums = async (filter, deviceId) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculums",
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}
const adminDeviceCurriculum = async (deviceId, id) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceCurriculumActivities = async (deviceId, id, page, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + id + "/activities",
        method: "GET",
        params: {sort_field:'created_at', sort_order: 'asc', page, ...filter},
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminDeviceFilterCurriculumActivities = async (deviceId, id, filter) => {
    return await client.request({
        url: '/admin/device/' + deviceId + "/curriculum/" + id + "/activities",
        method: "GET",
        params: filter,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminMasterCurriculums = async (filter) => {
    return await client.request({
        url: '/admin/curriculums',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterCurriculum = async (id) => {
    return await client.request({
        url: '/admin/curriculum/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterCurriculum = async (data) => {
    return await client.request({
        url: '/admin/curriculum',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterCurriculum = async (id, data) => {
    return await client.request({
        url: '/admin/curriculum/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminDeviceCurriculums,
    adminDeviceCurriculum,
    adminDeviceCurriculumActivities,
    adminDeviceFilterCurriculumActivities,
    adminMasterCurriculums,
    adminMasterCurriculum,
    adminCreateMasterCurriculum,
    adminUpdateMasterCurriculum
}