import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as treatmentActionCreators} from '../../store/Treatments'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Button,
    Table,
    Pagination,
    TransitionablePortal,
} from 'semantic-ui-react'
import FilterTreatment from '../common/ui/FilterTreatment'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import moment from 'moment'
import AddTreatmentModal from '../common/modal/AddTreatmentModal'

class MasterTreatments extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTreatment: {},
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterTreatments()
    }

    getTableData = props => {
        return props.treatments.master.treatments.filter(item => {
            return item.name.toLowerCase().includes(props.searchStore.text)
        }).sort((a, b) => a.order - b.order).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/treatment/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format('LLLL')}</Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getMasterTreatments(this.state.filter, data.activePage)
    }

    getFilterTreatment(filter) {
        this.setState({filter})
        this.props.getMasterTreatments(filter, this.state.page)
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.createMasterTreatment(data)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterTreatments(this.state.filter, this.state.page)
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    render() {
        let isResearchAssistant = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'research_assistant') : false
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Treatments",
            }]}/>,
            <AccordionSegment key={1} header={'Treatments'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}
                        >
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    <FilterTreatment filter={this.getFilterTreatment.bind(this)}/>
                    {this.props.treatments.master.treatments ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Created At</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={6}>
                                        <Pagination defaultActivePage={this.props.treatments.master.paging.page}
                                                    totalPages={this.props.treatments.master.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                        {isResearchAssistant ? null :
                                            <AddTreatmentModal
                                                submit={this.handleSubmit.bind(this)}/>
                                        }
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                        <Loading/>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, treatments: state.treatments, login: state.login}),
    dispatch => bindActionCreators({...treatmentActionCreators}, dispatch)
)(MasterTreatments)
