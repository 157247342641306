import React, {Component} from "react";
import {
    Accordion,
    Divider,
    Icon
} from "semantic-ui-react";

export class AccordionSegment extends Component {
    state = {activeIndex: 0}

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state

        let icon = activeIndex === 0 ?
            <Icon size={'small'} name='minus' style={{float: 'right', margin: '4px -4px 0 0'}}/> :
            <Icon size={'small'} name='plus' style={{float: 'right', margin: '4px -4px 0 0'}}/>
        return (
            <Accordion fluid styled style={{marginTop: 10, background: this.props.backgroundColor}}>
                <Accordion.Title
                    icon={icon}
                    style={{fontSize: 15}}
                    active={activeIndex === 0}
                    content={this.props.header}
                    index={0}
                    onClick={this.handleClick}
                />
                <Accordion.Content style={{background: this.props.backgroundColor}} active={activeIndex === 0}>
                    <Divider style={{margin: '-7px -15px 10px -15px'}}/>
                    {this.props.component}
                </Accordion.Content>
            </Accordion>
        )
    }
}

export default AccordionSegment