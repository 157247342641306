const text = `<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
<br/>
<p><mark style="background-color: lightblue">1A. Lose weight through exercise</mark></p>
<div style="padding-left: 25px;">
  <p>
    If goal is to lose weight – ask, how much? If goal is for large weight loss
    (50+ pounds), ask them to set a more modest goal &lt;50 pounds
  </p>
    <div style="padding-left: 25px;">
    <p>
        <strong>You can ask some of the following:</strong>
      </p>
      <p>
        How many times a week do you go on a walk or exercise?
      </p>
      <p>
        What kinds of activities do you enjoy?
      </p>
      <p>
        Do you like walking?
      </p>
      <p>
        Do you prefer activities you can do alone, with someone else, or in a
        group?
      </p>
      <p>
        Where would you like to exercise (gym, at home)? How often?
      </p>
  </div>
</div>

</body>
</html>`

export default text
