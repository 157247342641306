import languagesApi from './api/languages'
import {handleError} from './api/errorHandler'

const GET_DEVICE_LANGUAGES = "GET_DEVICE_LANGUAGES";
const GET_LANGUAGE = "GET_LANGUAGE";
const GET_LANGUAGE_ACTIVITIES = "GET_LANGUAGE_ACTIVITIES";
const GET_MASTER_LANGUAGES = "GET_MASTER_LANGUAGES"
const GET_MASTER_LANGUAGE = "GET_MASTER_LANGUAGE"

const initialState = {
    data: {},
    master: {},
    language: {},
    masterlanguage: {},
};
export const actionCreators = {
    getDeviceLanguages: (deviceId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await languagesApi.adminDeviceLanguages(filter, deviceId)
            let data = response.data
            dispatch({
                type: GET_DEVICE_LANGUAGES,
                payload: data
            });
        };

        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceLanguage: (deviceId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await languagesApi.adminDeviceLanguage(deviceId, id)
            let data = response.data
            dispatch({
                type: GET_LANGUAGE,
                payload: data.language
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceFilterLanguageActivities: (deviceId, id, filter) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await languagesApi.adminDeviceFilterLanguageActivities(deviceId, id, filter)
            let data = response.data
            await dispatch({
                type: GET_LANGUAGE_ACTIVITIES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceLanguageActivities: (deviceId, id, limit, filter) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await languagesApi.adminDeviceLanguageActivities(deviceId, id, limit, filter)
            let data = response.data
            let activities = getState().languages.activities
            if (activities) {
                activities.activities.slice(0).reverse().map(activity => {
                    return data.activities.unshift(activity)
                })
            }
            await dispatch({
                type: GET_LANGUAGE_ACTIVITIES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterLanguages: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await languagesApi.adminMasterLanguages(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_LANGUAGES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterLanguage: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await languagesApi.adminMasterLanguage(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_LANGUAGE,
                payload: data.language_text
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterLanguage: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await languagesApi.adminCreateMasterLanguage(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterLanguage: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await languagesApi.adminUpdateMasterLanguage(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_DEVICE_LANGUAGES) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_MASTER_LANGUAGES) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_LANGUAGE) {
        return {...state, language: action.payload, activities: null};
    }
    if (action.type === GET_LANGUAGE_ACTIVITIES) {
        return {...state, activities: action.payload};
    }
    if (action.type === GET_MASTER_LANGUAGE) {
        return {...state, masterlanguage: action.payload};
    }


    return state;
};
