import React, {Component} from 'react'
import 'semantic-ui-css/semantic.min.css'
import {
    Header,
    Button,
    Icon,
    Modal,
    Image,
} from 'semantic-ui-react'
import Loading from '../ui/Loading'

export default class ViewACQImageModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
            newItem: {
                name: "",
                data: {}
            }
        }

    }

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})

    handleChange = (e) => {
        let newItem = this.state.newItem
        newItem[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked : e.target.value
        this.setState({newItem})
    }

    handleJSONChange = (e, target) => {
        let newItem = this.state.newItem
        newItem['data'] = e
        this.setState({newItem})
    }
    render() {
        let data = ""
        if(this.props.acqimage.image){
            data = 'data:'+(this.props.acqimage.headers? this.props.acqimage.headers['Content-Type']: null)+';charset=utf-8;base64, '+this.props.acqimage.image
        }
        return (
            <Modal
                dimmer={"blurring"}
                trigger={<Button
                    icon labelPosition={"right"}
                    color='teal'
                    onClick={this.handleOpen.bind(this)}
                ><Icon name='eye'/>View ACQ Image
                </Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose.bind(this)}
                size='small'
                style={{height: "fit-content", margin: "auto"}}
            >
                <Header content={[<p key={1}>View ACQ Image</p>, (
                    this.props.name ?
                        <p key={2} style={{fontSize: 15, marginLeft: 20}}><Icon name={"level up alternate"}
                                                                                rotated="clockwise"/>{this.props.name}
                        </p> : null)]}/>
                <Modal.Content>
                    {this.props.acqimage.image ? <Image src={data} />: <Loading/>}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}
