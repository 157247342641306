import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Router, Redirect } from 'react-router';
import App from './components/layout/App'

import Login from './components/pages/Login';
import UpdatePassword from "./components/pages/UpdatePassword";
import ResetPassword from "./components/pages/ResetPassword";
import ForgotPassword from "./components/pages/ForgotPassword";
import UnlockAccount from "./components/pages/UnlockAccount";
import ResendUnlock from "./components/pages/ResendUnlock";

const NotAuthorized = (props) => {
    sessionStorage.setItem("previouspath",props.location.pathname)
    return <Redirect to="/"/>;
}

const MainRouter = (props) => {
    return (
        <Router history={props.history}>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/forgotpassword" exact component={ForgotPassword} />
                <Route path="/unlockaccount" exact component={UnlockAccount} />
                <Route path="/resendunlock" exact component={ResendUnlock} />
                <Route path="/resetpassword" exact component={ResetPassword} />
                <Route path="/updatepassword" exact component={UpdatePassword} />
                <Route
                    path="/app"
                    component={props.authenticated ? App : NotAuthorized}
                />
            </Switch>
        </Router>
    );
}

export default connect(state => {
    return {
        authenticated: state.login.user.authenticated
    };
})(MainRouter);
