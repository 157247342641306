import React from 'react';
import {Breadcrumb} from "semantic-ui-react";
import {Link} from 'react-router-dom'

const AppBreadcrumb = props => (
    <Breadcrumb size={'large'}>{
        props.crumbs.map((crumb, index) => {
            let section = <span>{crumb.text}</span>
            if (crumb.link) {
                section = <Link to={crumb.link}>{crumb.text}</Link>
            }
            let component = [
                <Breadcrumb.Section key={index} active={!crumb.link}>
                    {section}
                </Breadcrumb.Section>,
            ]
            if (props.crumbs.length - 1 > index) {
                component.push(<Breadcrumb.Divider key={index+"-divider"} icon='right angle'/>)
            }
            return component
        })}
    </Breadcrumb>
);

export default AppBreadcrumb;
