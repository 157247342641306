import React, {Component} from "react";
import {
    Button,
    Table,
    Pagination, Icon, Label,
} from "semantic-ui-react";
import {Link} from 'react-router-dom'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import FilterPatient from '../common/ui/FilterPatient'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'
import {actionCreators} from "../../store/Patients";
import {actionCreators as sitesActionCreators} from "../../store/Sites";
import {actionCreators as treatmentsActionCreators} from "../../store/Treatments";
import {actionCreators as usersActionCreators} from "../../store/Users";
import {actionCreators as icsmedicationActionCreators} from "../../store/ICSMedications";

class Patients extends Component {
    state = {
        filter: {},
        page: 1
    }

    async componentWillMount() {
        this.props.getMasterTreatments()
        this.props.getMasterSites()
        this.props.getMasterICSMedications()
        await this.props.getProviderUser({roles: 'clinician'})
        await this.props.getPatients()
    }


    getTableData = props => {
        let isClinician = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'clinician') : false
        let isAdmin = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'admin') : false

        return props.patients.data.patients.filter(item => {
            return item.nickname ? item.nickname.toLowerCase().includes(props.searchStore.text) : true
        }).map((item, index) => {
                const site = this.props.sites.master.sites ? this.props.sites.master.sites.find(find => find.id === item.siteId) : null
                const provider = this.props.users.master.users ? this.props.users.master.users.find(find => find.id === item.providerId) : null
                const treatment = item.treatment ? item.treatment.name : ""
                return <Table.Row key={item.id}>
                    <Table.Cell collapsing>
                        <Link to={"/app/patient/" + item.id}>
                            <Button color='teal' size="small" icon> View
                            </Button>
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        <Link to={"/app/patient/" + item.id} style={{color:'black'}}>
                            {item.nickname}
                        </Link>
                    </Table.Cell>
                    {isClinician ? null :
                        <Table.Cell>{provider ? provider.firstName + " " + provider.lastName : item.providerId}</Table.Cell>}
                    {isClinician ? null : <Table.Cell>{site ? site.name : item.siteId}</Table.Cell>}
                    {isAdmin ? <Table.Cell>{treatment}</Table.Cell> : null}
                    {isClinician ? null : <Table.Cell>{item.enrollDate}</Table.Cell>}
                    {isClinician ? null : <Table.Cell>{item.protocol_finished ?
                        <Label color='teal' horizontal>TRUE</Label> :
                        <Label horizontal>FALSE</Label>}
                    </Table.Cell>}


                </Table.Row>
            }
        );
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getPatients(this.state.filter, data.activePage)
    }

    getFilterPatient(filter) {
        this.setState({filter})
        this.props.getPatients(filter, this.state.page)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.createPatient(data)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getPatients(this.state.filter, this.state.page)
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    handleOpen() {
        this.props.history.push("/app/patient")
    }

    render() {
        let isClinician = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'clinician') : false
        let isAdmin = this.props.login.user.roles ? this.props.login.user.roles.find(role => role.name === 'admin') : false

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Patients",
            }]}/>,
            <AccordionSegment key={1} header={'Patients'} component={
                <div>
                    <FilterPatient filter={this.getFilterPatient.bind(this)}
                                   sites={this.props.sites.master.sites}
                                   providers={this.props.users.master.users}
                                   icsMedications={this.props.icsmedications.master.ics_medications}/>
                    {
                        this.props.patients.data.patients ?
                            <Table compact celled definition>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell/>
                                        <Table.HeaderCell>NickName</Table.HeaderCell>
                                        {isClinician ? null : <Table.HeaderCell>Provider name</Table.HeaderCell>}
                                        {isClinician ? null : <Table.HeaderCell>Site Id</Table.HeaderCell>}
                                        {isAdmin ? <Table.HeaderCell>Treatment</Table.HeaderCell> : null}
                                        {isClinician ? null : <Table.HeaderCell>Enroll Date</Table.HeaderCell>}
                                        {isClinician ? null : <Table.HeaderCell>Protocol Completed</Table.HeaderCell>}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{this.getTableData(this.props)}</Table.Body>
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={7}>
                                            <Pagination activePage={this.props.patients.data.paging.page || 1}
                                                        totalPages={this.props.patients.data.paging.pages}
                                                        onPageChange={this.onPageChange.bind(this)}
                                                        size='mini'/>
                                            {isClinician ? null : <Button
                                                floated='right'
                                                icon
                                                labelPosition='left'
                                                color='teal'
                                                size='small'
                                                onClick={this.handleOpen.bind(this)}
                                            >
                                                <Icon name='plus'/> Add Patient
                                            </Button>
                                            }
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table> :
                            <Loading/>
                    }
                </div>}/>
        ];
    }
}


export default connect(
    state => {
        const {searchStore, patients, sites, treatments, icsmedications, users, login} = state;
        return {searchStore, patients, sites, treatments, icsmedications, users, login}
    },
    dispatch => bindActionCreators({...actionCreators, ...sitesActionCreators, ...icsmedicationActionCreators, ...treatmentsActionCreators, ...usersActionCreators}, dispatch)
)(Patients);
