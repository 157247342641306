const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
<p>
  <strong>
    <mark>Note to clinician: This second section should take 3 minutes of conversation. </mark><mark style="color:#db2828">Pick some of the following to discuss.</mark>
  </strong>
</p>

<p>
  <strong><u>2. Counsel patient: </u></strong>
  <strong>
    Describe benefits of diet, exercise, weight loss/gain and healthy
    eating.
  </strong>
</p>
<p><mark style="background-color: lightblue">2A. Lose weight through exercise</mark></p>

<p>
  <strong>Work backwards from the patient’s goal using the minimum information
  necessary to cover the most essential learning topics that will enable and motivate them to reduce weight and get active.</strong>
</p>
<p>
  <strong>Being active is important for any weight-loss program. When you're active,
  your body uses more energy (calories). And when you burn more calories than
  you consume, you lose weight.</strong>
</p>
<p>
  To lose weight, most people need to reduce the number of calories they
  consume and increase their physical activity. According to the 2015-2020
  Dietary Guidelines for Americans, in general, that means that to lose 1 1/2
  pounds (0.7 kilograms) a week, you need to reduce your daily calories by
  500 to 750 calories, through exercise, diet or a combination of both.
</p>
<p>
  <strong>To lose weight through exercise alone, you will need a high amount of
  physical activity.</strong> A good way to start incorporating exercise into your day
  is by starting with just 5 minutes of physical activity several times a day
  5-6 times a week, like Monday-Friday. Over time you can gradually work up
  to 10 minutes per session, 3x a day.
</p>
<p>
  The more you exercise, the more health benefits you gain. Exercise is
  enjoyable when you find the activities you like to do and who you like to
  do them with. <strong>Try different types of exercise to figure out what you like
  best.</strong> Exercise can include walking, biking, dancing, yoga, following a
  fitness instruction video on YouTube or a group exercise class.
</p>
<p>
  <strong>Being active can help you live longer, maintain muscle, prevent joint pain
  as you age, strengthen your heart and lungs and help you sleep better at
  night. Exercising can also improve your mood, lower blood pressure and
  lower blood sugar.</strong>
</p>
<p>
  Strength training is an important part of exercise, especially as we age,
  we lose muscle mass. <strong>Strength training can increase bone density and help
  you lose weight by increasing your metabolism to help you burn more
  calories.</strong> Weight training can be done with our own body weight like
  performing squats, planks, and pushups or using free weights or barbells.
</p>
<p>
  When beginning weight or resistance training, choose a weight or resistance
  level heavy enough to tire your muscles after about 12 to 15 repetitions.
  When you can easily do more repetitions of a certain exercise, gradually
  increase the weight or resistance.
</p>
<p>
  Research shows that a single set of 12 to 15 repetitions with the proper
  weight can build muscle efficiently in most people and can be as effective
  as three sets of the same exercise. As long as you take the muscle you are
  working to fatigue — meaning you can't lift another repetition — you are
  doing the work necessary to make the muscle stronger. And fatiguing at a
  higher number of repetitions means you likely are using a lighter weight,
  which will make it easier for you to control and maintain correct form. To
  give your muscles time to recover, rest one full day between exercising
  each specific muscle group.
</p>
<p>
  <strong>Remember that weight loss does not happen overnight and will require time.
  Similarly, when starting a new exercise regimen start off slow and work
  your way up in time and intensity. Jumping into an intense exercise regimen
  can cause injury and will do more harm than good. Not everyday has to be as
  intense as the previous day, change the intensity of workouts throughout
  the week and take rest days.</strong>
</p>





</body>
</html>`

export default text
