import patientsApi from './api/patients'
import {handleError} from './api/errorHandler'

const GET_ALL_PATIENTS = "GET_ALL_PATIENTS";
const GET_PATIENT = "GET_PATIENT";
const GET_PATIENT_ACQ_IMAGE = "GET_PATIENT_ACQ_IMAGE"
const GET_PATIENT_FILES = "GET_PATIENT_FILES"
const GET_PATIENT_CUSTOM_ACTIVE = "GET_PATIENT_CUSTOM_ACTIVE"
const GET_UPLOAD_PROGRESS = "GET_UPLOAD_PROGRESS"

const initialState = {
    data: {},
    patient: {},
    location: {},
    acqimage: {},
    files: {},
    script: {},
    progress:0
};

export const actionCreators = {
    getPatients: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (!filter) filter = {}
            if (page) {
                filter.page = page
            } else {
                const previousPaging = getState().patients.data.paging
                filter.page = previousPaging? previousPaging.page: 1
            }
            let isClinician = getState().login.user.roles ? getState().login.user.roles.find(role => role.name === 'clinician') : false
            if (isClinician) {
                filter.providerId = getState().login.user.id
                filter.protocol_finished = false
                filter.status = "Active"
            }
            let response = await patientsApi.adminPatients(filter)
            let data = response.data
            dispatch({
                type: GET_ALL_PATIENTS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getPatient: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await patientsApi.adminPatient(id)
            let data = response.data
            dispatch({
                type: GET_PATIENT,
                payload: data.patient
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },

    getPatientACQIMage: (id,) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await patientsApi.adminPatientACQImage(id)
            let data = response.data
            dispatch({
                type: GET_PATIENT_ACQ_IMAGE,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getPatientFiles: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await patientsApi.adminPatientFiles(id)
            let data = response.data
            dispatch({
                type: GET_PATIENT_FILES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createPatient: (data) => async (dispatch, getState) => {
        let callback = async function () {
            return patientsApi.adminCreatePatient(data)
        };
        try {
            return await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updatePatient: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await patientsApi.adminUpdatePatient(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    uploadAudio: (data, id) => async (dispatch, getState) => {
        let callback = async function () {
            const formData = new FormData();
            let fileName = "audio_file"
            let date = new Date()

            let newFilename = fileName.replace(/^([^.]*)\.(.*)$/, '$1.' + date.getTime() + '.$2');
            formData.append('file', data)
            formData.append('name', decodeURI(newFilename))
            return await patientsApi.uploadAudio(id, formData,
                (progressEvent) =>{
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    dispatch({
                        type: GET_UPLOAD_PROGRESS,
                        payload: percentCompleted
                    });
                })
        };
        try {
            return await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },

    uploadAudioMedia: (data, id) => async (dispatch, getState) => {
        let callback = async function () {
            const formData = new FormData();
            let fileName = "audio_file"
            let date = new Date()

            let newFilename = fileName.replace(/^([^.]*)\.(.*)$/, '$1.' + date.getTime() + '.$2');
            formData.append('file', data)
            formData.append('name', decodeURI(newFilename))
            return await patientsApi.uploadAudioMedia(id, formData,
                (progressEvent) =>{
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    dispatch({
                        type: GET_UPLOAD_PROGRESS,
                        payload: percentCompleted
                    });
                })
        };
        try {
            return await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    removePatientFile: (patientId, fileId) => async (dispatch, getState) => {
        let callback = async function () {
            return await patientsApi.adminRemovePatientFile(patientId, fileId)
        };
        try {
            return await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    getCustomActiveHtml: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await patientsApi.getCustomActiveHtml(id)
            let data = response.data
            dispatch({
                type: GET_PATIENT_CUSTOM_ACTIVE,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_ALL_PATIENTS) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_PATIENT) {
        return {...state, patient: action.payload, acqimage: {}};
    }
    if (action.type === GET_PATIENT_ACQ_IMAGE) {
        return {...state, acqimage: action.payload};
    }
    if (action.type === GET_PATIENT_FILES) {
        return {...state, files: action.payload};
    }
    if (action.type === GET_PATIENT_CUSTOM_ACTIVE) {
        return {...state, script: action.payload};
    }
    if (action.type === GET_UPLOAD_PROGRESS) {
        return {...state, progress: action.payload};
    }

    return state;
};
