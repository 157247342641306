import loginApi from './api/login'

const LOGIN = 'LOGIN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const ME = 'ME';

let interval = {}
const initialState = {
    user: {
        authenticated: sessionStorage.getItem('token') ? true : false,
        email: sessionStorage.getItem('email'),
        auth:{},
        roles:[{
            name:'clinician'
        }]
    }
};

export const actionCreators = {
    login: user => async (dispatch, getState) => {
        try {
            let response = await loginApi.auth(user.email, user.password)
            let auth = response.data
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('expires', auth.expires);
            sessionStorage.setItem('email', user.email);
            let me = await loginApi.me()

            clearInterval(interval)
            interval = setInterval(async (meUser) => {
                let responseToken = await loginApi.refresh()
                let authToken = responseToken.data
                sessionStorage.setItem('token', authToken.token);
                sessionStorage.setItem('expires', authToken.expires);
                dispatch({
                    type: REFRESH_TOKEN,
                    payload: Object.assign({}, meUser, {email: sessionStorage.getItem('email'), auth: authToken, authenticated: authToken.token ? true : false})
                });
            }, 540000, me.data)
            dispatch({
                type: LOGIN,
                payload: Object.assign({}, {...me.data}, {email: sessionStorage.getItem('email'), auth, authenticated: auth.token ? true : false})
            });
            response.data.me = me.data
            return response
        } catch (e) {
            return e.response
        }
    },
    me: user => async (dispatch, getState) => {
        try {
            let me = await loginApi.me()
            dispatch({
                type: ME,
                payload: me.data
            });
        } catch (e) {
            return e.response
        }
    },
    sendPasswordReset: user => async (dispatch, getState) => {
        try {
            return await loginApi.sendPasswordReset(user.email)
        } catch (e) {
            return e.response
        }
    },
    resetPassword: user => async (dispatch, getState) => {
        try {
            return await loginApi.resetPassword(user.newPassword, user.newPasswordConfirmation, user.token)
        } catch (e) {
            return e.response
        }
    },
    updatePassword: user => async (dispatch, getState) => {
        try {
            return await loginApi.updatePassword(user)
        } catch (e) {
            return e.response
        }
    },
    inviteAdministrator: user => async (dispatch, getState) => {
        try {
            return await loginApi.inviteAdministrator(user)
        } catch (e) {
            return e.response
        }
    },
    unlockAccount: user => async (dispatch, getState) => {
        try {
            return await loginApi.unlockAccount(user.token)
        } catch (e) {
            return e.response
        }
    },
    resendUnlock: user => async (dispatch, getState) => {
        try {
            return await loginApi.resendUnlock(user.email)
        } catch (e) {
            return e.response
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === LOGIN) {
        return {...state, user: action.payload};
    }
    if (action.type === REFRESH_TOKEN) {
        return {...state, user: action.payload};
    }

    if (action.type === ME) {
        return {...state, user: {...state.user, ...action.payload}};
    }
    return state;
};
