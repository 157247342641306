import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as appActionCreators} from '../../store/Apps'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Button,
    Table,
    Pagination,
    TransitionablePortal,
} from 'semantic-ui-react'
import FilterApp from '../common/ui/FilterApp'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import moment from 'moment'
import AddAppModal from '../common/modal/AddAppModal'

class MasterApps extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newApp: {},
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterApps()
    }

    getTableData = props => {
        return props.apps.master.apps.filter(item => {
            return item.type.toLowerCase().includes(props.searchStore.text)
        }).sort((a, b) => a.order - b.order).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/app/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.type}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format('LLLL')}</Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getMasterApps(this.state.filter, data.activePage)
    }

    getFilterApp(filter) {
        this.setState({filter})
        this.props.getMasterApps(filter, this.state.page)
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.createMasterApp(data)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterApps(this.state.filter, this.state.page)
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Apps",
            }]}/>,
            <AccordionSegment key={1} header={'Apps'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}
                        >
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    <FilterApp filter={this.getFilterApp.bind(this)}/>
                    {this.props.apps.master.apps ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Created At</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={6}>
                                        <Pagination defaultActivePage={this.props.apps.master.paging.page}
                                                    totalPages={this.props.apps.master.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                        <AddAppModal
                                            submit={this.handleSubmit.bind(this)}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                        <Loading/>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, apps: state.apps}),
    dispatch => bindActionCreators({...appActionCreators}, dispatch)
)(MasterApps)
