import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as icsmedicationActionCreators} from '../../store/ICSMedications'
import {actionCreators as languageActionCreators} from '../../store/Languages'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    TransitionablePortal,
} from 'semantic-ui-react'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'
import 'jsoneditor-react/es/editor.min.css';
import 'brace/mode/json';
import 'brace/theme/github';
import AppBreadCrumb from "../common/ui/AppBreadCrumb";
import AppMessage from "../common/ui/AppMessage";

class MasterICSMedication  extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTopic: {},
        icsmedication: {},
        edit: false,
        loading: true,
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterICSMedication(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({icsmedication: nextProps.icsmedications.mastericsmedication, loading: !this.state.icsmedication})
    }

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})

    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSave() {
        this.setState({saving: 1})
        let icsmedication = {
            name: this.state.icsmedication.name,
            data: this.state.icsmedication.data
        }
        let errors = await this.props.updateMasterICSMedication(this.props.match.params.id, icsmedication)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterICSMedication(this.props.match.params.id)
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    handleCancel = () => {
        this.props.getMasterICSMedication(this.props.match.params.id)
        this.setState({edit: false})
    }

    handleChange = (e, target) => {
        if (this.state.edit) {
            let icsmedication = this.state.icsmedication
            icsmedication[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked ? 1 : 0 : e.target.value
            this.setState({icsmedication})
        }
    }

    handleJSONChange = (e, target) => {
        if (this.state.edit) {
            let icsmedication = this.state.icsmedication
            icsmedication['data'] = e
            this.setState({icsmedication})
        }
    }


    render() {
        let icsmedication = this.state.icsmedication
        let readOnly = !this.state.edit
        let createdAt = icsmedication.created_at ? icsmedication.created_at.substr(0, 16) : ""
        let updatedAt = icsmedication.updated_at ? icsmedication.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "ICS Medications",
                link: "/app/icsmedications"
            }, {
                text: "ICS Medication",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'ICS Medication'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}>
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    {this.state.loading ? <Loading/> :
                        <Form className={readOnly ? "readOnly" : "editing"}>
                            <GridFormInput
                                label="Type"
                                component={readOnly ? icsmedication.name : <Form.Input
                                    fluid
                                    placeholder='Type'
                                    readOnly={readOnly}
                                    value={icsmedication.name}
                                    name="name"
                                    onChange={this.handleChange.bind(this)}/>}
                            />
                            <GridFormInput
                                label="ID"
                                component={icsmedication.id}
                            />

                            <GridFormInput
                                label="Created"
                                component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Created By"
                                component={icsmedication.created_by
                                }
                            />
                            <GridFormInput
                                label="Updated By"
                                component={icsmedication.updated_by}
                            />
                            <br/>
                            {readOnly ? <Button color='teal' style={{float: "right"}}
                                                onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                [<Button key={0} color='teal' style={{float: "right"}}
                                         onClick={this.handleSave.bind(this)}>Save</Button>,
                                    <Button key={1} style={{float: "right"}}
                                            onClick={this.handleCancel.bind(this)}>Cancel</Button>]
                            }
                            <br/>
                            <br/>
                        </Form>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, icsmedications: state.icsmedications, languages: state.languages}),
    dispatch => bindActionCreators({...icsmedicationActionCreators, ...languageActionCreators}, dispatch)
)(MasterICSMedication)
