const text = `<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>

<br/>
<p><mark style="background-color: lightblue">1D. Eating a healthier diet</mark></p>
<div style="padding-left: 50px;">
  <p>
    <strong>You can ask some of the following:</strong>
  </p>
  <p>
    How would you describe your diet?
  </p>
  <p>
    What does a healthy diet look like to you?
  </p>
  <p>
    What did you have for breakfast?
  </p>
  <p>
    How many servings of fruits and vegetables do you have per day?
  </p>
</div>
</body>
</html>`

export default text
