import appsApi from './api/apps'
import {handleError} from './api/errorHandler'

const GET_DEVICE_APPS = "GET_DEVICE_APPS";
const GET_APP = "GET_APP";
const GET_APP_ACTIVITIES = "GET_APP_ACTIVITIES";
const GET_MASTER_APPS = "GET_MASTER_APPS"
const GET_MASTER_APP = "GET_MASTER_APP"

const initialState = {
    data: {},
    master: {},
    app: {},
    masterapp: {},
};
export const actionCreators = {
    getMasterApps: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await appsApi.adminMasterApps(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_APPS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterApp: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await appsApi.adminMasterApp(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_APP,
                payload: data.app
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterApp: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await appsApi.adminCreateMasterApp(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterApp: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await appsApi.adminUpdateMasterApp(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_DEVICE_APPS) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_MASTER_APPS) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_APP) {
        return {...state, app: action.payload, activities: null};
    }
    if (action.type === GET_APP_ACTIVITIES) {
        return {...state, activities: action.payload};
    }
    if (action.type === GET_MASTER_APP) {
        return {...state, masterapp: action.payload};
    }


    return state;
};
