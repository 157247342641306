import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as languageActionCreators} from '../../store/Languages'
import 'semantic-ui-css/semantic.min.css'
import {Link} from 'react-router-dom'
import {
    Segment,
    Form,
    Button,
    Header,
    Divider,
    TransitionablePortal,
    Icon
} from 'semantic-ui-react'
import AccordionSegment from '../common/ui/AccordionSegment'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import moment from 'moment'
import GridFormInput from '../common/ui/TableGridFormInput'
import {JsonEditor as Editor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

class MasterLanguage extends Component {
    state = {
        filter: {},
        page: 1,
        modalOpen: false,
        newTopic: {},
        language: {},
        edit: true,
        loading: true,
        saving: -1
    }

    componentWillMount() {
        this.props.getMasterLanguage(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({language: nextProps.languages.masterlanguage, loading: false})
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSave() {
        this.setState({saving: 1})
        let language = {
            data: this.state.language.data,
            language: this.state.language.language,
            object_id: this.state.language.objectId,
            type: this.state.language.type
        }
        let errors = await this.props.updateMasterLanguage(this.props.match.params.id, language)
        if (errors !== undefined) {
            this.setState({saving: 2, errors})
        } else {
            await this.props.getMasterLanguage(this.props.match.params.id)
            this.setState({edit: false, saving: 0})
            this.toggleMessage()
        }
    }

    handleEdit = () => {
        this.setState({edit: true})
    }

    handleCancel = () => {
        this.props.getMasterLanguage(this.props.match.params.id)
        this.setState({edit: false})
    }

    handleChange = (e, target) => {
        if (this.state.edit) {
            let language = this.state.language
            language[e.target.name] = e.target.name === "active" || e.target.name === "required" ? e.target.checked ? 1 : 0 : e.target.value
            this.setState({language})
        }
    }

    handleJSONChange = (e, target) => {
        if (this.state.edit) {
            let language = this.state.language
            language['data'] = e
            this.setState({language})
        }
    }

    render() {
        let language = this.state.language
        let readOnly = !this.state.edit
        let createdAt = language.created_at ? language.created_at.substr(0, 16) : ""
        let updatedAt = language.updated_at ? language.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Apps",
                link: "/app/apps"
            }, {
                text: "App",
                link: "/app/app/" + this.props.match.params.appid
            }, {
                text: "Language",
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Language Information'} component={
                <div>
                    <TransitionablePortal open={this.state.saving !== -1}>
                        <Segment
                            style={{left: '48%', position: 'fixed', top: '30px', zIndex: 1000, padding: 0}}>
                            <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                        </Segment>
                    </TransitionablePortal>
                    {this.state.loading ? <Loading/> :
                        <Form className={readOnly ? "readOnly" : "editing"}>
                            <Link style={{
                                position: 'absolute',
                                right: 0,
                                top: 3
                            }}
                                  to={"/app/app/" + this.props.match.params.appid}>
                                <Button
                                    icon
                                    floated="right"
                                    labelPosition='left'
                                    color='teal'
                                    size='small'
                                >
                                    <Icon name='angle left'/> Back to English Version
                                </Button>
                            </Link>
                            <GridFormInput
                                label="Language"
                                component={readOnly ? language.language : <Form.Select
                                    style={{width:'60%'}}
                                    fluid
                                    options={[
                                        {key: '0', text: 'Spanish', value: "es", type: "text"},
                                    ]}
                                    placeholder='Language'
                                    name="format"
                                    readOnly
                                    value={language.language}
                                    onChange={this.handleChange.bind(this)}
                                />}
                            />
                            <GridFormInput
                                label="Object ID"
                                component={language.objectId}
                            />
                            <GridFormInput
                                label="Type"
                                component={language.type}
                            />
                            <GridFormInput
                                label="ID"
                                component={language.id}
                            />

                            <GridFormInput
                                label="Created"
                                component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Updated"
                                component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                            />
                            <GridFormInput
                                label="Created By"
                                component={language.created_by
                                }
                            />
                            <GridFormInput
                                label="Updated By"
                                component={language.updated_by}
                            />
                            <Divider horizontal style={{marginBottom: 5}}>
                                <Header as='h4'>
                                    Data
                                </Header>
                            </Divider>

                            {readOnly ? <Editor
                                style={{height: 500}}
                                value={language.data}
                                search={false}
                                mode={'view'}
                                enableSort={false}
                                enableTransform={false}
                                history={false}
                                ace={ace}
                                onChange={this.handleJSONChange}
                            /> : null}
                            {readOnly ?
                                null
                                :
                                <Editor
                                    style={{height: 500}}
                                    value={language.data}
                                    search={false}
                                    mode={'code'}
                                    enableSort={false}
                                    enableTransform={false}
                                    history={false}
                                    ace={ace}
                                    onChange={this.handleJSONChange}
                                />
                            }
                            <br/>
                            {readOnly ? <Button color='teal' style={{float: "right"}}
                                                onClick={this.handleEdit.bind(this)}>Edit</Button> :
                                [<Button key={0} color='teal' style={{float: "right"}}
                                         onClick={this.handleSave.bind(this)}>Save</Button>,
                                    <Button key={1} style={{float: "right"}}
                                            onClick={this.handleCancel.bind(this)}>Cancel</Button>]
                            }
                            <br/>
                            <br/>
                        </Form>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, languages: state.languages}),
    dispatch => bindActionCreators({...languageActionCreators}, dispatch)
)(MasterLanguage)
