import React, {Component} from 'react'
import 'semantic-ui-css/semantic.min.css'
import {
    Header,
    Button,
    Icon,
    Modal,
    Pagination,
    Input,
    Popup
} from 'semantic-ui-react'

export default class ViewReadinessModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalOpen: false,
        }
    }

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})

    render() {
        return (
            <Modal
                dimmer={"blurring"}
                trigger={<Button
                    icon labelPosition={"right"}
                    color='teal'
                    onClick={this.handleOpen.bind(this)}
                ><Icon name='eye'/>View Readiness
                </Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose.bind(this)}
                size='tiny'
                style={{height: "fit-content", margin: "auto"}}
            >
                <Header content={[<p key={1}>Edit Readiness</p>, (
                    this.props.name ?
                        <p key={2} style={{fontSize: 15, marginLeft: 20}}>{this.props.name}</p> : null)]}/>
                <Modal.Content style={{textAlign:"center"}}>
                    <Input
                        min={1}
                        max={10}
                        onChange={(e, data)=>{this.props.handleUpdateReadiness(e, {activePage: data.value})}}
                        type='range'
                        value={this.props.patient.readiness}
                        style={{width:458}}
                    />
                    <Pagination
                        activePage={this.props.patient.readiness || 0}
                        onPageChange={this.props.handleUpdateReadiness}
                        boundaryRange={10}
                        size='large'
                        totalPages={10}
                        firstItem={null}
                        lastItem={null}
                        prevItem={null}
                        nextItem={null}
                        pageItem={(Item, {value, ...props}) => {
                            let content = value
                            let style = props.active ? {background: "#00b5ad", color: "white"} : {}
                            let component = value === 1 ? <Popup
                                content='Not Ready'
                                open
                                position='bottom left'
                                trigger={<Item value={value} {...props} content={content} style={style}/>}
                            /> :  value === 10 ? <Popup
                                content='Very Ready'
                                open
                                position='bottom right'
                                trigger={<Item value={value} {...props} content={content} style={style}/>}
                            /> :  value === 5 ? <Popup
                                content='Somewhat Ready'
                                open
                                position='bottom center'
                                trigger={<Item value={value} {...props} content={content} style={style}/>}
                            /> : <Item value={value} {...props} content={content} style={style}/>
                            return component
                        }}
                    />
                    <br/>
                    <br/>
                    <br/>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}
