import {client} from './apiClient'


const adminMasterICSMedications = async (filter) => {
    if (!filter) filter = {}
    return await client.request({
        url: '/admin/ics_medications',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterICSMedication = async (id) => {
    return await client.request({
        url: '/admin/ics_medication/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterICSMedication = async (data) => {
    return await client.request({
        url: '/admin/ics_medication',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterICSMedication = async (id, data) => {
    return await client.request({
        url: '/admin/ics_medication/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminMasterICSMedications,
    adminMasterICSMedication,
    adminCreateMasterICSMedication,
    adminUpdateMasterICSMedication
}
