import {client} from './apiClient'


const adminMasterSites = async (filter) => {
    if (!filter) filter = {}
    return await client.request({
        url: '/admin/sites',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: filter
    })
}

const adminMasterSite = async (id) => {
    return await client.request({
        url: '/admin/site/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminCreateMasterSite = async (data) => {
    return await client.request({
        url: '/admin/site',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdateMasterSite = async (id, data) => {
    return await client.request({
        url: '/admin/site/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminMasterSites,
    adminMasterSite,
    adminCreateMasterSite,
    adminUpdateMasterSite
}
