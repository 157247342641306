const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>

        <p>
            <strong>
                <mark>Note to clinician: This first section should take 3 minutes of conversation</mark><mark style="color:#db2828">Pick some of the following to discuss.</mark>
            </strong>
        </p>
<p>
  <strong><u>2. Counsel patient: </u></strong>
  <strong>
    Describe benefits of diet, exercise, weight loss/gain and healthy
    eating.
  </strong>
</p>

<p><mark style="background-color: lightblue">2B. Lose weight by reducing calories</mark></p>

<p>
  <strong>Work backwards from the patient’s goal using the minimum information
  necessary to cover the most essential learning topics that will enable and motivate them to reduce or maintain weight and eat
  better</strong>
</p>
<p>
  <strong>Eating healthier does not mean you have to make big changes to how you and
  your family eat. You can choose to eat healthier and still enjoy the foods
  you love, occasionally. Making small changes over time can have a big
  impact on your health.</strong>
</p>
<p>
  Some beverages, like bottled teas, sodas, or fruit juices have lots of
  added sugar we may not be aware of. <strong>If you are drinking beverages with
  added sugar, try to substitute some of those drinks with water.</strong> Just
  replacing one beverage a day is a good start.
</p>
<p>
  <strong>There is no “right” way to eat healthy.</strong> Eating healthy can be simple if you
  keep a few things in mind. <strong>Try to eat more whole foods.</strong> When you go to a
  grocery store, the best foods with the most nutritional benefit are against
  the walls. Usually that is where you will find fresh vegetables and fruits.
  <strong>Replace fruit juice with whole fruits. Eat a variety of vegetables of all
  different colors: dark green leafy vegetables (like spinach and collard
  greens), red vegetables (like bell peppers and tomatoes), orange vegetables
  (like carrots and squash). You can get healthy sources of protein from
  seafood (like salmon or tuna), chicken, lean turkey, tofu, legumes (like
  split peas, lentils, beans), eggs, and nuts.</strong>
</p>
<p>
  <strong>When you cook, use oils that come from plants like canola, corn, olive oil,
  or avocado oil. Limit how much salt you add to your food when cooking, some
  seasonings have added salt. Sodium is another name for salt. Nutrition
  labels can be found on the back of packaged foods. Sodium will be listed on
  a nutrition label to tell you how much salt is in the food or seasoning
  bottle.</strong> Choose packaged foods that have 140 mg or less of sodium per
  serving. <strong>You may also see saturated and trans fats on a nutrition label,
  look for foods with 0 grams of trans fats and very low saturated fats.
  These kinds of fats increase levels of bad cholesterol in the blood.
  Overtime this can cause reduced blood flow to your heart and your heart
  will not be able to pump oxygen into the rest of your body.</strong>
</p>
<p>
  Another step you can take to lose weight by changing your eating habits is
  to create an eating schedule and plan your meals. It is a lot easier to eat
  healthier and not overeat when you do not need to make a choice at every
  meal. If you have a plan for what you’ll eat during the week or the during
  the day, you can avoid making an unhealthy choice when you are very hungry.
</p>
<p>
  One way to reduce the amount of added sugar you consume is to replace soda
  and energy drinks with water or unsweetened carbonated beverages. If you
  enjoy desserts, start out with fruit then move on to the dessert of your
  choice, you will likely eat less of the sugar-filled dessert.
</p>
<p>
  <strong>Pay attention to how you feel after eating certain types of food. Compare
  how you feel after snacking on a processed food versus fruit or nuts, or
  how you feel after eating a fast food meal versus a home cooked meal.</strong> While
  the processed foods may be appealing in the moment, a good way to determine
  if something is healthy, is how you feel an hour later. <strong>Do you feel sleepy
  or full of energy? Are you hungry again or full?</strong>
</p>





</body>
</html>`

export default text
