import topicsApi from './api/topics'
import {handleError} from './api/errorHandler'

const GET_CURRICULUM_TOPICS = "GET_CURRICULUM_TOPICS";
const GET_TOPIC = "GET_TOPIC";
const GET_MASTER_TOPICS = "GET_MASTER_TOPICS";
const GET_MASTER_TOPIC = "GET_MASTER_TOPIC";

const initialState = {
    data: {},
    topic: {},
    master: {},
    mastertopic: {}
};

export const actionCreators = {
    getCurriculumTopics: (deviceId, curriculumId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await topicsApi.adminCurriculumTopics(filter, deviceId, curriculumId)
            let data = response.data
            dispatch({
                type: GET_CURRICULUM_TOPICS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getCurriculumTopic: (deviceId, curriculumId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await topicsApi.adminCurriculumTopic(deviceId, curriculumId, id)
            let data = response.data
            dispatch({
                type: GET_TOPIC,
                payload: data.topic
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterTopics: (curriculumId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await topicsApi.adminMasterTopics(filter, curriculumId)
            let data = response.data
            dispatch({
                type: GET_MASTER_TOPICS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterTopic: (curriculumId, id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await topicsApi.adminMasterTopic(curriculumId, id)
            let data = response.data
            dispatch({
                type: GET_MASTER_TOPIC,
                payload: data.topic
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterTopic: (curriculumId, newItem) => async (dispatch, getState) => {
        let callback = async function () {
            await topicsApi.adminCreateMasterTopic(curriculumId, newItem)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterTopic: (curriculumId, id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await topicsApi.adminUpdateMasterTopic(curriculumId, id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_CURRICULUM_TOPICS) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_TOPIC) {
        return {...state, topic: action.payload};
    }

    if (action.type === GET_MASTER_TOPICS) {
        return {...state, master: action.payload};
    }

    if (action.type === GET_MASTER_TOPIC) {
        return {...state, mastertopic: action.payload};
    }


    return state;
};
