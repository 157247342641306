import React, {Component} from "react";
import {
    Table,
    Button,
    Segment,
    Dimmer,
    Loader,
} from "semantic-ui-react";

import {Link} from 'react-router-dom'
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../store/Devices";
import {connect} from "react-redux";
import moment from 'moment'

class SimpleDeviceList extends Component {

    state = {
        loading: true
    }
    getTableData = props => {
        return props.devices.data.devices.map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/device/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
            </Table.Row>);
    };

    componentWillUnmount() {
        this.setState({loading: true})
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: false})
    }

    componentWillMount() {
        let date = moment(this.props.date).format("YYYY-MM-DD")
        this.props.getDevicesByDate(date)
    }

    render() {
        return (
            <div>
                {
                    this.state.loading ?
                        <Table style={{marginTop: 30, marginBottom:30}} basic='very' compact>
                            <Table.Body>
                                <Segment style={{
                                    background: '#f3937e77',
                                    marginBottom: 10,
                                    position: 'absolute',
                                    width: '97%',
                                    marginRight: -100,
                                    marginTop: -20,
                                }}>
                                    <Dimmer active inverted>
                                        <Loader size='mini'></Loader>
                                    </Dimmer>
                                </Segment>
                            </Table.Body>
                        </Table>
                        :
                        <Table style={{margin: 20}} basic='very' compact>
                            <Table.Body>{this.getTableData(this.props)}</Table.Body>
                        </Table>
                }
            </div>
        )
    }
}

export default connect(
    state => {
        const {searchStore, devices} = state;
        return {searchStore, devices}

    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SimpleDeviceList);
