const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>

<p>
  <strong>
    <mark>Note to clinician: This final (third) section should take 3 minutes of conversation</mark>
  </strong>
</p>

<p>
  <strong>3. </strong>
  <strong><u>Plan for goal attainment</u>.</strong>
  <strong>Explore barriers and facilitators to goal attainment.</strong>
</p>
<p><mark style="background-color: lightblue">3E. Exercising to stay healthy</mark></p>

<p>
  Like I said in the beginning, you can make these changes slowly and over
  time. The key is to begin and to remain committed
</p>
<p>
  <strong>Do you have any concerns or questions about what I just talked about?</strong>
</p>
<p>
  <strong>Are there any changes you feel may be hard to incorporate in your daily
  routine?</strong>
</p>
<p>
  <strong>Potential barriers to address:</strong>
</p>
<p>
    <u>I am tired when I get home:</u> You can schedule physical activity for times during the day that you feel energetic.
</p>
<p>
  <u>Getting started for the first time:</u> Start slowly and see how you feel. Gradually increase how long and how often you are active..
</p>
<p>
  <u>Too many things to do:</u> Exercise can be performed during your daily activities. For example, you can take the stairs instead of an elevator or get off one subway stop early and walk the rest of the way. Some other everyday activities that can help you get exercise are pushing a stroller or doing housework and laundry.
</p>
<p>
  <u>Concerns around pain:</u> You may feel some minor discomfort or muscle soreness when you first become active. These feelings should go away as you get used to your activity. Go easier and then slowly build up your activity level. Some activities, such as walking are less likely to cause injuries. Shortness of breath is normal, take a minute to catch your breath and continue for as long as you can.
</p>
<p>
  <u>I don’t have anyone to go with me:</u> Join a group such as a class at the local gym or rec center.
</p>
<p>
  <strong>Facilitators to mention:</strong>
</p>
<p>
  <u>Role of family members and friends: </u> Encourage family and friends to join you in exercising. Set weekly goals for how many days in the week you want to exercise to reach.
</p>


</body>
</html>`

export default text
