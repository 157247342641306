const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>


        <p>
            <strong>
                <mark>Note to clinician: This first section should take 3 minutes of conversation</mark><mark style="color:#db2828">Pick some of the following to discuss.</mark>
            </strong>
        </p>
<p>
  <strong><u>2. Counsel patient: </u></strong>
  <strong>
    Describe benefits of diet, exercise, weight loss/gain and healthy
    eating.
  </strong>
</p>

<p><mark style="background-color: lightblue">2D. Eating a healthier diet</mark></p>

<p>
  <strong>Work backwards from the patient’s goal using the minimum information necessary to cover the most 
  essential learning topics that will enable and motivate them to maintain weight and eat better.</strong>
</p>
<p>
  <strong>There are many different ways to approach healthy eating. An easy way to start making changes to your 
  diet is to go through all five groups of food: grains, fruits, vegetables, protein, and dairy.</strong>
</p>
<p>
  <strong>When choosing a grain like bread, pasta, or rice, choose products that list
  whole grains as their first ingredient,</strong> like whole grain bread, brown or
  wild rice. <strong>Whole grains will help you feel full longer.</strong>
</p>
<p>
  <strong>Fruits and vegetables provide flavor and variety in your diet, they also
  contain necessary fiber, vitamins, and minerals.</strong> Season vegetables with
  healthy oils and herbs, avoid butter or mayonnaise.
</p>
<p>
  <strong>For protein, select low-fat, lean cuts of meat. Lean beef will be sold
  under words like “loin” or “round” or “leg”. Trim off excess fat before
  cooking your meat.</strong> Chicken breast are low fat and high in protein, and lots
  of seafood is high in healthy fat. Baking, grilling, and roasting are the
  healthiest ways to prepare your meats. Tofu and legumes (beans) are good
  sources of non-meat proteins that are low in fat and cholesterol-free.
</p>
<p>
  <strong>If you eat dairy, choose skim or non-dairy milk like soy, almond or oat
  milk. Nonfat or Greek yogurt are good alternatives to sour cream.</strong>
</p>
<p>
  <strong>An easy way to start, is to eat meals prepared at home as much as possible.
  When cooking at home, focus on meals made from a combination of unprocessed
  foods, these are foods that don’t come packaged, such as vegetables, beans,
  whole grains and fruit.</strong>
</p>
<p>
  It is important to note that restaurant and takeout foods are almost always
  higher in calories and added salt compared to making the same food at home.
  That is because at home, you have more control and most people will eat
  much smaller portions with healthier ingredients.
</p>
<p>
  <strong>Another step you can take to eating healthier is to create an eating
  schedule and plan your meals.</strong> It is a lot easier to eat healthier when you
  do not need to make a choice at every meal. If you have a plan for what
  you’ll eat during the week or the during the day, you can avoid making an
  unhealthy choice when you are very hungry.
</p>
<p>
  One way to reduce the amount of added sugar you consume is to replace soda
  and energy drinks with water or unsweetened carbonated beverages. If you
  enjoy desserts, start out with fruit then move on to the dessert of your
  choice, you will likely eat less of the sugar-filled dessert.
</p>
<p>
  <strong>Pay attention to how you feel after eating certain types of food. Compare
  how you feel after snacking on a processed food versus fruit or nuts, or
  how you feel after eating a fast food meal versus a home cooked meal. While
  the processed foods may be appealing in the moment, a good way to determine
  if something is healthy, is how you feel an hour later. Do you feel sleepy
  or full of energy? Are you hungry again or full?</strong>
</p>





</body>
</html>`

export default text
