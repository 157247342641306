const text = `<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
<br/>
<p><mark style="background-color: lightblue">1E. Exercising to stay healthy</mark></p>
<div style="padding-left: 25px;">
    <div style="padding-left: 25px;">
    <p>
        <strong>You can ask some of the following:</strong>
      </p>
      <p>
        What do you do for exercise?
      </p>
      <p>
        How many days a week do you go for a walk or exercise?
      </p>
      <p>
        How long do you exercise for?
      </p>
      <p>
        What kinds of exercise activities do you enjoy?
      </p>
      <p>
        What would a healthy exercise program look like to you?
      </p>
      <p>
        What would you like to change with your exercise routine?
      </p>
  </div>
</div>

</body>
</html>`

export default text
