import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actionCreators as deviceActionCreators} from '../../store/Devices'
import {actionCreators as curriculumActionCreators} from '../../store/Curriculums'
import 'semantic-ui-css/semantic.min.css'
import {
    Segment,
    Form,
    Button,
    Grid,
    Icon
} from 'semantic-ui-react'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'
import moment from 'moment'
import GridFormInput from '../common/ui/CompactTableGridFormInput'
import MapWithAMarker from '../common/ui/MapWithAMarker'

class Device extends Component {
    state = {
        filter: {},
        page: 1,
        loading: true,
    }

    componentWillMount() {
        this.props.getDevice(this.props.match.params.id)
        this.props.getDeviceLocation(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: !nextProps.devices.device})
    }

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getDeviceCurriculums(this.props.match.params.id, this.state.filter, data.activePage)
    }

    downloadCSV() {
        console.log(this.props.devices.device)

        let a = document.createElement('a');
        a.href = this.props.devices.device.summary.raw_device_csv_url;
        a.target = "_blank"
        a.click();
    }

    downloadJSON() {
        let a = document.createElement('a');
        a.href = this.props.devices.device.summary.raw_device_json_url;
        a.target = "_blank"
        a.click();
    }

    render() {
        let device = this.props.devices.device
        let location = this.props.devices.location
        let createdAt = device.created_at ? device.created_at.substr(0, 16) : ""
        let updatedAt = device.updated_at ? device.updated_at.substr(0, 16) : ""

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Devices",
                link: "/app/devices"
            }, {
                text: "Device"
            }]}/>,
            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Device Information'} component={
                <div>
                    {this.state.loading ? <Loading/> :
                        <Form>
                            <Grid columns='equal' style={{marginTop: 0}}>
                                <Grid.Column>
                                    <GridFormInput
                                        label="ID"
                                        component={device.id}
                                    />
                                    <GridFormInput
                                        label="OS"
                                        component={device.os}
                                    />
                                    <GridFormInput
                                        label="Type"
                                        component={device.type}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <GridFormInput
                                        label="Created"
                                        component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                                    />
                                    <GridFormInput
                                        label="Updated"
                                        component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                                    />
                                </Grid.Column>
                            </Grid>
                            <br/>
                            {device.summary && device.summary.raw_device_csv_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadCSV.bind(this)}>Download CSV<Icon
                                    name='download'/></Button> : null}
                            {device.summary && device.summary.raw_device_json_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadJSON.bind(this)}>Download
                                    JSON<Icon name='download'/></Button> : null}
                        </Form>}
                </div>}/>,
            location.ipAddress ?
                <AccordionSegment backgroundColor={'#f7f6a130'} key={2} header={'Location'} component={
                    <div>
                        {this.state.loading ? <Loading/> :
                            <Form>
                                <Grid columns='equal' style={{marginTop: 0}}>
                                    <Grid.Column width={6} style={{paddingRight: 0}}>
                                        {
                                            location.ipAddress ?
                                                < GridFormInput
                                                    label="IP Address"
                                                    component={location.ipAddress}
                                                /> : null}
                                        {
                                            location.ipAddressProcessed === 1 ? <div>
                                                <GridFormInput
                                                    label="Country"
                                                    component={location.country}
                                                />
                                                <GridFormInput
                                                    label="Region"
                                                    component={location.region}
                                                />
                                                <GridFormInput
                                                    label="City"
                                                    component={location.city}
                                                />
                                                <GridFormInput
                                                    label="Timezone"
                                                    component={location.timezone}
                                                />
                                                <GridFormInput
                                                    label="Latitude"
                                                    component={location.latitude}
                                                />
                                                <GridFormInput
                                                    label="Longitude"
                                                    component={location.longitude}
                                                />
                                                <GridFormInput
                                                    label="Area"
                                                    component={location.area}
                                                />
                                                <GridFormInput
                                                    label="Metro"
                                                    component={location.metro}
                                                /></div> : null
                                        }
                                    </Grid.Column>
                                    <Grid.Column>
                                        {
                                            location.longitude && location.longitude ?
                                                <Segment size={'mini'}>
                                                    <MapWithAMarker
                                                        location={location}
                                                        googleMapURL={window.CONFIG.googleMapURL}
                                                        loadingElement={<div style={{height: `100%`}}/>}
                                                        containerElement={<div style={{height: `320px`}}/>}
                                                        mapElement={<div style={{height: `100%`}}/>}
                                                    />
                                                </Segment> : null}
                                    </Grid.Column>
                                </Grid>

                            </Form>}
                    </div>}/> : null
        ]
    }
}

export default connect(
    state => ({searchStore: state.searchStore, devices: state.devices, curriculums: state.curriculums}),
    dispatch => bindActionCreators({...deviceActionCreators, ...curriculumActionCreators}, dispatch)
)(Device)
