import React from 'react';
import {Segment, Dimmer, Loader, Placeholder} from "semantic-ui-react";

const Loading = props => {
    let defaultStyle = {marginTop: 20}
    if (props.style) {
        defaultStyle = props.style
    }
    return (
        <Segment style={defaultStyle}>
            <Dimmer active inverted>
                <Loader>Loading</Loader>
            </Dimmer>
            <Placeholder>
                <Placeholder.Header image>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                </Placeholder.Header>
                <Placeholder.Paragraph>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                    <Placeholder.Line/>
                </Placeholder.Paragraph>
            </Placeholder>
        </Segment>
    );
}

export default Loading;
