const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>


        <p>
            <strong>
                <mark>Note to clinician: This first section should take 3 minutes of conversation</mark>
            </strong>
        </p>

<p>
  <strong>3. </strong>
  <strong><u>Plan for goal attainment</u>.</strong>
  <strong>Explore barriers and facilitators to goal attainment.</strong>
</p>

<p><mark style="background-color: lightblue">3D. Eating a healthier diet</mark></p>

<p>
  Like I said in the beginning, you can make these changes slowly and over
  time. The key is to begin and to remain committed
</p>
<p>
  <strong>Do you have any concerns or questions about what I just talked about?</strong>
</p>
<p>
  <strong>Are there any changes you feel may be hard to incorporate in your daily
  routine?</strong>
</p>
<p>
  <strong>Potential Barriers to address:</strong>
</p>
<p>
  <u>Can’t afford healthy foods:</u> Healthy eating can be achieved by everyone. If
  fresh vegetables are too expensive, try to get frozen or canned fruits and
  vegetables—just make sure you check the sodium content and make sure there
  is no added sugar.
</p>
<p>
  <u>No time to cook:</u> Start with cooking one healthy dinner a week or try to
  cook meals on the weekend that you can heat up for lunch and dinner during
  the week.
</p>
<p>
  <strong>Facilitators to mention:</strong>
</p>
<p>
  <u>Role of family members:</u> Encourage family and household members to shop
  healthier. Everyone can choose their favorite vegetables and you all can
  work together to cook a meal everyone will enjoy.
</p>



</body>
</html>`

export default text
