const text = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>

<p>
  <strong>
    <mark>Note to clinician: This final (third) section should take 3 minutes of conversation</mark>
  </strong>
</p>

<p>
  <strong>3. </strong>
  <strong><u>Plan for goal attainment</u>.</strong>
  <strong>Explore barriers and facilitators to goal attainment.</strong>
</p>
<p><mark style="background-color: lightblue">3A. Lose weight through exercise</mark></p>

<p>
  Like I said in the beginning, you can make these changes slowly and over
  time. The key is to begin and to remain committed
</p>
<p>
  <strong>Do you have any concerns or questions about what I just talked about?</strong>
</p>
<p>
  <strong>Are there any changes you feel may be hard to incorporate in your daily
  routine?</strong>
</p>
<p>
  <strong>Potential barriers to address:</strong>
</p>
<p>
  <u>Concerns around pain during exercise:</u> You may feel some minor discomfort or
  muscle soreness when you first become active. These feelings should go away
  as you get used to your activity. However, if you feel sick to your stomach
  or have pain, you may have done too much. Go easier and then slowly build
  up your activity level. Some activities, such as walking or biking on a
  stationary bike are less likely to cause injuries. Shortness of breath from
  exertion is normal, take a minute to catch your breath and continue for as
  long as you can.
</p>
<p>
  <u>Getting started for the first time:</u> If you have been inactive, start slowly
  and see how you feel. Gradually increase how long and how often you are
  active. If you don’t feel right, stop your activity. If you feel pain,
  tightness, or pressure in your chest or neck, shoulder, or arm, extreme
  shortness of breath, dizziness or sickness stop right away.
</p>
<p>
  <u>No time to exercise:</u> Get off the bus from work or the train a few stops
  earlier and walk the rest of the way home. Take the stairs a few flights
  instead of the elevator. If you can, try to walk around for a few minutes a
  couple times a day while at work.
</p>
<p>
  <strong>Facilitators to mention:</strong>
</p>
<p>
  <u>Role of family members:</u> Encourage family and household members to join you
  in exercising. Set monthly goals for how many days in the month you want to
  exercise or miles/steps you want to reach to track your progress.
</p>


</body>
</html>`

export default text
