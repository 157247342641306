import React, {Component} from "react";
import {
    Header,
    Segment,
    Table,
    Image,
    Popup,
} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Images";
import {connect} from "react-redux";
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AppMessage from '../common/ui/AppMessage'
import AddImageModal from '../common/modal/AddImageModal'

class Images extends Component {
    state = {
        filter: {},
        edit: false,
        loading: true,
        saving: -1
    }

    componentWillMount() {
        this.props.getImages()
    }

    componentWillReceiveProps() {
    }

    getTableData = props => {
        return props.images.data.images.filter(item => {
            return item.imageName.toLowerCase().includes(props.searchStore.text)
        }).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell><Popup trigger={<Image src={item.imageSignedUrl} size='mini'/>} position='right center'
                                   flowing hoverable>
                    <Image src={item.imageSignedUrl} size='medium'/></Popup></Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell><a href={item.imageSignedUrl} rel="noopener noreferrer" target={"_blank"}>{item.imageSignedUrl}</a></Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getImages(this.state.filter, data.activePage)
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async handleSubmit(data, callback) {
        this.setState({saving: 1})
        let errors = await this.props.uploadImage(data)
        if (errors !== undefined) {
            console.log(errors)
            this.setState({saving: 2, errors})
        } else {
            await this.props.getImages()
            this.setState({saving: 0})
            this.toggleMessage()
            callback()
        }
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Images",
            }]}/>,
            <Segment key={1}>
                <AppMessage saving={this.state.saving} errors={this.state.errors}/>
                <Header>Images</Header>
                <AddImageModal name={""}
                               submit={this.handleSubmit.bind(this)}/>
                <br/>
                {
                    this.props.images.data.images ?
                        <Table compact celled>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>Preview</Table.HeaderCell>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>URL</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.getTableData(this.props)}</Table.Body>
                            {/*<Table.Footer fullWidth>*/}
                            {/*<Table.Row>*/}
                            {/*<Table.HeaderCell colSpan={5}>*/}
                            {/*<Pagination defaultActivePage={this.props.images.data.paging.page}*/}
                            {/*totalPages={this.props.images.data.paging.pages}*/}
                            {/*onPageChange={this.onPageChange.bind(this)}*/}
                            {/*size='mini'/>*/}
                            {/*</Table.HeaderCell>*/}
                            {/*</Table.Row>*/}
                            {/*</Table.Footer>*/}
                        </Table> :
                        <Loading/>
                }
            </Segment>
        ];
    }
}


export default connect(
    state => {
        const {searchStore, images} = state;
        return {searchStore, images}

    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Images);
