import React from 'react';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle
} from "react-google-maps";

const MapWithAMarker = withScriptjs(withGoogleMap(props => {

        let zoom = 11
        if (props.location.area >= 100) {
            zoom = 7
        } else if (props.location.area >= 50) {
            zoom = 8
        } else if (props.location.area >= 20) {
            zoom = 9
        } else if (props.location.area >= 10) {
            zoom = 10
        } else if (props.location.area >= 5) {
            zoom = 11
        }
        return <GoogleMap
            defaultZoom={zoom}
            defaultCenter={{lat: props.location.latitude, lng: props.location.longitude}}
            options={{scaleControl:true}}
        >

            <Circle
                position={{lat: props.location.latitude, lng: props.location.longitude}}
                defaultCenter={{lat: props.location.latitude, lng: props.location.longitude}}
                radius={props.location.area * 1000}
                options={{
                    strokeColor: 'rgba(111, 111, 111, 1)',
                    fillColor: 'rgba(111, 111, 111, 1)'
                }}
            />
            <Marker
                position={{lat: props.location.latitude, lng: props.location.longitude}}
            />
        </GoogleMap>
    }
));

export default MapWithAMarker
