import React, {Component} from "react";
import {
    Button,
    Table,
    Pagination,
} from "semantic-ui-react";
import {Link} from 'react-router-dom'
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Devices";
import {connect} from "react-redux";
import FilterDevice from '../common/ui/FilterDevice'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import AccordionSegment from '../common/ui/AccordionSegment'

class Devices extends Component {
    state = {
        filter: {},
        page: 1
    }

    componentWillMount() {
        this.props.getDevices()
    }

    componentWillReceiveProps() {
    }

    getTableData = props => {
        return props.devices.data.devices.filter(item => {
            return item.type.toLowerCase().includes(props.searchStore.text)
        }).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link to={"/app/device/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.type}</Table.Cell>
                <Table.Cell>{item.os}</Table.Cell>
            </Table.Row>);
    };

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getDevices(this.state.filter, data.activePage)
    }

    getFilterDevice(filter) {
        this.setState({filter})
        this.props.getDevices(filter, this.state.page)
    }

    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Devices",
            }]}/>,
            <AccordionSegment key={1} header={'Devices'} component={
                <div>
                    <FilterDevice filter={this.getFilterDevice.bind(this)}/>
                    {
                        this.props.devices.data.devices ?
                            <Table compact celled definition>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell/>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>OS</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{this.getTableData(this.props)}</Table.Body>
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={5}>
                                            <Pagination defaultActivePage={this.props.devices.data.paging.page}
                                                        totalPages={this.props.devices.data.paging.pages}
                                                        onPageChange={this.onPageChange.bind(this)}
                                                        size='mini'/>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table> :
                            <Loading/>
                    }
                </div>}/>
        ];
    }
}


export default connect(
    state => {
        const {searchStore, devices} = state;
        return {searchStore, devices}

    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Devices);
