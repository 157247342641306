import {client} from './apiClient'

const adminPatients = async (filter) => {
    return await client.request({
        url: '/admin/patients',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
        params: {...filter, limit: 10}
    })
}

const adminPatientACQImage = async (id) => {
    return await client.request({
        url: '/admin/patient/' + id + '/getacqimage',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminPatientFiles = async (id) => {
    return await client.request({
        url: '/admin/patient/' + id + '/files',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const adminPatient = async (id) => {
    return await client.request({
        url: '/admin/patient/' + id,
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminCreatePatient = async (data) => {
    return await client.request({
        url: '/admin/patient',
        method: "POST",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
const adminUpdatePatient = async (id, data) => {
    return await client.request({
        url: '/admin/patient/' + id,
        method: "PUT",
        data: data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}

const uploadAudio = async (id, data, onUploadProgress) => {
    return await client.request({
        url: '/admin/patient/' + id + '/file',
        method: "POST",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token'),
        },
        onUploadProgress
    })
}
const uploadAudioMedia = async (id, data, onUploadProgress) => {
    return await client.request({
        url: '/admin/patient/' + id + '/media',
        method: "POST",
        data,
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token'),
        },
        onUploadProgress
    })
}
const adminRemovePatientFile = async (id, fileId) => {
    return await client.request({
        url: '/admin/patient/' + id + '/file/' + fileId,
        method: "DELETE",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token'),
        },
    })
}

const getCustomActiveHtml = async (id) => {
    return await client.request({
        url: '/admin/patient/' + id + '/customactivehtml',
        method: "GET",
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        },
    })
}
export default {
    adminPatients,
    adminPatient,
    adminCreatePatient,
    adminUpdatePatient,
    adminPatientACQImage,
    adminPatientFiles,
    uploadAudio,
    uploadAudioMedia,
    adminRemovePatientFile,
    getCustomActiveHtml
}
