import React, {Component} from 'react'
import {
    Icon,
    Image,
    Menu,
} from 'semantic-ui-react'
import {actionCreators as sideAction} from "../../store/SideMenu";
import {actionCreators as searchAction} from "../../store/SearchStore";
import {actionCreators as loginAction} from "../../store/Login";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {history} from '../../index'

class TopMenu extends Component {
    state = {};

    async componentWillMount() {
        await this.props.me()
    }

    render() {
        let isAdmin = this.props.user.roles ? this.props.user.roles.find(role => role.name === 'admin') : false
        return (
            <Menu fixed="top" className="top-menu">
                <Menu.Item className="logo logo-space-menu-item">
                    <div className="display-inline logo-space">
                        <Image
                            src={process.env.PUBLIC_URL + '/columbia.png'}
                        />
                    </div>
                </Menu.Item>
                <Menu.Item className="no-border">
                    <div className="display-inline">
                        Version {process.env.REACT_APP_VERSION}
                    </div>
                </Menu.Item>

                <Menu.Menu position="right">
                    <Menu.Item className="no-border" position="right"
                               onClick={() => history.push("/app/profile")}>
                        <div className="display-inline">
                            <Image
                                circular
                                size={"mini"}
                                avatar
                                src={process.env.PUBLIC_URL + '/default-avatar.png'}
                            />
                            &nbsp;
                            {this.props.user.firstName + " " + this.props.user.lastName}
                        </div>
                    </Menu.Item>
                    {isAdmin  ?
                        <Menu.Item className="no-border" position="right"
                                   onClick={() => history.push("/app/invite")}>
                            <div className="display-inline">
                                <Icon name="add user"/>
                                &nbsp;
                                &nbsp;
                                {"Invite User"}
                            </div>
                        </Menu.Item> : null}
                    <Menu.Item
                        className="no-border"
                        onClick={this.props.logout}
                    >
                        <Icon name="log out"/>
                    </Menu.Item>
                    <Menu.Item
                        className="no-border"
                        onClick={this.props.toggleSideMenu}
                    >
                        <Icon name="bars"/>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}

export default connect(
    state => ({user: state.login.user}),
    dispatch => bindActionCreators({...sideAction, ...searchAction, ...loginAction}, dispatch)
)(TopMenu);
