import React, {Component} from 'react'
import {Button, Form, Grid, Segment, Message, Image} from 'semantic-ui-react'
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Login";
import {connect} from "react-redux";

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            errorMessage: null,
            locked: false,
        }
    }

    componentDidMount() {
        const searParams = new URLSearchParams(this.props.location.search)
        console.log(searParams.get("action"), searParams.get("token"))
        if(searParams.get("action") === "resetpassword"){
            setTimeout(()=> {
                this.props.history.push("resetpassword?token="+searParams.get("token"))
            }, 500)
        }
    }

    async handleSubmit() {
        let response = await this.props.login(this.state)
        this.setState({errorMessage: null, loading: true})
        if(response.data.should_change_password){
            this.props.history.push('/updatepassword')
        }else if (!response.data.errors) {
            let previousPath = sessionStorage.getItem('previouspath')
            let isClinician = response.data.me.roles.find(role => role.name === 'clinician')
            this.props.history.push(!isClinician && previousPath && previousPath !== '/' ? previousPath : '/app/patients')
        } else {
            this.handleErrors(response);
        }
    }

    handleErrors(response) {
        let errors = response.data.errors
        let errorMessage = Array.isArray(errors) ? errors.map((error, index) => {
            return <p key={index}>{error.message}</p>
        }) : <p>{response.data.message}</p>
        this.setState({errorMessage, loading: false, locked: response.data.message.indexOf("locked") > -1})
    }

    handleChange(field, e) {
        let state = this.state
        state[field] = e.target.value
        state.errorMessage = null
        this.setState(state)
    }

    render() {
        return (
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Form size='large'>
                        <Segment stacked padded='very'>
                            {this.state.errorMessage ? <Message negative>
                                <Message.Header>There was some errors with your submission</Message.Header>
                                {this.state.errorMessage}
                                {this.state.locked ?
                                    <p><a href='/resendunlock'>Click here </a> to resend unlock code</p> : null}
                            </Message> : null}

                            <div style={{padding: 20}}>
                                <Image
                                    src={process.env.PUBLIC_URL + '/columbia.png'}
                                />
                            </div>
                            <br/>
                            <Message positive>
                            <Message.Header>Authorized Access Only</Message.Header>
                                <br/>
                                <p><b>*Acceptable Use Notice:*</b></p>
                                <p>The information in University Systems at Columbia University is private and confidential and may be used only on a need-to-know basis.  All access is logged.  Unauthorized or improper use of a University System or the data in it may result in dismissal and/or civil or criminal penalties.</p>
                            </Message>
                            <br/>
                            <Form.Input
                                fluid icon='user'
                                iconPosition='left'
                                placeholder='E-mail address'
                                autoFocus
                                onChange={this.handleChange.bind(this, 'email')}/>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                onChange={this.handleChange.bind(this, 'password')}
                            />

                            <Button color='teal' fluid size='large' onClick={this.handleSubmit.bind(this)}>
                                Login
                            </Button>
                            <Message>
                                Forgot Password? <a href='/forgotpassword'>Click here </a> to reset Password
                            </Message>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

export default connect(
    state => {
        const {login} = state;
        return {
            state: {login}
        };
    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);
