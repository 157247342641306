import devicesApi from './api/devices'
import reportsApi from './api/reports'
import {handleError} from './api/errorHandler'

const GET_ALL_DEVICES = "GET_ALL_DEVICES";
const GET_DEVICE = "GET_DEVICE";
const GET_DEVICE_LOCATION = "GET_DEVICE_LOCATION";

const initialState = {
    data: [],
    device: {},
    location: {}
};

export const actionCreators = {
    getDevicesByDate: (date) => async (dispatch, getState) => {
        let callback = async function () {
            let filter = {
                'created_at': date
            }
            let response = await devicesApi.adminDevices(filter)
            let data = response.data

            dispatch({
                type: GET_ALL_DEVICES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },

    getDevicesUsageByDate: (date, json_url) => async (dispatch, getState) => {
        let callback = async function () {
            let reportData = await reportsApi.reportData(json_url)
            let devices = reportData.data.filter(device => {
                return device.last_accessed_date === date
            })

            dispatch({
                type: GET_ALL_DEVICES,
                payload: {devices}
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDevices: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await devicesApi.adminDevices(filter)
            let data = response.data

            dispatch({
                type: GET_ALL_DEVICES,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDevice: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await devicesApi.adminDevice(id)
            let data = response.data
            dispatch({
                type: GET_DEVICE,
                payload: data.device
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getDeviceLocation: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await devicesApi.adminDeviceLocation(id)
            let data = response.data
            dispatch({
                type: GET_DEVICE_LOCATION,
                payload: data.location
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_ALL_DEVICES) {
        return {...state, data: action.payload};
    }

    if (action.type === GET_DEVICE) {
        return {...state, device: action.payload};
    }
    if (action.type === GET_DEVICE_LOCATION) {
        return {...state, location: action.payload};
    }

    return state;
};
