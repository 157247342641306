import React, {Component} from "react";
import {
    Button,
    Header,
    Segment,
    Message,
    Form,
    Grid
} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Login";
import {connect} from "react-redux";
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import {actionCreators as sitesActionCreators} from "../../store/Sites";
import {actionCreators as treatmentsActionCreators} from "../../store/Treatments";

class Devices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            errorMessage: null,
            successMessage: null,
        }
    }

    async componentWillMount() {
        await this.props.getMasterTreatments()
        await this.props.getMasterSites()
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let isAdmin = nextProps.user.roles ? nextProps.user.roles.find(role => role.name === 'admin') : true
        if(!isAdmin){
            this.props.history.push("")
        }
    }

    async handleSubmit() {
        let data = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            role: this.state.role,
            siteId: this.state.siteId,
            treatmentId: this.state.treatmentId,
        }
        let response = await this.props.inviteAdministrator(data)
        this.handleResponse(response);

    }

    handleResponse(response) {
        let errors = response.data.errors
        if (!errors) {
            let successMessage = <p>{response.data.message}</p>
            this.setState({successMessage, errorMessage: null, loading: false})
        } else {
            let errorMessage = Array.isArray(errors) ? errors.map((error, index) => {
                return <p key={index}>{error.message}</p>
            }) : <p>{response.data.message}</p>
            this.setState({successMessage: null, errorMessage, loading: false})

        }
    }

    handleChange(field, e, target) {
        let state = this.state
        state[field] = target.value
        state.errorMessage = null
        this.setState(state)
    }
    handleRoleChange(field, e, target) {
        let state = this.state
        state[field] = target.value
        state.siteId = null
        state.treatmentId = null
        state.errorMessage = null
        this.setState(state)
    }
    render() {
        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Profile",
            }]}/>,
            <Segment key={1}>
                <Header>Profile</Header>
                <Grid>
                    <Grid.Column style={{maxWidth: 450}}>
                        <Segment stacked>
                            <Form size='large'>
                                {this.state.errorMessage ? <Message negative>
                                    <Message.Header>There was some errors with your submission</Message.Header>
                                    {this.state.errorMessage}
                                </Message> : this.state.successMessage ? <Message positive key={1}>
                                    <Message.Header>Success!</Message.Header>
                                    {this.state.successMessage}
                                </Message> : null}
                                <Form.Input
                                    fluid
                                    icon='info'
                                    iconPosition='left'
                                    placeholder='Email'
                                    label='Email'
                                    type='email'
                                    onChange={this.handleChange.bind(this, 'email')}
                                />
                                <Form.Input
                                    fluid
                                    icon='info'
                                    iconPosition='left'
                                    placeholder='First Name'
                                    label='First Name'
                                    type='text'
                                    onChange={this.handleChange.bind(this, 'firstName')}
                                />
                                <Form.Input
                                    fluid
                                    icon='info'
                                    iconPosition='left'
                                    placeholder='Last Name'
                                    label='Last Name'
                                    type='text'
                                    onChange={this.handleChange.bind(this, 'lastName')}
                                />
                                <Form.Select
                                    fluid
                                    label='Role'
                                    options={[
                                        { key: '1', text: 'Admin', value: 'admin' },
                                        { key: '2', text: 'Research Assistant', value: 'research_assistant' },
                                        { key: '3', text: 'Clinician', value: 'clinician' },
                                    ]}
                                    onChange={this.handleRoleChange.bind(this, 'role')}
                                    placeholder='Role'
                                />
                                {this.state.role === 'clinician' ? <Form.Select
                                    fluid
                                    label='Site Id'
                                    placeholder='Site ID'
                                    value={this.state.siteId}
                                    options={this.props.sites.master.sites.map((site, index) => {
                                        return { key: index, text: site.name, value: site.id }
                                    })}
                                    name="siteId"
                                    onChange={this.handleChange.bind(this, "siteId")}/> : null}

                                {this.state.role === 'clinician' ? <Form.Select
                                    fluid
                                    label='Treatment Id'
                                    placeholder='Treatment ID'
                                    value={this.state.treatmentId}
                                    options={this.props.treatments.master.treatments.map((site, index) => {
                                        return { key: index, text: site.name, value: site.id }
                                    })}
                                    name="treatmentId"
                                    onChange={this.handleChange.bind(this, "treatmentId")}/>: null}

                                <Button color='teal'  size='large' onClick={this.handleSubmit.bind(this)}>
                                    Save
                                </Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>

            </Segment>
        ];
    }
}


export default connect(
    state => {
        const {searchStore, devices, sites, treatments, login} = state;
        return {searchStore, devices, sites, treatments, user: login.user}
    },
    dispatch => bindActionCreators({...actionCreators, ...sitesActionCreators, ...treatmentsActionCreators}, dispatch)
)(Devices);
