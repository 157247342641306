import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators as curriculumActionCreators} from '../../store/Curriculums'
import {actionCreators as topicActionCreators} from '../../store/Topics'
import {actionCreators as deviceActionCreators} from '../../store/Devices'
import 'semantic-ui-css/semantic.min.css'
import {
    Form,
    Button,
    Table,
    Pagination,
    Label,
    Grid,
    Icon,
} from 'semantic-ui-react'
import FilterTopic from '../common/ui/FilterTopic'
import Loading from '../common/ui/Loading'
import AppBreadCrumb from '../common/ui/AppBreadCrumb'
import moment from 'moment'
import GridFormInput from '../common/ui/CompactTableGridFormInput'
import AccordionSegment from '../common/ui/AccordionSegment'

class Curriculum extends Component {
    state = {
        filter: {},
        page: 1,
        loading: true,
        currentIndex: {
            activeCurriculum: 0
        }
    }

    componentWillMount() {
        this.props.getDeviceCurriculum(this.props.match.params.deviceid, this.props.match.params.id)
        this.props.getCurriculumTopics(this.props.match.params.deviceid, this.props.match.params.id)
        this.props.getDevice(this.props.match.params.deviceid)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: !this.props.curriculums.curriculum})
    }

    getTableData = props => {
        return props.topics.data.topics.filter(item => {
            return item.name.toLowerCase().includes(props.searchStore.text)
        }).map((item, index) =>
            <Table.Row key={item.id}>
                <Table.Cell collapsing>
                    <Link
                        to={"/app/device/" + props.match.params.deviceid + "/curriculum/" + props.match.params.id + "/topic/" + item.id}>
                        <Button color='teal' size="small" icon> View
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.complete}</Table.Cell>
                <Table.Cell>{item.current}</Table.Cell>
                <Table.Cell>{item.order}</Table.Cell>
                <Table.Cell>{item.required ?
                    <Label color='teal' horizontal>TRUE</Label> :
                    <Label color='red' horizontal>FALSE</Label>}
                </Table.Cell>
            </Table.Row>);
    };

    handleClick = (option, isSuper, e, titleProps) => {
        const {index} = titleProps
        const {currentIndex} = this.state
        if (option === 'activeCurriculum' || isSuper) {
            currentIndex['activeTopic'] = -1
        }
        const newIndex = currentIndex[option] === index ? -1 : index
        currentIndex[option] = newIndex
        this.setState({currentIndex})
    }

    onPageChange = (e, data) => {
        this.setState({page: data.activePage})
        this.props.getCurriculumTopics(this.props.match.params.deviceid, this.props.match.params.id, this.state.filter, data.activePage)

    }

    getFilterTopic(filter) {
        this.setState({filter})
        this.props.getCurriculumTopics(this.props.match.params.deviceid, this.props.match.params.id, filter, this.state.page)
    }

    getTimeText(time) {
        let timeMinutes = Math.floor(time / 60000)
        let remainderMinutes = time % 60000
        let timeSeconds = Math.floor(remainderMinutes / 1000)
        return (timeMinutes < 1 ? "" : timeMinutes + " minutes and ") + (timeSeconds < 1 ? " less than a second" : timeSeconds + " seconds")
    }

    renderScreen(curriculum) {
        return <Table key={3} basic>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}/>
                    <Table.HeaderCell>Average Time</Table.HeaderCell>
                    <Table.HeaderCell>Total Time</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell><label style={{
                        float: 'right',
                        paddingRight: 10,
                        marginBottom: 0
                    }}>About Screen</label>
                    </Table.Cell>
                    <Table.Cell>{this.getTimeText(curriculum.about_screen.average_time)}</Table.Cell>
                    <Table.Cell>{this.getTimeText(curriculum.about_screen.total_time)}</Table.Cell>
                    <Table.Cell>{curriculum.about_screen.count}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><label style={{
                        float: 'right',
                        paddingRight: 10,
                        marginBottom: 0
                    }}>Glossary Screen</label>
                    </Table.Cell>
                    <Table.Cell>{this.getTimeText(curriculum.glossary_screen.average_time)}</Table.Cell>
                    <Table.Cell>{this.getTimeText(curriculum.glossary_screen.total_time)}</Table.Cell>
                    <Table.Cell>{curriculum.glossary_screen.count}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><label style={{
                        float: 'right',
                        paddingRight: 10,
                        marginBottom: 0
                    }}>Helpful Links Screen</label>
                    </Table.Cell>
                    <Table.Cell>{this.getTimeText(curriculum.helpful_links_screen.average_time)}</Table.Cell>
                    <Table.Cell>{this.getTimeText(curriculum.helpful_links_screen.total_time)}</Table.Cell>
                    <Table.Cell>{curriculum.helpful_links_screen.count}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

    }

    downloadCSV() {
        console.log(this.props.devices.device)

        let a = document.createElement('a');
        a.href = this.props.devices.device.summary.raw_device_csv_url;
        a.target = "_blank"
        a.click();
    }

    downloadJSON() {
        let a = document.createElement('a');
        a.href = this.props.devices.device.summary.raw_device_json_url;
        a.target = "_blank"
        a.click();
    }

    render() {
        let device = this.props.devices.device
        let curriculum = this.props.curriculums.curriculum
        let createdAt = curriculum.created_at ? curriculum.created_at.substr(0, 16) : ""
        let updatedAt = curriculum.updated_at ? curriculum.updated_at.substr(0, 16) : ""
        let profileCurriculum = null


        if (device.summary && device.summary.profile_curriculums) {
            console.log(device.summary.profile_curriculums)
            profileCurriculum = device.summary.profile_curriculums.find(profile_curriculum => profile_curriculum.id === curriculum.id)
        }

        return [
            <AppBreadCrumb key={0} crumbs={[{
                text: "Devices",
                link: "/app/devices"
            }, {
                text: "Device",
                link: "/app/device/" + this.props.match.params.deviceid
            }, {
                text: "Curriculum",
            }]}/>,

            <AccordionSegment backgroundColor={'#80e4d530'} key={1} header={'Curriculum Information'} component={
                <div>
                    {this.state.loading ? <Loading/> :
                        <Form>
                            <Grid columns='equal' style={{marginTop: 0}}>
                                <Grid.Column>
                                    <GridFormInput
                                        label="Name"
                                        component={curriculum.name}
                                    />
                                    <GridFormInput
                                        label="Current"
                                        component={curriculum.current}
                                    />
                                    <GridFormInput
                                        label="Complete"
                                        component={curriculum.complete}
                                    />
                                    <GridFormInput
                                        label="Order"
                                        component={curriculum.order}
                                    />

                                </Grid.Column>
                                <Grid.Column>

                                    <GridFormInput
                                        label="ID"
                                        component={curriculum.id}
                                    />
                                    <GridFormInput
                                        label="Device ID"
                                        component={curriculum.deviceId}
                                    />
                                    <GridFormInput
                                        label="Created"
                                        component={moment(createdAt).format('MMMM Do YYYY') + " at " + moment(createdAt).format('h:mm:ss a')}
                                    />
                                    <GridFormInput
                                        label="Updated"
                                        component={moment(updatedAt).format('MMMM Do YYYY') + " at " + moment(updatedAt).format('h:mm:ss a')}
                                    />
                                </Grid.Column>
                            </Grid>
                            <br/>

                            <Link
                                to={"/app/device/" + this.props.match.params.deviceid + "/curriculum/" + this.props.match.params.id + "/timeline"}>
                                <Button icon labelPosition='right' color='teal' size="small">View Curriculum
                                    Timeline<Icon name='eye'/></Button></Link>
                            {device.summary && device.summary.raw_device_csv_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadCSV.bind(this)}>Download CSV<Icon
                                    name='download'/></Button> : null}
                            {device.summary && device.summary.raw_device_json_url ?
                                <Button icon labelPosition='right' color='teal' size="small"
                                        onClick={this.downloadJSON.bind(this)}>Download
                                    JSON<Icon name='download'/></Button> : null}
                        </Form>}
                </div>}/>,
            <AccordionSegment backgroundColor={'#f7f6a130'} key={2} header={'Results'} component={
                device.summary && device.summary.profile_curriculums ? this.state.loading || !profileCurriculum ?
                    <Loading/> : [
                        <Grid key={2} columns='equal' style={{marginTop: 0}}>
                            <Grid.Column>
                                {
                                    profileCurriculum.start_date ?
                                        <GridFormInput
                                            width={140}
                                            label="Start Date"
                                            component={moment(profileCurriculum.start_date ? profileCurriculum.start_date.substr(0, 16) : "").format('MMMM Do YYYY') + " at " + moment(profileCurriculum.start_date ? profileCurriculum.start_date.substr(0, 16) : "").format('h:mm:ss a')}
                                        /> : null
                                }
                                {
                                    profileCurriculum.last_accessed_date ?
                                        <GridFormInput
                                            width={140}
                                            label="Most Recent Access"
                                            component={moment(profileCurriculum.last_accessed_date ? profileCurriculum.last_accessed_date.substr(0, 16) : "").format('MMMM Do YYYY')}
                                        /> : null
                                }
                                {
                                    profileCurriculum.end_date ?
                                        <GridFormInput
                                            width={140}
                                            label="End Date"
                                            component={moment(profileCurriculum.end_date ? profileCurriculum.end_date.substr(0, 16) : "").format('MMMM Do YYYY')}
                                        /> : null
                                }
                                <GridFormInput
                                    width={140}
                                    label="Count"
                                    component={profileCurriculum.count}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <GridFormInput
                                    label="Average Time"
                                    component={this.getTimeText(profileCurriculum.average_time)}
                                />
                                <GridFormInput
                                    label="Total Time"
                                    component={this.getTimeText(profileCurriculum.total_time)}
                                />
                            </Grid.Column>
                        </Grid>,
                        this.renderScreen(profileCurriculum)
                    ] : <b>No Data Available</b>
            }/>,
            <AccordionSegment key={3} header={'Topics'} component={
                <div>
                    <FilterTopic filter={this.getFilterTopic.bind(this)}/>
                    {this.props.topics.data.topics ?
                        <Table compact celled definition>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Complete</Table.HeaderCell>
                                    <Table.HeaderCell>Current</Table.HeaderCell>
                                    <Table.HeaderCell>Order</Table.HeaderCell>
                                    <Table.HeaderCell>Required</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.getTableData(this.props)}</Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={7}>
                                        <Pagination defaultActivePage={this.props.topics.data.paging.page}
                                                    totalPages={this.props.topics.data.paging.pages}
                                                    onPageChange={this.onPageChange.bind(this)}
                                                    size='mini'/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table> :
                        <Loading/>
                    }
                </div>}/>
        ]
    }
}

export default connect(
    state => ({
        searchStore: state.searchStore,
        curriculums: state.curriculums,
        topics: state.topics,
        devices: state.devices
    }),
    dispatch => bindActionCreators({...curriculumActionCreators, ...topicActionCreators, ...deviceActionCreators}, dispatch)
)(Curriculum)
